import React, { useState, useContext, useEffect } from 'react'
import { Typography, Paper, Table, TableRow, TableCell, TableHead, TableBody, IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import Pagination from '../../components/layout/Pagination'
import Api from '../../services/Api'
import { priceFormat } from '../../services/helpers'
import defaultImg from '../../assets/default.png'
import { InsertDriveFileOutlined } from '@material-ui/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-start'
  },
  avatar: {
    ...theme.common.avatar,
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30)
  }
}))

const Invoices = () => {
  const classes = useStyles()
  const [invoices, setInvoices] = useState([])
  const [paginationLinks, setPaginationLinks] = useState({})
  const [paginationMeta, setPaginationMeta] = useState({})
  const { userContext } = useContext(UserContext)

  useEffect(() => {
    Api.getEnterpriseInvoices(userContext.getId()).then(res => {
      if (res && res.data) {
        setInvoicesData(res.data)
      }
    })
  }, [])

  function download(invoice) {
    Api.getPdf(invoice.order_id, invoice.client.id).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `facture-${invoice.created_at}.pdf`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  const setInvoicesData = data => {
    if (data.data.length > 0) {
      setInvoices(data.data)
      setPaginationLinks({ prev: data.links.prev, next: data.links.next })
      setPaginationMeta({ currentPage: data.meta.current_page, pageCount: data.meta.last_page })
    }
  }

  const lines = (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nom d'utilisateur</TableCell>
          <TableCell align="center">Montant</TableCell>
          <TableCell align="center">Date</TableCell>
          <TableCell align="right">Facture</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.map(invoice => (
          <TableRow key={invoice.id}>
            <TableCell>
              <div className={classes.inline}>
                <img alt={invoice.client.name} className={classes.avatar} src={invoice.client.img ? invoice.client.img : defaultImg} />
                <div>
                  <Typography>{invoice.client.name}</Typography>
                  <Typography>{invoice.client.email}</Typography>
                </div>
              </div>
            </TableCell>
            <TableCell align="center">{priceFormat(invoice.total_ttc)} €</TableCell>
            <TableCell align="center">
              {dayjs
                .unix(invoice.created_at)
                .format('DD MMMM YYYY')
                .toLowerCase()}
            </TableCell>
            <TableCell align="right">
              <Tooltip title="Télécharger la facture">
                <IconButton variant="contained" size="small" color="primary" onClick={() => download(invoice)}>
                  <InsertDriveFileOutlined />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  return (
    <div className={classes.section}>
      <Typography variant="h1">Mes factures</Typography>

      {invoices.length > 0 ? (
        <>
          <Paper className={classes.sectionPaper}>{lines}</Paper>
          <Pagination links={paginationLinks} meta={paginationMeta} onChangePage={data => setInvoicesData(data)} />
        </>
      ) : (
        <Typography className={classes.noResults} variant="h4">
          Aucune facture.
        </Typography>
      )}
    </div>
  )
}

export default Invoices
