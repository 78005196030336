import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper, FormControlLabel, Radio } from '@material-ui/core'
import LinkButton from '../buttons/LinkButton'
import MessageList from './MessageList'
import palette from '../../theme/palette'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import config from '../../config'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  header: {
    borderBottom: `1px solid ${palette.grey}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.typography.pxToRem(15),
    marginBottom: theme.typography.pxToRem(15)
  },
  other: {
    marginBottom: 0
  },
  unread: {
    textAlign: 'right'
  }
}))

const MessageLast = ({ order }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [unread, setUnread] = useState(true)
  const [messages, setMessages] = useState([])
  const { userContext } = useContext(UserContext)
  const role = userContext.getRole()

  useEffect(() => {
    loadMessages()
  }, [order])

  useEffect(() => {
    if (unread === 'true') {
      Api.setMessagesUnread(order.id, userContext.getId())
    }
  }, [unread])

  const loadMessages = async () => {
    setIsLoading(true)
    try {
      const res = await Api.getLastMessages(order.id)

      if (res.data.success && res.data.data) {
        setMessages(res.data.data.reverse())
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <Paper className={classes.sectionPaper}>
      {isLoading ? (
        <Typography variant="body2">Chargement...</Typography>
      ) : (
        <>
          <div className={classes.header}>
            <Typography variant="h3" className={classes.other}>
              Messagerie
            </Typography>
            <LinkButton to={`/${role}/messages/${order.id}`}>Voir tous les messages</LinkButton>
          </div>
          {messages.length > 0 ? (
            <>
              <MessageList messages={messages} />
              {userContext.getRole() === config.roles.artisan && (
                <div className={classes.unread}>
                  <FormControlLabel
                    control={<Radio checked={unread === 'true'} onChange={() => setUnread('true')} value={unread} />}
                    label={'Marquer comme non lu'}
                  />
                </div>
              )}
            </>
          ) : (
            <Typography variant="body2">Aucun message.</Typography>
          )}
        </>
      )}
    </Paper>
  )
}

export default MessageLast
