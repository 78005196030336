import React, { useContext, useState, useEffect } from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import palette from '../../theme/palette'
import ServiceTeaser from './ServiceTeaser'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import config from '../../config'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  btn: { width: '100%' },
  root: {
    width: '100%',
    padding: 20,
    height: 216,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    marginBottom: 10,
    display: 'block',
    margin: '0 auto',
    '& svg': {
      maxWidth: '100%',
      height: 'auto'
    }
  },
  iconActive: {
    '& svg *': {
      fill: palette.red
    }
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: '700',
    letterSpacing: 1.33,
    position: 'relative',
    '&:before': {
      content: '""',
      height: 1,
      backgroundColor: palette.red,
      left: 0,
      right: 0,
      bottom: -2,
      position: 'absolute',
      transform: 'scale(0,1)',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  titleActive: {
    color: palette.red,
    '&:before': {
      transform: 'scale(1,1)'
    }
  }
}))

const ServicesBlock = props => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([])

  const { userContext } = useContext(UserContext)
  const { onSelectionDone } = props
  const spacing = isMobile ? 2 : 4

  useEffect(() => {
    const getServices = async () => {
      setIsLoading(true)

      try {
        const res =
          userContext.getRole() === config.roles.artisan
            ? await Api.getServicesByArtisan(userContext.getId())
            : await Api.getServicesByPlace(userContext.user.place.value)
        if (res.data.success) {
          setServices(res.data.data.service)
          setIsLoading(false)
        }
      } catch (error) {
        console.error(error)
      }
    }

    getServices()
  }, [])

  useEffect(() => {
    if (selectedServices.length > 0) {
      onSelectionDone(selectedServices)
    }
  }, [selectedServices])

  function handleClick(service) {
    const newSelectedServices = [] // pour eviter la mutation
    newSelectedServices.push(service)
    setSelectedServices(newSelectedServices)
    return
  }

  if (isLoading)
    return (
      <div className={classes.section}>
        <CircularProgress size="24px" color="primary" />
      </div>
    )

  return (
    <>
      {services.length > 0 ? (
        <Grid container spacing={spacing}>
          {services.map(service => (
            <Grid item xs={6} md={3} key={`${service.id}`}>
              <ServiceTeaser service={service} onClick={() => handleClick(service)} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <div className={classes.section}>
          <Typography>Aucun service pour le moment</Typography>
        </div>
      )}
    </>
  )
}

export default ServicesBlock
