import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import palette from '../../theme/palette'
import { makeStyles } from '@material-ui/styles'
import { icons } from '../../services/iconUtils'

const useStyles = makeStyles(theme => ({
  itemLink: {
    backgroundColor: '#fff',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    opacity: 1,
    transition: theme.transitions.create('opacity background', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      backgroundColor: palette.clearRed,
      color: palette.red
    },
    '&:focus': {
      color: palette.red,
      backgroundColor: palette.clearRed
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 2,
      top: 0,
      bottom: 0,
      left: 0,
      transition: 'all .2s',
      transform: 'scale(1,0)',
      backgroundColor: palette.red
    }
  },
  label: {
    fontSize: '1rem',
    fontWeight: 300
  },
  labelActive: {
    color: palette.red,
    fontWeight: 700
  },
  active: {
    '&&': {
      color: palette.red,
      backgroundColor: palette.clearRed
    },
    '&:before': {
      transform: 'scale(1,1)'
    },
    '&&:hover': {
      color: palette.red,
      backgroundColor: palette.clearRed
    },
    '&&:focus': {
      color: palette.red,
      backgroundColor: palette.clearRed
    }
  },
  menuIcon: {
    width: 25,
    height: 20,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: 22
  },
  isLast: {
    marginBottom: 40
  }
}))

const MenuLink = props => {
  const classes = useStyles()
  const { active, isLast, label, isOpen, ...rest } = props
  const Icon = icons[props.icon || 'Home']

  let path = props.to;
  if (path && path.includes(':placeId')) {
    path = path.replace(':placeId', props.placeId); // Replace :placeId with the actual value
  }

  return (
    <ListItem
      {...rest}
      button
      selected={active}
      component={Link}
      to={path}
      className={classNames(classes.itemLink, {
        [classes.isLast]: isLast
      })}
      classes={{
        selected: classes.active
      }}
    >
      <ListItemIcon>
        <Icon color={active ? palette.red : palette.dark} />
      </ListItemIcon>
      {isOpen && (
        <ListItemText
          className={classNames(classes.label, {
            [classes.labelActive]: active
          })}
          classes={{
            primary: active ? classes.labelActive : null
          }}
          disableTypography
          primary={label}
        />
      )}
    </ListItem>
  )
}

export default MenuLink
