import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DeleteRounded, CheckCircleRounded, Close } from '@material-ui/icons/'
import DayjsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { TOAST, useToasts } from '../../hooks/HookToasts'
import InputNumber from '../../hooks/InputNumber'

dayjs.extend(isBetween)

const useStyles = makeStyles(theme => ({
  modalInline: {
    ...theme.common.inline
  },
  modalIcon: {
    fontSize: 20,
    color: theme.palette.primary.main
  },
  inline: {
    ...theme.common.inline,
    marginTop: theme.typography.pxToRem(20),
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  footer: {
    marginRight: theme.typography.pxToRem(8)
  },
  row: {
    height: 'auto'
  },
  cell: {
    padding: '0 2px'
  }
}))

export default function PlanningDialog(props) {
  const classes = useStyles()
  const [day, setDay] = useState(props.day)
  const [line, setLine] = useState({ start: null, end: null, remain: null })
  const { add } = useToasts()

  useEffect(() => {
    if (props.day) {
      let d = { ...props.day }
      setDay(d)
    }
  }, [props.day])

  const addLine = () => {
    if (!line.start || !line.end) {
      if (!line.start) {
        add('La date de début est obligatoire', TOAST.ERROR)
      } else {
        add('La date de fin est obligatoire', TOAST.ERROR)
      }
      return false
    } else {
      const s = dayjs(line.start)
      const e = dayjs(line.end)

      if (e.isBefore(s, 'm') || s.isSame(e, 'm')) {
        add("L'heure de fin doit être après l'heure de début", TOAST.ERROR)
        return false
      }

      const newLine = { start: s.format('HH:mm'), end: e.format('HH:mm'), remain: line.remain }
      let newDay = { ...props.day }
      newDay.hours = day.hours ? day.hours : []
      for (const h of newDay.hours) {
        let dStart = dayjs()
          .set('h', h.start.substring(0, 2))
          .set('m', h.start.substring(3))
        let dEnd = dayjs()
          .set('h', h.end.substring(0, 2))
          .set('m', h.end.substring(3))
        if (s.isBetween(dStart, dEnd, 'm') || s.isSame(dStart, 'm')) {
          add("L'heure de début est déjà comprise dans un créneau existant", TOAST.ERROR)
          return false
        } else if (e.isBetween(dStart, dEnd, 'm') || e.isSame(dEnd, 'm')) {
          add("L'heure de fin est déjà comprise dans un créneau existant", TOAST.ERROR)
          return false
        } else if (s.isBefore(dStart, 'm') && e.isAfter(dEnd, 'm')) {
          add('Un créneau existant plus court existe déjà', TOAST.ERROR)
          return false
        }
      }

      newDay.hours.push(newLine)
      setDay(newDay)
      setLine({ start: null, end: null, remain: null })
      return true
    }
  }

  function removeLine(index) {
    let newDay = { ...day }
    newDay.hours.splice(index, 1)
    setDay(newDay)
  }

  function handleLine(name, value) {
    setLine({ ...line, [name]: value })
  }

  const submit = () => {
    if (line.start || line.end) {
      if (addLine()) {
        props.onSubmit(day)
      }
    } else {
      props.onSubmit(day)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Dialog fullWidth open={props.day.name !== undefined} onClose={props.onCancel} aria-labelledby="form-dialog-title">
        <div className={classes.modalInline}>
          <DialogTitle id="form-dialog-title">Ajouter un créneau</DialogTitle>
          <IconButton onClick={props.onCancel}>
            <Close className={classes.modalIcon} />
          </IconButton>
        </div>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow classes={{ root: classes.row }}>
                <TableCell>Début</TableCell>
                <TableCell>Fin</TableCell>
                <TableCell>Commandes Max</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {day.hours &&
                day.hours.map((hour, index) => (
                  <TableRow classes={{ root: classes.row }} key={`hour-${index}`}>
                    <TableCell classes={{ root: classes.cell }}>{hour.start}</TableCell>
                    <TableCell classes={{ root: classes.cell }}>{hour.end}</TableCell>
                    <TableCell classes={{ root: classes.cell }}>{hour.remain ? hour.remain : '-'}</TableCell>
                    <TableCell classes={{ root: classes.cell }}>
                      <IconButton onClick={() => removeLine(index)} variant="contained">
                        <DeleteRounded color="primary" fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {!day.hours && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography>Aucun créneau pour le moment.</Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow classes={{ root: classes.row }}>
                <TableCell classes={{ root: classes.cell }}>
                  <KeyboardTimePicker
                    id="start"
                    ampm={false}
                    invalidDateMessage={'Date invalide'}
                    mask="__:__"
                    variant="dialog"
                    margin="normal"
                    label="Debut *"
                    value={line.start}
                    onChange={e => handleLine('start', e ? e.$d : null)}
                    KeyboardButtonProps={{ 'aria-label': "changer d'heure" }}
                    DialogProps={{ cancelLabel: 'ANNULER' }}
                  />
                </TableCell>
                <TableCell classes={{ root: classes.cell }}>
                  <KeyboardTimePicker
                    id="end"
                    ampm={false}
                    invalidDateMessage={'Date invalide'}
                    mask="__:__"
                    variant="dialog"
                    margin="normal"
                    label="Fin *"
                    value={line.end}
                    onChange={e => handleLine('end', e ? e.$d : null)}
                    KeyboardButtonProps={{ 'aria-label': "changer d'heure" }}
                    DialogProps={{ cancelLabel: 'ANNULER' }}
                  />
                </TableCell>
                <TableCell classes={{ root: classes.cell }}>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel shrink htmlFor="min_order">
                      Commandes max
                    </InputLabel>
                    <Input
                      id="max"
                      type="text"
                      value={line.remain}
                      onChange={e => handleLine('remain', e.target.value)}
                      name="remain"
                      fullWidth
                      placeholder="Champ facultatif"
                      inputComponent={InputNumber}
                    />
                  </FormControl>
                </TableCell>
                <TableCell classes={{ root: classes.cell }}>
                  <IconButton onClick={addLine} variant="contained">
                    <CheckCircleRounded color="primary" fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} variant="outlined" color="primary">
            Annuler
          </Button>
          <Button onClick={submit} variant="contained" color="primary">
            Terminer
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}
