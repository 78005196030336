import React, { useState, useEffect } from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Api from '../../services/Api'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  content: {
    '& img, & iframe': {
      maxWidth: '100%',
      height: 'auto'
    }
  }
}))

const StaticPage = ({ match }) => {
  const classes = useStyles()
  const [content, setContent] = useState('')

  useEffect(() => {
    Api.getPage(match.params.placeId).then(res => {
      if (res.data.success && res.data.data) {
        setContent(res.data.data.text)
      }
    })
  }, [])

  return (
    <Paper className={classes.sectionPaper}>
      <div dangerouslySetInnerHTML={{ __html: content }} className={classes.content} />
    </Paper>
  )
}

export default withRouter(StaticPage)
