import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  blocPaper: {
    padding: 18
  },
  blocTitle: {
    ...theme.common.blocTitle
  },
  number: {
    display: 'block',
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.26,
    marginBottom: 12
  },
  numberLegend: {
    textAlign: 'center'
  }
}))

const NumberFocus = ({ title, number, legend }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.blocPaper}>
      <Typography className={classes.blocTitle}>{title}</Typography>
      <div>
        <Typography className={classes.numberLegend}>
          <span className={classes.number}>{number}</span>
          {legend}
        </Typography>
      </div>
    </Paper>
  )
}

export default NumberFocus
