import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import ServicesBlock from '../../components/entities/ServicesBlock'
import { TOAST, useToasts } from '../../hooks/HookToasts'
import Api from '../../services/Api'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  back: {
    marginRight: 5
  },
  new: {
    margin: '50px auto',
    textAlign: 'center'
  }
}))

const Delivery = props => {
  const classes = useStyles()
  const { add } = useToasts()

  // We check if a service has inside his process any configurable module
  const redirectAfterClick = async service => {
    const res = await Api.getModuleForService(service.id)
    if (res.data && res.data.success) {
      const components = res.data.data.map(module => module.component)
      if (components.includes('Timeslots')) {
        props.history.push(`/artisan/creneaux/${service.id}`, { service })
      } else if (components.includes('Schedule') || components.includes('Delivery')) {
        props.history.push('/artisan/horaires', { service })
      } else {
        add("Aucun créneau ou jour de passage n'est à paramétrer pour ce service.", TOAST.ERROR)
      }
    } else {
      add("Aucun créneau ou jour de passage n'est à paramétrer pour ce service.", TOAST.ERROR)
    }
  }

  return (
    <div className={classes.section}>
      <Typography variant="h1">{props.title}</Typography>
      <ServicesBlock
        selectOne
        onSelectionDone={services => {
          redirectAfterClick(services[0])
        }}
      />
    </div>
  )
}

export default withRouter(Delivery)
