import React from 'react'
import palette from '../../../theme/palette'

const Orders = ({ color = palette.primary }) => (
  <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.933 8.30499C6.90677 8.27797 6.87537 8.25651 6.84066 8.24191C6.80595 8.2273 6.76865 8.21986 6.731 8.22C6.69402 8.21946 6.65736 8.22676 6.6234 8.24141C6.58945 8.25606 6.55898 8.27772 6.534 8.30499C6.48183 8.3579 6.45179 8.42872 6.45 8.503C6.45151 8.57724 6.48154 8.64805 6.53386 8.70074C6.58618 8.75343 6.65677 8.78395 6.731 8.78599C6.76865 8.78614 6.80595 8.77869 6.84066 8.76408C6.87537 8.74948 6.90677 8.72802 6.933 8.701C6.98517 8.64809 7.01521 8.57729 7.017 8.503C7.01719 8.46602 7.00985 8.42938 6.9954 8.39534C6.98096 8.36129 6.95972 8.33055 6.933 8.30499V8.30499Z"
      fill={color}
    />
    <path
      d="M17.351 8.22C17.279 8.22429 17.2113 8.25592 17.1618 8.30843C17.1124 8.36093 17.0848 8.43036 17.0848 8.5025C17.0848 8.57465 17.1124 8.64407 17.1618 8.69658C17.2113 8.74909 17.279 8.78072 17.351 8.785C17.423 8.78072 17.4907 8.74909 17.5402 8.69658C17.5896 8.64407 17.6172 8.57465 17.6172 8.5025C17.6172 8.43036 17.5896 8.36093 17.5402 8.30843C17.4907 8.25592 17.423 8.22429 17.351 8.22Z"
      fill={color}
    />
    <path
      d="M11.451 8.22C11.379 8.22429 11.3113 8.25592 11.2618 8.30843C11.2124 8.36093 11.1848 8.43036 11.1848 8.5025C11.1848 8.57465 11.2124 8.64407 11.2618 8.69658C11.3113 8.74909 11.379 8.78072 11.451 8.785C11.523 8.78072 11.5907 8.74909 11.6402 8.69658C11.6896 8.64407 11.7172 8.57465 11.7172 8.5025C11.7172 8.43036 11.6896 8.36093 11.6402 8.30843C11.5907 8.25592 11.523 8.22429 11.451 8.22V8.22Z"
      fill={color}
    />
    <path
      d="M10.271 8.22C10.199 8.22429 10.1313 8.25592 10.0818 8.30843C10.0324 8.36093 10.0048 8.43036 10.0048 8.5025C10.0048 8.57465 10.0324 8.64407 10.0818 8.69658C10.1313 8.74909 10.199 8.78072 10.271 8.785C10.343 8.78072 10.4107 8.74909 10.4602 8.69658C10.5096 8.64407 10.5372 8.57465 10.5372 8.5025C10.5372 8.43036 10.5096 8.36093 10.4602 8.30843C10.4107 8.25592 10.343 8.22429 10.271 8.22V8.22Z"
      fill={color}
    />
    <path
      d="M12.631 8.22C12.559 8.22429 12.4913 8.25592 12.4418 8.30843C12.3924 8.36093 12.3648 8.43036 12.3648 8.5025C12.3648 8.57465 12.3924 8.64407 12.4418 8.69658C12.4913 8.74909 12.559 8.78072 12.631 8.785C12.703 8.78072 12.7707 8.74909 12.8202 8.69658C12.8696 8.64407 12.8972 8.57465 12.8972 8.5025C12.8972 8.43036 12.8696 8.36093 12.8202 8.30843C12.7707 8.25592 12.703 8.22429 12.631 8.22V8.22Z"
      fill={color}
    />
    <path
      d="M9.091 8.22C9.01898 8.22429 8.95132 8.25592 8.90184 8.30843C8.85237 8.36093 8.82481 8.43036 8.82481 8.5025C8.82481 8.57465 8.85237 8.64407 8.90184 8.69658C8.95132 8.74909 9.01898 8.78072 9.091 8.785C9.16302 8.78072 9.23068 8.74909 9.28016 8.69658C9.32963 8.64407 9.35719 8.57465 9.35719 8.5025C9.35719 8.43036 9.32963 8.36093 9.28016 8.30843C9.23068 8.25592 9.16302 8.22429 9.091 8.22V8.22Z"
      fill={color}
    />
    <path
      d="M7.911 8.22C7.83898 8.22429 7.77132 8.25592 7.72184 8.30843C7.67237 8.36093 7.64481 8.43036 7.64481 8.5025C7.64481 8.57465 7.67237 8.64407 7.72184 8.69658C7.77132 8.74909 7.83898 8.78072 7.911 8.785C7.98302 8.78072 8.05068 8.74909 8.10015 8.69658C8.14963 8.64407 8.17719 8.57465 8.17719 8.5025C8.17719 8.43036 8.14963 8.36093 8.10015 8.30843C8.05068 8.25592 7.98302 8.22429 7.911 8.22Z"
      fill={color}
    />
    <path
      d="M13.811 8.22C13.739 8.22429 13.6713 8.25592 13.6218 8.30843C13.5724 8.36093 13.5448 8.43036 13.5448 8.5025C13.5448 8.57465 13.5724 8.64407 13.6218 8.69658C13.6713 8.74909 13.739 8.78072 13.811 8.785C13.883 8.78072 13.9507 8.74909 14.0002 8.69658C14.0496 8.64407 14.0772 8.57465 14.0772 8.5025C14.0772 8.43036 14.0496 8.36093 14.0002 8.30843C13.9507 8.25592 13.883 8.22429 13.811 8.22V8.22Z"
      fill={color}
    />
    <path
      d="M16.171 8.22C16.099 8.22429 16.0313 8.25592 15.9818 8.30843C15.9324 8.36093 15.9048 8.43036 15.9048 8.5025C15.9048 8.57465 15.9324 8.64407 15.9818 8.69658C16.0313 8.74909 16.099 8.78072 16.171 8.785C16.243 8.78072 16.3107 8.74909 16.3602 8.69658C16.4096 8.64407 16.4372 8.57465 16.4372 8.5025C16.4372 8.43036 16.4096 8.36093 16.3602 8.30843C16.3107 8.25592 16.243 8.22429 16.171 8.22V8.22Z"
      fill={color}
    />
    <path
      d="M14.991 8.22C14.919 8.22429 14.8513 8.25592 14.8018 8.30843C14.7524 8.36093 14.7248 8.43036 14.7248 8.5025C14.7248 8.57465 14.7524 8.64407 14.8018 8.69658C14.8513 8.74909 14.919 8.78072 14.991 8.785C15.063 8.78072 15.1307 8.74909 15.1802 8.69658C15.2296 8.64407 15.2572 8.57465 15.2572 8.5025C15.2572 8.43036 15.2296 8.36093 15.1802 8.30843C15.1307 8.25592 15.063 8.22429 14.991 8.22V8.22Z"
      fill={color}
    />
    <path
      d="M18.08 14H6.28C6.20494 14 6.13296 14.0298 6.07989 14.0829C6.02681 14.136 5.997 14.2079 5.997 14.283C5.997 14.3581 6.02681 14.43 6.07989 14.4831C6.13296 14.5362 6.20494 14.566 6.28 14.566H18.08C18.1551 14.566 18.227 14.5362 18.2801 14.4831C18.3332 14.43 18.363 14.3581 18.363 14.283C18.363 14.2079 18.3332 14.136 18.2801 14.0829C18.227 14.0298 18.1551 14 18.08 14Z"
      fill={color}
    />
    <path
      d="M18.08 2H6.28C6.20494 2 6.13296 2.02981 6.07989 2.08289C6.02681 2.13596 5.997 2.20795 5.997 2.283C5.997 2.35806 6.02681 2.43004 6.07989 2.48311C6.13296 2.53618 6.20494 2.56599 6.28 2.56599H18.08C18.1551 2.56599 18.227 2.53618 18.2801 2.48311C18.3332 2.43004 18.363 2.35806 18.363 2.283C18.363 2.20795 18.3332 2.13596 18.2801 2.08289C18.227 2.02981 18.1551 2 18.08 2Z"
      fill={color}
    />
    <path
      d="M1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1C2.26522 1 2.51957 1.10536 2.70711 1.29289C2.89464 1.48043 3 1.73478 3 2C3 2.26522 2.89464 2.51957 2.70711 2.70711C2.51957 2.89464 2.26522 3 2 3C1.73478 3 1.48043 2.89464 1.29289 2.70711C1.10536 2.51957 1 2.26522 1 2Z"
      stroke={color}
      strokeWidth="0.5"
      fill={color}
      strokeMiterlimit="50"
    />
    <path
      d="M1 14C1 13.7348 1.10536 13.4804 1.29289 13.2929C1.48043 13.1054 1.73478 13 2 13C2.26522 13 2.51957 13.1054 2.70711 13.2929C2.89464 13.4804 3 13.7348 3 14C3 14.2652 2.89464 14.5196 2.70711 14.7071C2.51957 14.8946 2.26522 15 2 15C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14Z"
      stroke={color}
      strokeWidth="0.5"
      fill={color}
      strokeMiterlimit="50"
    />
    <path
      d="M1 8C1 7.73478 1.10536 7.48043 1.29289 7.29289C1.48043 7.10536 1.73478 7 2 7C2.26522 7 2.51957 7.10536 2.70711 7.29289C2.89464 7.48043 3 7.73478 3 8C3 8.26522 2.89464 8.51957 2.70711 8.70711C2.51957 8.89464 2.26522 9 2 9C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8Z"
      stroke={color}
      fill={color}
      strokeWidth="0.5"
      strokeMiterlimit="50"
    />
  </svg>
)

export default Orders
