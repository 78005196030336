import React, { Component } from 'react'
import { withToastManager } from 'react-toast-notifications'
import { Button, withStyles, createStyles, CircularProgress, Grid, Paper } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import FullCenteredLayout from '../../components/layout/FullCenteredLayout'
import { withUser } from '../../stores/UserProvider'
import Api from '../../services/Api'
import PasswordInput from '../../components/form/PasswordInput'
import { isPasswordValid } from '../../services/formUtils'
import { TOAST } from '../../hooks/HookToasts'

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: '23px 20px 32px',
    maxWidth: '660px'
  },
  paperBtn: {
    textAlign: 'center',
    marginTop: 23
  }
})

class Reset extends Component {
  state = {
    password: '',
    password_confirm: ''
  }

  componentDidMount() {
    document.title = "box'n services – Réinitialisation du mot de passe"
    // ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleChange = (e, i) => {
    this.setState({ [e.target.name]: e.currentTarget.value })
  }

  handleClickShowPassword = () => {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  reinit = e => {
    e.preventDefault()
    const { password, password_confirm } = this.state
    const { toastManager } = this.props
    if (password !== password_confirm) {
      toastManager.add('Les mots de passe doivent être similaires', { appearance: TOAST.ERROR })
      return
    }

    if (!password || !password_confirm) {
      toastManager.add('Tous les champs sont obligatoires', { appearance: TOAST.ERROR })
      return
    }

    if (!isPasswordValid(password) || !isPasswordValid(password_confirm)) {
      toastManager.add('Le mot de passe doit faire au moins 8 caractères', { appearance: TOAST.ERROR })
      return
    }

    const token = this.props.match.params.token
    if (!token) {
      toastManager.add('Jeton de sécurité manquant', { appearance: TOAST.ERROR })
      return
    }

    this.setState({ isLoading: true })
    const newPass = {
      new_password: password,
      token: token
    }
    Api.resetPassword(newPass).then(res => {
      if (!res.data.data.error) {
        this.setState({ isLoading: false })
        toastManager.add('Votre mot de passe a bien été régénéré', { appearance: TOAST.SUCCESS })
        this.props.history.push('/')
      } else {
        this.setState({ isLoading: false })
        toastManager.add('Une erreur est survenue', { appearance: TOAST.ERROR })
      }
    })
  }

  render() {
    const { classes } = this.props
    const { isLoading, password, password_confirm } = this.state

    return (
      <FullCenteredLayout title="Réinitialisation du mot de passe">
        <Paper className={classes.paper}>
          <div>
            <form autoComplete="off" onSubmit={this.reinit}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <PasswordInput
                    required
                    fullWidth
                    margin="normal"
                    value={password}
                    name="password"
                    label="Mot de passe"
                    placeholder="Entrez votre mot de passe"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PasswordInput
                    required
                    fullWidth
                    margin="normal"
                    value={password_confirm}
                    name="password_confirm"
                    label="Confirmation"
                    placeholder="Confirmez votre mot de passe"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <div className={classes.paperBtn}>
                <Button style={{ marginBottom: '12px' }} type="submit" align="center" size="large" variant="contained" color="secondary" onClick={this.reinit}>
                  {isLoading ? <CircularProgress size="24px" color="primary" className={classes.loader} /> : 'Réinitialiser mon mot de passe'}
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </FullCenteredLayout>
    )
  }
}

export default withToastManager(withRouter(withUser(withStyles(styles)(Reset))))
