import React, { useEffect } from 'react'
import Api from '../../services/Api'
import KRGlue from '@lyracom/embedded-form-glue'

const PaymentFormMobile = ({ order, url, publicKey }) => {
  async function handleSubmit(response) {
    // The payment response is here
    const paymentBtn = document.querySelector('.kr-payment-button > span')
    paymentBtn.innerHTML = response.clientAnswer.orderStatus

    const spinner = document.querySelector('.kr-spinner')
    spinner.style.display = 'none'
    paymentBtn.style.display = 'block'

    if (response.clientAnswer.orderStatus === 'PAID') {
      window.location.replace(process.env.PUBLIC_URL + '/paiement-ok.html')
    } else {
      window.location.replace(process.env.PUBLIC_URL + '/paiement-erreur.html')
    }
  }

  useEffect(() => {
    Api.getPayzenFormToken({ order: order.id }).then(response => {
      const { formToken } = response.data.data

      KRGlue.loadLibrary(url, publicKey)
        .then(({ KR }) => {
          return KR.setFormConfig({ formToken, language: 'fr-FR' })
        })
        .then(({ KR }) => {
          return KR.onSubmit(handleSubmit)
        })
        .then(({ KR }) => {
          return KR.addForm('#myPaymentForm')
        })
        .then(({ KR, result }) => {
          return KR.showForm(result.formId)
        })
        .catch(err => {
          // Any error will be thrown here
          console.error(err)
        })
    })
  }, [])

  return <div id="myPaymentForm" />
}

export default PaymentFormMobile
