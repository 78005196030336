import React from 'react'
import { makeStyles } from '@material-ui/styles'
import FirstStep from '../../components/process/FirstStep'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  }
}))

const Home = props => {
  const classes = useStyles()
  const promo = props.location.state ? props.location.state.promo : false

  return (
    <div className={classes.section}>
      <FirstStep promo={promo} />
    </div>
  )
}

export default Home
