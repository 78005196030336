import React, { useState, useContext } from 'react'
import { Button, CircularProgress, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import PasswordInput from '../../components/form/PasswordInput'
import Api from '../../services/Api'
import { TOAST, useToasts } from '../../hooks/HookToasts'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: 52
  },
  miniLabel: {
    fontSize: 12
  },
  photoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 16
  },
  btn: {
    marginRight: 5,
    marginLeft: 'auto'
  }
}))

const DeleteAccountForm = props => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState('')
  const { userContext } = useContext(UserContext)
  const { add } = useToasts()

  async function deleteAccount(e) {
    e.preventDefault()
    setLoading(true)

    try {
      const res = await Api.deleteAccount({ user_id: userContext.getId(), password: password })
      if (res.data.data.error) {
        add('Une erreur est survenue', TOAST.ERROR)
        setLoading(false)
        return
      }
      userContext.logout()
    } catch (error) {
      console.error(error)
      add('Une erreur est survenue', TOAST.ERROR)
      setLoading(false)
    }
  }

  function handleClose() {
    setOpen(false)
    setPassword('')
  }

  return (
    <>
      <Button
        justify="center"
        style={{ marginBottom: '12px' }}
        type="submit"
        size="large"
        variant="contained"
        color="secondary"
        onClick={() => {
          setOpen(true)
        }}
      >
        Supprimer mon compte
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className={classes.modalInline}>
          <DialogTitle id="form-dialog-title">Supprimer mon compte</DialogTitle>
        </div>

        <DialogContent>
          <Typography>Veuillez entrer votre mot de passe pour confirmer votre suppression de compte. Attention, cette action ne peut être annulée.</Typography>
          <form onSubmit={deleteAccount}>
            <PasswordInput
              required
              fullWidth
              margin="normal"
              value={password}
              name="password"
              label="Mot de passe"
              placeholder="Entrez votre mot de passe"
              onChange={e => setPassword(e.currentTarget.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Annuler
          </Button>
          <Button onClick={deleteAccount} variant="contained" color="secondary" disabled={password.length === 0}>
            {loading ? <CircularProgress size="24px" color="primary" /> : 'Supprimer'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteAccountForm
