import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import OrderTeaser from '../../components/entities/OrderTeaser'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  new: {
    margin: '50px auto',
    textAlign: 'center'
  }
}))

const OrdersList = props => {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h1">{props.title}</Typography>
      {props.orders.map((order, key) => (
        <OrderTeaser order={order} key={`order${key}`} withLinks />
      ))}
      <div className={classes.new}>
        <Button variant="contained" color="primary" size="large" component={Link} to={{ pathname: '/client', state: { add: true } }}>
          Nouvelle Commande
        </Button>
      </div>
    </>
  )
}

export default OrdersList
