import React, { useState, useContext, useEffect } from 'react'
import { Typography, Paper, Table, TableRow, TableCell, TableHead, TableBody, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import { TOAST, useToasts } from '../../hooks/HookToasts'
import { FileCopyOutlined, EmailOutlined } from '@material-ui/icons'
import defaultImg from '../../assets/default.png'
import config from '../../config'
import { copyValue } from '../../services/helpers'
import ConfirmationDialog from '../../components/dialog/ConfirmationDialog'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  invite: {
    marginTop: theme.typography.pxToRem(15)
  },
  inviteLink: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    margin: `${theme.typography.pxToRem(10)} 0`,
    display: 'block'
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  btns: {
    margin: `${theme.typography.pxToRem(10)} 0 ${theme.typography.pxToRem(15)}`
  },
  btn: {
    marginRight: `${theme.typography.pxToRem(5)}`
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-start'
  },
  avatar: {
    ...theme.common.avatar,
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30)
  },
  bold: {
    ...theme.common.bold
  }
}))

const Users = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false)
  const { add } = useToasts()
  const shareLink = `${config.publicURL}inscription?u=${userContext.getId()}`

  function getUsers() {
    Api.getEnterpriseUsers(userContext.getId()).then(res => {
      if (res.data && res.data.success) {
        setUsers(res.data.data)
      }
    })
  }

  useEffect(() => {
    getUsers()
  }, [])

  function revokeUser(user) {
    Api.revokeUser(user.id).then(res => {
      if (res.data && res.data.success) {
        getUsers()
      }
    })
  }

  function getEmail() {
    let header = "mailto: ?subject=Rejoins Box'n services&body="
    let email = "Bonjour,\n\nVoici le lien pour créer votre compte pro box'n services :\n"
    email += shareLink + '\n\n'
    email += 'Vous pourrez, via ce compte, utiliser les services de conciergerie à titre professionnel sans paiement par CB de votre commande.\n'
    email +=
      'Chaque commande donnera lieu à une facture au nom de l\'entreprise à télécharger dans la rubrique "Facture" dans le menu de gauche et à transmettre à votre comptabilité pour règlement.\n'
    email += "L'utilisation de ce compte reste sous votre responsabilité."
    return header + encodeURI(email)
  }

  function copyLink() {
    copyValue(shareLink)
    add('Lien copié !', TOAST.SUCCESS)
  }

  const collabs = (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nom d'utilisateur</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map(user => (
          <TableRow key={user.id}>
            <TableCell>
              <div className={classes.inline}>
                <img className={classes.avatar} src={user.img ? user.img : defaultImg} alt={`${user.firstname} ${user.lastname}`} />
                <div>
                  <Typography>
                    {user.firstname} {user.lastname}
                  </Typography>
                  <Typography>{user.email}</Typography>
                </div>
              </div>
            </TableCell>
            <TableCell align="right">
              <Button variant="contained" size="small" color="primary" onClick={() => setOpen(true)}>
                Révoquer
              </Button>
            </TableCell>
            <ConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onConfirm={() => revokeUser(user)}
              title="Utilisateur pro"
              text="Êtes-vous sûr de vouloir supprimer ce compte professionnel ?"
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  return (
    <>
      <div className={classes.section}>
        <Typography variant="h1">Utilisateurs</Typography>

        {users.length > 0 ? (
          <Paper className={classes.sectionPaper}>{collabs}</Paper>
        ) : (
          <Typography className={classes.noResults} variant="h4">
            Aucun collaborateur n'est encore rattaché à votre compte.
          </Typography>
        )}
      </div>

      <div className={classes.section}>
        <Typography variant="h1">Invitez des collaborateurs</Typography>

        <Paper className={classes.sectionPaper}>
          <Typography>
            Le compte PRO permet à vos collaborateurs d'utiliser les services à titre professionnel sans paiement par CB de leurs commandes. Chaque commande
            donnera lieu à une facture au nom de l'entreprise à télécharger dans la rubrique "Facture" dans le menu de gauche et à transmettre à votre
            comptabilité pour règlement.
          </Typography>
          <Typography className={classes.bold}>
            L'utilisation de ces comptes reste sous votre responsabilité et toute commande terminée sera facturée à l'entreprise.
          </Typography>

          <Typography className={classes.invite}>Partagez le lien d'inscription: </Typography>
          <Typography>
            <a href={shareLink} target="_blank" rel="noreferrer noopener" className={classes.inviteLink}>
              {shareLink}
            </a>
          </Typography>
          <div className={classes.btns}>
            <Button variant="contained" color="primary" className={classes.btn} onClick={copyLink}>
              <FileCopyOutlined className={classes.leftIcon} />
              Copier le lien
            </Button>
            <a
              href={getEmail()}
              target="_blank"
              onClick={e => {
                window.open(getEmail())
                e.preventDefault()
              }}
              rel="noopener noreferrer"
            >
              <Button variant="contained" color="primary" className={classes.btn}>
                <EmailOutlined className={classes.leftIcon} />
                Envoyer par email
              </Button>
            </a>
          </div>
        </Paper>
      </div>
    </>
  )
}

export default Users
