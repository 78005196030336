import React from 'react'
import { Typography, Button, Card, CardHeader, CardActions, CardContent, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TOAST, useToasts } from '../../hooks/HookToasts'
import { copyValue } from '../../services/helpers'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(10)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  phrase: {
    '& span': {
      position: 'relative',
      display: 'inline-block',
      zIndex: 1,
      '&:before': {
        content: '""',
        left: 0,
        right: 0,
        bottom: theme.typography.pxToRem(1),
        height: theme.typography.pxToRem(3),
        position: 'absolute',
        zIndex: -1,
        backgroundColor: theme.palette.secondary.main
      }
    }
  }
}))

const PromoCode = props => {
  const classes = useStyles()
  const { code, history } = props
  const { add } = useToasts()

  const handleClick = e => {
    copyValue(code.name)
    add('Lien copié !', TOAST.SUCCESS, [
      <Button style={{ color: '#fff', borderColor: '#fff' }} variant="outlined" onClick={() => history.push('/client', { add: true, promo: code })}>
        Passer commande
      </Button>
    ])
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
            {`-${code.reduction} ${code.is_percent ? '%' : '€'}`}
          </Avatar>
        }
        title="à valoir sur votre prochaine commande"
      />
      <CardContent>
        <Typography className={classes.phrase}>{code.desc}</Typography>
        <Typography className={classes.phrase}>
          Avec le code <span>{code.name}</span>
        </Typography>
      </CardContent>
      {history && (
        <CardActions className={classes.actions} disableSpacing>
          <Button variant="contained" color="secondary" onClick={handleClick}>
            Copier le code
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default PromoCode
