import React, { useState, useContext, useEffect } from 'react'
import { CircularProgress, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import OrderTeaser from '../../components/entities/OrderTeaser'
import LinkButtonOption from '../../components/buttons/LinkButtonOption'
import Api from '../../services/Api'
import Pagination from '../../components/layout/Pagination'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  filterContainer: {
    display: 'inline-block',
    marginBottom: theme.typography.pxToRem(15),
    marginLeft: theme.typography.pxToRem(15)
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  }
}))

const defaultPlace = { value: null, label: 'Tous les lieux' }

const Orders = props => {
  const classes = useStyles()
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedPlace, setSelectedPlace] = useState({})
  const [places, setPlaces] = useState([defaultPlace])
  const { userContext } = useContext(UserContext)
  const [paginationLinks, setPaginationLinks] = useState({})
  const [paginationMeta, setPaginationMeta] = useState({})

  useEffect(() => {
    Api.getUserPlaces(userContext.getId()).then(res => {
      if (res.data) {
        let p = res.data.data.map(pl => ({
          ...pl,
          value: pl.id,
          label: pl.name
        }))
        p = [defaultPlace, ...p]
        setPlaces(p)
      }
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    Api.getOrders(userContext.getRole(), userContext.getId(), { place: selectedPlace }).then(res => {
      if (res.data) {
        setOrdersData(res.data)
        setLoading(false)
      }
    })
  }, [selectedPlace])

  const setOrdersData = data => {
    setOrders(data.data)
    setPaginationLinks({ prev: data.links.prev, next: data.links.next })
    setPaginationMeta({ currentPage: data.meta.current_page, pageCount: data.meta.last_page })
  }

  return (
    <div className={classes.section}>
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h1">Mes commandes</Typography>
        </Grid>
        <Grid item>
          <div className={classes.filterContainer}>
            <LinkButtonOption
              onChange={place => {
                setSelectedPlace(place)
              }}
              options={places}
            />
          </div>
        </Grid>
      </Grid>

      {loading ? (
        <CircularProgress />
      ) : orders.length > 0 ? (
        <>
          {orders.map((order, key) => {
            return <OrderTeaser order={order} key={`order${key}`} withLinks />
          })}
          <Pagination
            links={paginationLinks}
            meta={paginationMeta}
            onChangePage={data => {
              if (data) {
                setOrdersData(data)
                props.history.push(`?p=${data.meta_current_page}`)
                window.scrollTo(0, 0)
              }
            }}
          />
        </>
      ) : (
        <Typography className={classes.noResults} variant="h4">
          Aucune commande ne correspond à ces critères.
        </Typography>
      )}
    </div>
  )
}

export default Orders
