import React from 'react'

const Logo = props => (
  <svg width="207" height="44" viewBox="0 0 207 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M61.9291 39.8187C60.9117 39.8187 60.0264 39.561 59.2799 39.0457C58.5333 38.5304 58.0312 37.8433 57.7802 36.9844L58.0444 36.555V38.5568C58.0444 38.94 57.9387 39.2373 57.7339 39.4487C57.5291 39.6601 57.2384 39.7724 56.8751 39.7724C56.4919 39.7724 56.2012 39.6667 55.9898 39.4487C55.785 39.2307 55.6793 38.9334 55.6793 38.5568V23.8373C55.6793 23.4409 55.785 23.137 55.9898 22.9322C56.1946 22.7274 56.4919 22.6217 56.8751 22.6217C57.2582 22.6217 57.5555 22.7274 57.7669 22.9322C57.985 23.137 58.0907 23.4409 58.0907 23.8373V30.5166H57.8066C58.0576 29.6709 58.5597 29.0037 59.3063 28.5015C60.0528 27.9994 60.9249 27.7484 61.9291 27.7484C62.9796 27.7484 63.8847 27.9928 64.651 28.4883C65.4174 28.9838 66.0054 29.6709 66.4282 30.5628C66.851 31.4547 67.0624 32.525 67.0624 33.7802C67.0624 35.0024 66.851 36.0727 66.4282 36.9844C66.0054 37.8961 65.4108 38.5964 64.6378 39.0853C63.8648 39.5742 62.9664 39.8187 61.9291 39.8187ZM61.3345 37.9556C62.0018 37.9556 62.5832 37.797 63.0787 37.4799C63.5742 37.1628 63.9507 36.6937 64.2216 36.0727C64.4925 35.4517 64.6246 34.6919 64.6246 33.7802C64.6246 32.4127 64.3273 31.382 63.7327 30.6817C63.1381 29.9814 62.3387 29.6313 61.3345 29.6313C60.6805 29.6313 60.1057 29.7832 59.6036 30.0937C59.1015 30.4042 58.7183 30.8667 58.4474 31.4877C58.1766 32.1087 58.0444 32.8685 58.0444 33.7802C58.0444 35.1346 58.3483 36.1652 58.9495 36.8787C59.5573 37.5988 60.3501 37.9556 61.3345 37.9556Z"
      fill="#1D1D1B"
    />
    <path
      d="M74.8318 39.8187C73.6558 39.8187 72.6384 39.5742 71.7928 39.0787C70.9471 38.5832 70.2799 37.8829 69.8042 36.9778C69.3285 36.0727 69.0907 35.009 69.0907 33.7802C69.0907 32.8421 69.2228 32.0097 69.4937 31.2763C69.7645 30.543 70.1477 29.9153 70.6498 29.3802C71.1519 28.8451 71.7531 28.4421 72.46 28.1646C73.167 27.8871 73.9597 27.7484 74.8318 27.7484C75.9946 27.7484 76.9988 27.9928 77.851 28.4883C78.7033 28.9838 79.3639 29.6775 79.8396 30.576C80.3153 31.4745 80.5531 32.5448 80.5531 33.7868C80.5531 34.7118 80.4144 35.5442 80.1501 36.2907C79.8793 37.0373 79.4961 37.6715 78.994 38.2C78.4919 38.7286 77.8907 39.125 77.1838 39.4025C76.4769 39.6799 75.6907 39.8187 74.8318 39.8187ZM74.8318 37.9556C75.4859 37.9556 76.054 37.797 76.5495 37.4799C77.045 37.1628 77.4282 36.6937 77.7057 36.0727C77.9832 35.4517 78.1219 34.6919 78.1219 33.7802C78.1219 32.4127 77.818 31.382 77.2168 30.6817C76.609 29.9814 75.8162 29.6313 74.8318 29.6313C74.1645 29.6313 73.5832 29.7832 73.0877 30.0937C72.5922 30.4042 72.209 30.8667 71.9315 31.4877C71.654 32.1087 71.5153 32.8685 71.5153 33.7802C71.5153 35.1346 71.8192 36.1652 72.4204 36.8787C73.0282 37.5988 73.8342 37.9556 74.8318 37.9556Z"
      fill="#1D1D1B"
    />
    <path
      d="M83.3742 39.746C83.0901 39.746 82.8522 39.6601 82.6739 39.4949C82.4889 39.3298 82.3964 39.1184 82.3898 38.8607C82.3832 38.6097 82.4889 38.3256 82.7135 38.0283L86.7435 32.994V34.1898L82.9778 29.4925C82.7201 29.2085 82.6012 28.931 82.621 28.6667C82.6408 28.4024 82.7399 28.191 82.9315 28.0325C83.1231 27.8739 83.3543 27.7946 83.6252 27.7946C83.9291 27.7946 84.1802 27.8475 84.3784 27.9466C84.5766 28.0523 84.7616 28.2241 84.9399 28.4619L88.1111 32.4919H87.2523L90.4498 28.4619C90.6216 28.2241 90.8132 28.0523 91.0114 27.9466C91.2096 27.8409 91.4541 27.7946 91.7381 27.7946C92.0222 27.7946 92.2601 27.8805 92.4384 28.0457C92.6234 28.2109 92.7159 28.4289 92.7225 28.6865C92.7291 28.9508 92.6168 29.2217 92.379 29.5124L88.6132 34.137V33.0667L92.6432 38.0283C92.8811 38.3124 93 38.5898 92.9868 38.8541C92.9802 39.1184 92.8811 39.3298 92.6895 39.4949C92.4979 39.6601 92.2601 39.746 91.976 39.746C91.6919 39.746 91.4475 39.6931 91.2493 39.5808C91.0511 39.4685 90.8529 39.3033 90.6679 39.0787L87.2589 34.7844H88.0715L84.6823 39.0787C84.5039 39.2835 84.3189 39.4487 84.1207 39.5676C83.9027 39.6865 83.6583 39.746 83.3742 39.746Z"
      fill="#1D1D1B"
    />
    <path
      d="M97.1556 28.0127C96.9772 28.2373 96.7922 28.3628 96.594 28.3958C96.3958 28.4289 96.224 28.3892 96.0787 28.2901C95.9334 28.1844 95.8541 28.0391 95.8409 27.8343C95.8277 27.6361 95.9135 27.4181 96.1051 27.1802C96.3892 26.8169 96.5874 26.4469 96.6865 26.0835C96.7922 25.7202 96.8385 25.3898 96.8385 25.1058L96.931 25.7268C96.4553 25.7268 96.0655 25.588 95.7748 25.3106C95.4775 25.0331 95.3322 24.6631 95.3322 24.2006C95.3322 23.758 95.4643 23.3946 95.7352 23.1172C96.006 22.8397 96.3694 22.7009 96.8319 22.7009C97.3075 22.7009 97.6841 22.8595 97.9616 23.1766C98.2391 23.4937 98.3778 23.9562 98.3778 24.5574C98.3778 24.9076 98.3448 25.2709 98.2721 25.6541C98.1994 26.0373 98.0805 26.4271 97.9021 26.8235C97.7436 27.2133 97.4925 27.6097 97.1556 28.0127Z"
      fill="#FF5D4E"
    />
    <path
      d="M102.573 39.7658C102.19 39.7658 101.899 39.6601 101.688 39.4421C101.483 39.2241 101.377 38.9268 101.377 38.5502V29.0102C101.377 28.6138 101.483 28.3099 101.688 28.1051C101.893 27.9003 102.183 27.7946 102.547 27.7946C102.93 27.7946 103.22 27.9003 103.419 28.1051C103.617 28.3099 103.716 28.6138 103.716 29.0102V30.8733L103.452 30.4439C103.802 29.552 104.35 28.8847 105.097 28.4289C105.843 27.973 106.702 27.7484 107.673 27.7484C108.598 27.7484 109.365 27.9202 109.972 28.2637C110.587 28.6072 111.043 29.1226 111.347 29.8163C111.65 30.5099 111.802 31.3754 111.802 32.4259V38.5568C111.802 38.94 111.697 39.2373 111.492 39.4487C111.287 39.6601 110.99 39.7724 110.607 39.7724C110.21 39.7724 109.906 39.6667 109.702 39.4487C109.497 39.2307 109.391 38.9334 109.391 38.5568V32.5712C109.391 31.567 109.199 30.8403 108.81 30.3778C108.42 29.9154 107.812 29.6841 106.986 29.6841C106.015 29.6841 105.242 29.988 104.661 30.5892C104.079 31.1904 103.789 32.003 103.789 33.0205V38.5502C103.789 39.3628 103.379 39.7658 102.573 39.7658Z"
      fill="#1D1D1B"
    />
    <path
      d="M125.491 39.8187C124.791 39.8187 124.071 39.7394 123.331 39.5808C122.591 39.4223 121.911 39.1514 121.29 38.7682C121.085 38.6229 120.939 38.4643 120.86 38.2925C120.781 38.1208 120.754 37.9358 120.774 37.7574C120.801 37.5724 120.867 37.4139 120.992 37.2817C121.118 37.1496 121.263 37.0637 121.448 37.0307C121.633 36.9976 121.825 37.0373 122.029 37.1496C122.65 37.4997 123.245 37.7442 123.82 37.8763C124.395 38.0151 124.956 38.0811 125.511 38.0811C126.357 38.0811 126.991 37.9292 127.42 37.6253C127.85 37.3214 128.061 36.9184 128.061 36.4096C128.061 35.9934 127.916 35.6763 127.632 35.4451C127.348 35.2139 126.918 35.0355 126.344 34.91L124.077 34.4541C123.093 34.2493 122.347 33.8793 121.838 33.3574C121.329 32.8355 121.071 32.1616 121.071 31.3556C121.071 30.6223 121.27 29.988 121.666 29.4463C122.062 28.9045 122.617 28.4883 123.338 28.191C124.051 27.9003 124.877 27.7484 125.815 27.7484C126.515 27.7484 127.176 27.8343 127.797 28.0127C128.418 28.191 128.986 28.4487 129.515 28.7988C129.72 28.9112 129.858 29.0565 129.918 29.2415C129.984 29.4265 129.99 29.6048 129.951 29.7766C129.911 29.955 129.825 30.1069 129.7 30.2391C129.574 30.3712 129.416 30.4505 129.237 30.4769C129.052 30.5033 128.854 30.4571 128.629 30.3448C128.154 30.0409 127.678 29.8295 127.209 29.7039C126.74 29.5784 126.277 29.5124 125.815 29.5124C124.969 29.5124 124.342 29.6643 123.919 29.9748C123.496 30.2853 123.285 30.7015 123.285 31.2301C123.285 31.6265 123.41 31.9568 123.668 32.2079C123.919 32.4655 124.322 32.6439 124.864 32.7562L127.13 33.212C128.16 33.4169 128.947 33.767 129.482 34.2625C130.017 34.758 130.281 35.4253 130.281 36.2643C130.281 37.361 129.845 38.2265 128.966 38.8607C128.094 39.4949 126.938 39.8187 125.491 39.8187Z"
      fill="#1D1D1B"
    />
    <path
      d="M138.414 39.8187C137.139 39.8187 136.049 39.5742 135.137 39.0919C134.225 38.6097 133.512 37.916 133.016 37.0175C132.514 36.119 132.263 35.0487 132.263 33.8067C132.263 32.5976 132.508 31.5406 133.003 30.6355C133.499 29.7304 134.172 29.0235 135.031 28.5148C135.89 28.006 136.868 27.755 137.965 27.755C138.777 27.755 139.497 27.8871 140.138 28.1514C140.772 28.4157 141.321 28.7922 141.77 29.2943C142.226 29.7964 142.563 30.4042 142.794 31.1178C143.025 31.8313 143.138 32.6373 143.138 33.5292C143.138 33.8 143.058 34.0049 142.9 34.137C142.741 34.2691 142.503 34.3418 142.186 34.3418H134.172V32.8619H141.519L141.116 33.1988C141.116 32.4061 141.004 31.7256 140.772 31.1706C140.541 30.6157 140.198 30.1862 139.748 29.8955C139.293 29.5982 138.738 29.4529 138.07 29.4529C137.324 29.4529 136.69 29.6313 136.174 29.9748C135.659 30.325 135.263 30.8072 134.992 31.4283C134.721 32.0493 134.589 32.7694 134.589 33.6018V33.7472C134.589 35.1478 134.919 36.1982 135.58 36.9051C136.24 37.6121 137.198 37.9688 138.453 37.9688C138.929 37.9688 139.438 37.9027 139.966 37.7772C140.495 37.6517 141.004 37.4337 141.479 37.1364C141.75 36.9778 141.995 36.9051 142.206 36.9184C142.424 36.9316 142.596 37.0042 142.728 37.1364C142.86 37.2685 142.946 37.4205 142.979 37.6121C143.012 37.8037 142.979 37.9952 142.887 38.1934C142.794 38.3916 142.622 38.57 142.384 38.7286C141.843 39.0919 141.215 39.3694 140.502 39.5544C139.788 39.7394 139.081 39.8187 138.414 39.8187Z"
      fill="#1D1D1B"
    />
    <path
      d="M146.976 39.7658C146.58 39.7658 146.276 39.6601 146.071 39.4421C145.866 39.2241 145.76 38.9268 145.76 38.5502V29.0102C145.76 28.6138 145.866 28.3099 146.071 28.1051C146.276 27.9003 146.566 27.7946 146.93 27.7946C147.313 27.7946 147.604 27.9003 147.802 28.1051C148 28.3099 148.099 28.6138 148.099 29.0102V30.7544H147.861C148.112 29.803 148.581 29.0763 149.269 28.5808C149.949 28.0853 150.828 27.8012 151.891 27.722C152.175 27.7087 152.407 27.7748 152.572 27.9334C152.737 28.0919 152.829 28.3364 152.849 28.6733C152.882 29.0102 152.81 29.2745 152.631 29.4727C152.453 29.6709 152.189 29.7898 151.819 29.8163L151.363 29.8625C150.326 29.955 149.539 30.2853 149.004 30.8403C148.463 31.3952 148.192 32.1616 148.192 33.1328V38.5436C148.192 38.9268 148.093 39.2241 147.894 39.4355C147.696 39.6601 147.392 39.7658 146.976 39.7658Z"
      fill="#1D1D1B"
    />
    <path
      d="M159.879 39.7658C159.548 39.7658 159.258 39.6799 159.007 39.5015C158.756 39.3232 158.557 39.0589 158.399 38.6889L154.415 29.4331C154.29 29.149 154.237 28.8781 154.27 28.6205C154.303 28.3694 154.422 28.1646 154.626 28.0127C154.831 27.8607 155.109 27.788 155.459 27.788C155.763 27.788 156.001 27.8607 156.172 28.0061C156.344 28.1514 156.509 28.3958 156.648 28.746L160.249 37.6187H159.581L163.255 28.746C163.4 28.3958 163.559 28.1514 163.744 28.0061C163.929 27.8607 164.186 27.788 164.517 27.788C164.801 27.788 165.032 27.8607 165.197 28.0127C165.362 28.1646 165.468 28.3628 165.508 28.6072C165.547 28.8517 165.501 29.1226 165.375 29.4067L161.345 38.6823C161.2 39.0457 161.002 39.3166 160.751 39.4949C160.506 39.6799 160.216 39.7658 159.879 39.7658Z"
      fill="#1D1D1B"
    />
    <path
      d="M168.969 25.3634C168.494 25.3634 168.124 25.2445 167.86 25.0066C167.595 24.7688 167.463 24.4319 167.463 24.0024C167.463 23.5598 167.595 23.2162 167.86 22.9916C168.124 22.7604 168.494 22.6481 168.969 22.6481C169.445 22.6481 169.815 22.7604 170.079 22.9916C170.344 23.2229 170.476 23.5598 170.476 24.0024C170.476 24.4319 170.344 24.7688 170.079 25.0066C169.815 25.2445 169.445 25.3634 168.969 25.3634ZM168.969 39.746C168.586 39.746 168.296 39.6337 168.084 39.4025C167.873 39.1712 167.774 38.8475 167.774 38.4379V29.1358C167.774 28.7196 167.879 28.4024 168.084 28.1844C168.289 27.9598 168.586 27.8475 168.969 27.8475C169.353 27.8475 169.65 27.9598 169.861 28.1844C170.079 28.409 170.185 28.7262 170.185 29.1358V38.4379C170.185 38.8541 170.079 39.1712 169.875 39.4025C169.663 39.6271 169.366 39.746 168.969 39.746Z"
      fill="#1D1D1B"
    />
    <path
      d="M178.694 39.8187C177.505 39.8187 176.475 39.5676 175.609 39.0655C174.744 38.5634 174.07 37.8565 173.594 36.9448C173.118 36.0331 172.881 34.9562 172.881 33.7142C172.881 32.7892 173.013 31.9634 173.284 31.2235C173.554 30.4835 173.938 29.8625 174.44 29.3538C174.942 28.8451 175.55 28.4487 176.276 28.1712C176.996 27.8937 177.809 27.755 178.694 27.755C179.249 27.755 179.837 27.8409 180.445 28.0061C181.06 28.1712 181.628 28.4487 182.15 28.8319C182.354 28.9574 182.487 29.116 182.546 29.2943C182.599 29.4793 182.612 29.6643 182.572 29.8559C182.533 30.0475 182.447 30.2127 182.308 30.3448C182.169 30.4835 182.011 30.5628 181.819 30.5826C181.628 30.6024 181.423 30.5562 181.198 30.4307C180.815 30.1598 180.425 29.9682 180.029 29.8493C179.632 29.7304 179.249 29.6709 178.886 29.6709C178.311 29.6709 177.809 29.7634 177.373 29.9484C176.937 30.1334 176.567 30.391 176.263 30.7346C175.959 31.0781 175.735 31.5009 175.583 32.0097C175.431 32.5184 175.358 33.0997 175.358 33.7538C175.358 35.0553 175.662 36.0793 176.276 36.8193C176.891 37.5592 177.756 37.9292 178.886 37.9292C179.249 37.9292 179.632 37.8763 180.029 37.764C180.425 37.6517 180.815 37.4601 181.198 37.1892C181.423 37.0637 181.628 37.0175 181.819 37.0439C182.011 37.0703 182.163 37.1562 182.282 37.2949C182.401 37.4337 182.48 37.5922 182.52 37.7706C182.559 37.9556 182.546 38.1406 182.487 38.3322C182.42 38.5238 182.288 38.6823 182.084 38.8079C181.575 39.1712 181.02 39.4355 180.425 39.594C179.831 39.7328 179.256 39.8187 178.694 39.8187Z"
      fill="#1D1D1B"
    />
    <path
      d="M189.932 39.8187C188.657 39.8187 187.567 39.5742 186.655 39.0919C185.744 38.6097 185.03 37.916 184.535 37.0175C184.033 36.119 183.781 35.0487 183.781 33.8067C183.781 32.5976 184.026 31.5406 184.521 30.6355C185.017 29.7304 185.691 29.0235 186.55 28.5148C187.408 28.006 188.386 27.755 189.483 27.755C190.296 27.755 191.016 27.8871 191.657 28.1514C192.291 28.4157 192.839 28.7922 193.288 29.2943C193.744 29.7964 194.081 30.4042 194.312 31.1178C194.544 31.8313 194.656 32.6373 194.656 33.5292C194.656 33.8 194.577 34.0049 194.418 34.137C194.26 34.2691 194.022 34.3418 193.705 34.3418H185.691V32.8619H193.037L192.634 33.1988C192.634 32.4061 192.522 31.7256 192.291 31.1706C192.06 30.6157 191.716 30.1862 191.267 29.8955C190.811 29.5982 190.256 29.4529 189.589 29.4529C188.842 29.4529 188.208 29.6313 187.693 29.9748C187.177 30.325 186.781 30.8072 186.51 31.4283C186.239 32.0493 186.107 32.7694 186.107 33.6018V33.7472C186.107 35.1478 186.437 36.1982 187.098 36.9051C187.759 37.6121 188.717 37.9688 189.972 37.9688C190.448 37.9688 190.956 37.9027 191.485 37.7772C192.013 37.6517 192.522 37.4337 192.998 37.1364C193.269 36.9778 193.513 36.9051 193.724 36.9184C193.942 36.9316 194.114 37.0042 194.246 37.1364C194.378 37.2685 194.464 37.4205 194.497 37.6121C194.53 37.8037 194.497 37.9952 194.405 38.1934C194.312 38.3916 194.141 38.57 193.903 38.7286C193.361 39.0919 192.733 39.3694 192.02 39.5544C191.306 39.7394 190.599 39.8187 189.932 39.8187Z"
      fill="#1D1D1B"
    />
    <path
      d="M201.355 39.8187C200.655 39.8187 199.935 39.7394 199.195 39.5808C198.455 39.4223 197.774 39.1514 197.153 38.7682C196.948 38.6229 196.803 38.4643 196.724 38.2925C196.645 38.1208 196.618 37.9358 196.638 37.7574C196.664 37.5724 196.73 37.4139 196.856 37.2817C196.981 37.1496 197.127 37.0637 197.312 37.0307C197.497 36.9976 197.688 37.0373 197.893 37.1496C198.514 37.4997 199.109 37.7442 199.684 37.8763C200.258 38.0151 200.82 38.0811 201.375 38.0811C202.22 38.0811 202.855 37.9292 203.284 37.6253C203.714 37.3214 203.925 36.9184 203.925 36.4096C203.925 35.9934 203.78 35.6763 203.496 35.4451C203.211 35.2139 202.782 35.0355 202.207 34.91L199.941 34.4541C198.957 34.2493 198.21 33.8793 197.702 33.3574C197.193 32.8355 196.935 32.1616 196.935 31.3556C196.935 30.6223 197.133 29.988 197.53 29.4463C197.926 28.9045 198.481 28.4883 199.201 28.191C199.915 27.9003 200.741 27.7484 201.679 27.7484C202.379 27.7484 203.04 27.8343 203.661 28.0127C204.282 28.191 204.85 28.4487 205.378 28.7988C205.583 28.9112 205.722 29.0565 205.781 29.2415C205.848 29.4265 205.854 29.6048 205.814 29.7766C205.775 29.955 205.689 30.1069 205.563 30.2391C205.438 30.3712 205.279 30.4505 205.101 30.4769C204.916 30.5033 204.718 30.4571 204.493 30.3448C204.017 30.0409 203.542 29.8295 203.073 29.7039C202.604 29.5784 202.141 29.5124 201.679 29.5124C200.833 29.5124 200.205 29.6643 199.783 29.9748C199.36 30.2853 199.148 30.7015 199.148 31.2301C199.148 31.6265 199.274 31.9568 199.532 32.2079C199.783 32.4655 200.186 32.6439 200.727 32.7562L202.993 33.212C204.024 33.4169 204.81 33.767 205.345 34.2625C205.881 34.758 206.145 35.4253 206.145 36.2643C206.145 37.361 205.709 38.2265 204.83 38.8607C203.958 39.4949 202.802 39.8187 201.355 39.8187Z"
      fill="#1D1D1B"
    />
    <path
      d="M43.6949 12.4211C43.5627 -0.1249 22.0847 -1.08286 9.75012 1.53336C5.32369 2.4715 1.94771 6.91775 1.43239 12.4144C0.573534 21.6373 -0.642083 34.507 5.75972 39.6006C10.2522 43.1748 15.7555 43.188 21.1531 43.3004C32.5363 43.5448 34.3465 42.9436 36.976 41.1268C37.6432 40.6643 38.2378 40.149 38.7862 39.5874L38.3237 39.0721C38.2444 38.9928 38.1585 38.9136 38.0727 38.8145L37.5904 38.2595L36.1105 36.5616C35.3111 37.4667 34.406 38.2265 33.3357 38.7682C32.1069 39.3892 30.7856 39.6998 29.3784 39.6998C27.9117 39.6998 26.6432 39.4553 25.5796 38.9664C24.5159 38.4775 23.6967 37.7904 23.1285 36.9118C22.5603 36.0331 22.2762 34.9892 22.2762 33.7934C22.2762 33.0733 22.3687 32.3994 22.5537 31.7718C22.7387 31.1376 23.0228 30.5496 23.406 29.9946C23.7892 29.4397 24.2648 28.9046 24.833 28.376C25.4012 27.8541 26.0618 27.3454 26.8018 26.8433L27.2312 26.5526C26.3657 25.555 25.7315 24.6631 25.3549 23.8835C24.9519 23.0445 24.7537 22.1724 24.7537 21.2541C24.7537 20.2301 24.9784 19.3316 25.4342 18.5586C25.8901 17.7856 26.5309 17.1844 27.3766 16.7484C28.2156 16.3123 29.1934 16.0943 30.3099 16.0943C31.7964 16.0943 33.012 16.5172 33.9369 17.3694C34.8684 18.2151 35.3375 19.3712 35.3375 20.8313C35.3375 21.6373 35.1856 22.3904 34.8883 23.0841C34.591 23.7844 34.0955 24.4649 33.415 25.1388C32.8204 25.7268 32.0078 26.3478 31.0432 26.9952L35.9189 32.6835C36.1171 32.2805 36.3153 31.8775 36.4739 31.4019C36.6919 30.7478 36.8769 30.0409 37.0288 29.2811C37.1808 28.5148 37.2931 27.7154 37.3525 26.8631C37.412 26.4535 37.5507 26.143 37.7555 25.9316C37.9603 25.7268 38.251 25.6211 38.6276 25.6211C39.0174 25.6211 39.3213 25.74 39.5261 25.9778C39.7309 26.2157 39.8168 26.5526 39.7772 26.9886C39.6913 28.0787 39.5393 29.1027 39.3147 30.0739C39.0835 31.0451 38.806 31.937 38.4757 32.7694C38.218 33.4103 37.9273 33.9916 37.6234 34.54L38.5087 35.5376L40.3651 37.5856C42.5387 34.1964 43.3447 29.7634 43.609 25.2973C43.8865 20.6529 43.7279 15.8895 43.6949 12.4211Z"
      fill="#FF5D4E"
    />
    <path
      d="M25.4078 31.7256C25.1766 32.2673 25.0642 32.8883 25.0642 33.5886C25.0642 34.7448 25.4474 35.6433 26.2138 36.2973C26.9802 36.9514 28.0769 37.2751 29.5039 37.2751C30.66 37.2751 31.6841 37.0307 32.576 36.5418C33.2961 36.1454 33.9369 35.5442 34.5183 34.8175L28.7838 28.3298L28.5063 28.528C27.6805 29.0961 27.0132 29.6313 26.5045 30.1466C26.0024 30.6553 25.6324 31.1838 25.4078 31.7256Z"
      fill="#FF5D4E"
    />
    <path
      d="M29.5039 25.2643C30.224 24.7688 30.845 24.3129 31.3009 23.9099C31.8558 23.4211 32.2588 22.9454 32.5099 22.4763C32.7609 22.0072 32.8799 21.5117 32.8799 20.9898C32.8799 20.164 32.642 19.5099 32.1663 19.0277C31.6907 18.552 31.0696 18.3075 30.3033 18.3075C29.4312 18.3075 28.7309 18.5784 28.1958 19.1069C27.6606 19.6421 27.3898 20.3424 27.3898 21.2144C27.3898 21.6505 27.4558 22.0667 27.588 22.4697C27.7267 22.8727 27.9579 23.3087 28.3015 23.7778C28.5988 24.1808 29.0084 24.6829 29.5039 25.2643Z"
      fill="#FF5D4E"
    />
  </svg>
)

export default Logo
