import React, { useState, useContext, useEffect } from 'react'
import { Paper, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import { DynamicImport } from '../DynamicImport'
import { OrderContext } from '../../stores/OrderProvider'
import config from '../../config'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper,
    marginTop: theme.typography.pxToRem(28)
  },
  module: {
    display: 'block'
  },
  orderInstruction: {
    ...theme.common.orderInstruction
  }
}))

const OrderModulesList = props => {
  const classes = useStyles()
  const [expandedPannel, setExpandedPannel] = useState(-1)
  const { userContext } = useContext(UserContext)
  const { orderContext } = useContext(OrderContext)
  const { order } = orderContext
  const role = userContext.getRole() === config.roles.utilisateur ? config.roles.utilisateur : config.roles.artisan

  useEffect(() => {
    order.steps.map((orderModule, index) => {
      if (orderModule.open) {
        setExpandedPannel(index)
      }
      return true
    })
  }, [orderContext])

  return (
    <Paper className={classes.sectionPaper}>
      <Typography className={classes.orderInstruction}>{order.instruction}</Typography>
      {order.steps.map((orderModule, index) => {
        return (
          <ExpansionPanel
            key={orderModule.module}
            expanded={expandedPannel === index}
            onChange={() => setExpandedPannel(expandedPannel === index ? -1 : index)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="h3" style={{ marginBottom: 0 }}>
                {orderModule.title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.module}>
              <DynamicImport {...orderModule} resolve={() => import(`./${role}/${orderModule.module}`)} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </Paper>
  )
}

export default OrderModulesList
