import React, { useState } from 'react'
import { Input, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import palette from '../../theme/palette'

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.typography.pxToRem(15),
    borderTop: `1px solid ${palette.grey}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  formInput: {
    marginRight: theme.typography.pxToRem(15),
    padding: theme.typography.pxToRem(5),

    flexGrow: 1,
    '&$input': {
      fontSize: '0.75rem',
      fontWeight: 300,
      lineHeight: 1.5
    }
  }
}))

export default function MessageFullForm(props) {
  const [message, setMessage] = useState('')
  const classes = useStyles()

  return (
    <form className={classes.form}>
      <Input
        multiline
        className={classes.formInput}
        placeholder="Écrivez votre texte ici"
        rowsMax="5"
        value={message}
        onChange={e => {
          setMessage(e.target.value)
        }}
      />
      <div>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={e => {
            e.preventDefault()
            setMessage('')
            props.onSubmit(message)
          }}
        >
          {props.isSending ? <CircularProgress size="24px" color="primary" /> : 'Envoyer'}
        </Button>
      </div>
    </form>
  )
}
