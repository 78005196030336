import React, { useContext } from 'react'
import { ButtonBase, Paper, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import config from '../../config'
import { priceFormat } from '../../services/helpers'
import moment from 'moment'
import 'moment/locale/fr'
import { UserContext } from '../../stores/UserProvider'
import Hour from '../misc/Hour'

moment.locale('fr')

const useStyles = makeStyles(theme => ({
  paper: {
    opacity: 0.5,
    position: 'relative'
  },
  paperSelected: {
    opacity: 1
  },
  paperUnread: {
    '&:before': {
      content: "''",
      width: theme.typography.pxToRem(7),
      height: theme.typography.pxToRem(7),
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      position: 'absolute',
      left: theme.typography.pxToRem(6),
      top: theme.typography.pxToRem(6)
    }
  },
  order: {
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    margin: `0 0 ${theme.typography.pxToRem(12)}`
  },
  cell: {
    padding: `${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(12)}`,
    borderRight: '0.063rem solid #e7ecf2',
    width: '50%'
  },
  orderService: {
    textAlign: 'center',
    width: '11rem'
  },
  place: {
    fontWeight: 700
  },
  orderInfos: {
    flexGrow: 1,
    textAlign: 'left',
    borderRight: 0
  },
  icon: {
    marginBottom: '0.625rem',
    display: 'block',
    margin: '0 auto',
    '& svg': {
      maxWidth: '2.875rem',
      height: 'auto'
    }
  }
}))

const MessageTeaser = props => {
  const classes = useStyles()
  const { order, selected, onClick } = props
  const { userContext } = useContext(UserContext)

  return (
    <Paper className={classNames(classes.paper, { [classes.paperSelected]: selected, [classes.paperUnread]: order.messages[userContext.getRole()] > 0 })}>
      <ButtonBase className={classes.order} onClick={() => onClick()}>
        <div className={classNames(classes.cell, classes.orderService)}>
          {order.service.img && <img className={classes.icon} src={order.service.img} alt={`Logo de ${order.service.name}`} />}
          <Typography variant="h3">{order.service.name}</Typography>
          {userContext.getRole() === config.roles.artisan && (
            <Typography variant="body2" className={classes.place}>
              {order.place.name}
            </Typography>
          )}
          <Typography variant="body2">{moment.unix(order.created_at).format('DD MMMM YYYY')}</Typography>
        </div>
        <div className={classNames(classes.cell, classes.orderInfos)}>
          <Typography variant="h3">{userContext.getRole() === config.roles.artisan ? order.client.name : order.artisan.name}</Typography>
          <Typography variant="body2">Ref {order.reference}</Typography>
          <Typography variant="body2">{order.place.name}</Typography>
          <Typography variant="body2">Total: {priceFormat(order.total)} €</Typography>
          {order.date_delivery_start && (
            <Typography variant="body2">
              Livraison : <Hour withYear noBold date={order.date_delivery_start} end={order.date_delivery_end} />
            </Typography>
          )}
        </div>
      </ButtonBase>
    </Paper>
  )
}

export default MessageTeaser
