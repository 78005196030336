import { Calendar, Faq, Document, Contact, Cupons, Home, Messages, Orders, Products, Settings, Sponsor } from '../assets/icons/menu'

export const icons = {
  Calendar: Calendar,
  Contact: Contact,
  Cupons: Cupons,
  Document: Document,
  Home: Home,
  Messages: Messages,
  Orders: Orders,
  Products: Products,
  Settings: Settings,
  Faq: Faq,
  Sponsor: Sponsor
}
