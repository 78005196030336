import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import ServicesBlock from '../../components/entities/ServicesBlock'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  back: {
    marginRight: 5
  },
  new: {
    margin: '50px auto',
    textAlign: 'center'
  }
}))

const Products = props => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <Typography variant="h1">{props.title}</Typography>

      <ServicesBlock
        selectOne
        onSelectionDone={services => {
          if (services.length > 0) {
            props.history.push(`/artisan/produits/${services[0].id}`, { service: services[0] })
          }
        }}
      />
    </div>
  )
}

export default withRouter(Products)
