import React, { useContext, useState, useRef } from 'react'
import classNames from 'classnames'
import { Avatar, Typography, ButtonBase, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Grow, Badge } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import { ReactComponent as Arrow } from '../../assets/icons/rightarrow.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as AccountIcon } from '../../assets/icons/account.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import palette from '../../theme/palette'
import defaultImg from '../../assets/default.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  badge: {
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  badgeContent: {
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    fontSize: '0.625rem',
    fontWeight: 700,
    letterSpacing: '0.019rem'
  },
  avatar: {
    width: 34,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  avatarImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  arrow: {
    marginLeft: 'auto'
  },
  icon: {
    marginRight: 15
  },
  hiddenXS: {
    ...theme.common.hiddenXs
  },
  menu: {
    border: '1px solid #eff3f8',
    borderTop: 0
  },
  menuItem: {
    backgroundColor: '#fff',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    opacity: 1,
    transition: theme.transitions.create('opacity background', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      backgroundColor: palette.clearRed,
      color: palette.red
    },
    '&:focus': {
      color: palette.red,
      backgroundColor: palette.clearRed
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 2,
      top: 0,
      bottom: 0,
      left: 0,
      transition: 'all .2s',
      transform: 'scale(1,0)',
      backgroundColor: palette.red
    }
  },
  menuItemActive: {
    '&&': {
      color: palette.red,
      backgroundColor: palette.clearRed
    },
    '&:before': {
      transform: 'scale(1,1)'
    },
    '&&:hover': {
      color: palette.red,
      backgroundColor: palette.clearRed
    },
    '&&:focus': {
      color: palette.red,
      backgroundColor: palette.clearRed
    }
  }
}))

const Account = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)
  const { userContext } = useContext(UserContext)
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  function handleToggle() {
    setOpen(!open)
  }

  function handleClose(event) {
    if (anchorEl.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function goToAccount(event) {
    handleClose(event)
    props.history.push(`/${userContext.getRole()}/compte`)
  }

  function logout(event) {
    handleClose(event)
    userContext.logout()
  }

  return (
    <>
      <ButtonBase
        className={props.className}
        buttonRef={anchorEl}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        disableRipple
        onClick={handleToggle}
      >
        <Badge className={classes.badge} badgeContent={userContext.isPro() ? `PRO` : null} color="primary" classes={{ badge: classes.badgeContent }}>
          <Avatar className={classes.avatar}>
            <img className={classes.avatarImg} src={userContext.user.img ? userContext.user.img : defaultImg} alt="" />
          </Avatar>
        </Badge>
        <Typography className={classes.hiddenXS} noWrap>
          {userContext.user.firstname}
        </Typography>
        <Arrow className={classNames(classes.hiddenXS, classes.arrow)} />
      </ButtonBase>
      <Popper open={open} anchorEl={anchorEl.current} transition placement="bottom-end" className={classes.popper}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} id="menu-list-grow" style={{ transformOrigin: 'center top' }}>
            <Paper elevation={0} square className={classes.menu} style={{ width: anchorEl && isBrowser ? anchorEl.current.offsetWidth : 'auto' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList disablePadding>
                  <MenuItem
                    onClick={goToAccount}
                    className={classNames(classes.menuItem, {
                      [classes.menuItemActive]: props.location.pathname === `/${userContext.getRole()}/compte`
                    })}
                  >
                    <AccountIcon className={classes.icon} />
                    <Typography>Mon compte</Typography>
                  </MenuItem>
                  <MenuItem onClick={logout} className={classes.menuItem}>
                    <LogoutIcon className={classes.icon} />
                    <Typography>Déconnexion</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default withRouter(Account)
