import React, { useState } from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { useTheme, makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BNSAppBar from '../appbar/BNSAppBar'
import BNSDrawer from '../sidebar/BNSDrawer'
import { isBrowser } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  main: {
    ...theme.common.main,
    marginLeft: 80,
    paddingLeft: 24,
    paddingRight: 24,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  mainShift: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 280
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}))

const Sidebar = props => {
  const classes = useStyles()
  const theme = useTheme()
  const isBrowserViewport = useMediaQuery(theme.breakpoints.up('md'))
  const { menu, children, placeId} = props
  const [isOpen, setIsOpen] = useState(isBrowser)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <BNSAppBar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <BNSDrawer isOpen={isOpen} toggleSidebar={toggleSidebar} menu={menu} placeId={placeId} />
      <main
        className={classNames(classes.main, {
          [classes.mainShift]: isBrowserViewport && isOpen,
          [classes.main]: isBrowserViewport && !isOpen
        })}
      >
        {children}
      </main>
    </>
  )
}

export default withRouter(Sidebar)
