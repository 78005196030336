import React, { useState } from 'react'
import { IconButton, Tabs, Tab, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ScheduleTab from './ScheduleTab'
import { ReactComponent as BackIcon } from '../../assets/icons/leftarrow.svg'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  back: {
    marginRight: 5
  }
}))

const Schedule = props => {
  const [tab, setTab] = useState(0)
  const classes = useStyles()
  const service = props.location.state ? props.location.state.service : null

  return (
    <>
      <Typography variant="h1">
        <IconButton className={classes.back} onClick={() => props.history.push('/artisan/creneaux')}>
          <BackIcon />
        </IconButton>
        {service.name}
      </Typography>
      <Paper>
        <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
          <Tab disableRipple label="Planning Global" />
          <Tab disableRipple label="Planning par lieu" />
        </Tabs>

        {tab === 0 && <ScheduleTab global />}
        {tab === 1 && <ScheduleTab />}
      </Paper>
    </>
  )
}

export default Schedule
