import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import palette from '../../theme/palette'
import { ButtonBase } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    fontFamily: ['Nunito', 'sans-serif'],
    fontSize: 12,
    fontWeight: '600',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    color: palette.dark,
    paddingBottom: 1,
    zIndex: 0,
    '&:before': {
      content: '""',
      backgroundColor: '#ff5d4e',
      height: 2,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      transition: 'all .2s',
      zIndex: -1,
      opacity: 0.6
    },
    '&:hover': {
      '&:before': {
        height: 8
      }
    }
  }
})

const LinkButton = props => {
  const classes = useStyles()
  if (props.to) {
    return <Link {...props} className={classes.root} />
  } else {
    return (
      <ButtonBase {...props} className={classes.root}>
        {props.children}
      </ButtonBase>
    )
  }
}

export default LinkButton
