import React, { useEffect, useContext, useState } from 'react'
import { UserContext } from '../../stores/UserProvider'
import { Typography, Grid, Paper } from '@material-ui/core'
import Api from '../../services/Api'
import PromoCode from '../../components/entities/PromoCode'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  }
}))

const Promo = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [codes, setCodes] = useState([])

  useEffect(() => {
    Api.getUserPromo(userContext.getId()).then(res => {
      if (!res.data.data.error) {
        setCodes(res.data.data.promocode)
      }
    })
  }, [])

  return (
    <div className={classes.section}>
      <Typography variant="h1">Promotions</Typography>
      {codes.length > 0 ? (
        <Grid container spacing={3}>
          {codes.map(code => (
            <Grid key={`${code.id}`} item xs={12} sm={6} md={4}>
              <PromoCode code={code} history={props.history} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Paper className={classes.sectionPaper}>
          <Typography>Aucun code promo pour le moment.</Typography>
        </Paper>
      )}
    </div>
  )
}

export default Promo
