import { Button, CircularProgress, Grid, Input, InputLabel, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TOAST, useToasts } from '../../hooks/HookToasts'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  miniCont: {
    maxWidth: '460px',
    margin: '0 auto'
  },
  intro: {
    marginBottom: 32
  }
}))

const Contact = props => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [contact, setContact] = useState({ phone: '' })
  const { add } = useToasts()
  const [sent, setSent] = useState(false)
  const { userContext } = useContext(UserContext)

  useEffect(() => {
    setLoading(true)

    Api.getContact()
      .then(res => { setContact(res.data.data) })
      .finally(() => { setLoading(false) })
  }, [])

  async function submit(e) {
    e.preventDefault()
    if (!message) {
      add('Un message ne peut pas être vide', TOAST.ERROR)
      return
    }

    setLoading(true)

    Api.sendContact({ user: userContext.getId(), message: message }).then(res => {
      if (res.data.data.error) {
        add('Une erreur est survenue.', TOAST.ERROR)
      } else {
        add('Votre demande de contact a bien été envoyée.', TOAST.SUCCESS)
        setMessage('')
        setSent(true)
        setLoading(false)
      }
    })
  }

  if (loading) {
    return (
      <CircularProgress size="24px" color="primary" />
    );
  }

  return (
    <div className={classes.section}>
      <Typography variant="h1">Nous contacter</Typography>
      <Paper className={classes.sectionPaper}>
        <form autoComplete="off" onSubmit={submit} className={classes.miniCont}>
          <Typography className={classes.intro}>
            Vous souhaitez nous contacter ? Notre équipe est à votre écoute ! Nous sommes joignables au {contact.phone} ou via le formulaire ci-dessous.
          </Typography>

          {sent ? (
            <>
              <Typography>Votre message a bien été envoyé ! Nous reviendrons vers vous dans les plus brefs délais.</Typography>
              <Button
                justify="center"
                style={{ margin: '12px 0' }}
                component={Link}
                size="large"
                variant="contained"
                color="secondary"
                to={`/${userContext.getRole()}`}
              >
                Revenir à l'accueil
              </Button>
            </>
          ) : (
            <>
              <Grid container spacing={4} alignItems="flex-start">
                <Grid item xs={12}>
                  <InputLabel htmlFor="message">Message</InputLabel>
                  <Input
                    id="message"
                    required
                    multiline
                    fullWidth
                    rows={4}
                    rowsMax={35}
                    value={message}
                    name="message"
                    label="Message"
                    placeholder="Entrez votre message"
                    onChange={e => setMessage(e.currentTarget.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button justify="center" style={{ marginBottom: '12px' }} type="submit" size="large" variant="contained" color="secondary" onClick={submit}>
                    Envoyer
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </form>
      </Paper>
    </div>
  )
}

export default Contact
