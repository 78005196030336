import React from 'react'
import palette from '../../../theme/palette'

const Document = ({ color = palette.primary }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={19} width={19} viewBox="0 0 60 60">
    <path fill={color} d="M38.914 0H6.5v60h47V14.586L38.914 0zm.586 3.414L50.086 14H39.5V3.414zM8.5 58V2h29v14h14v42h-43z" />
    <path
      fill={color}
      d="M16.5 17h9a1 1 0 1 0 0-2h-9a1 1 0 1 0 0 2zM33.5 37h-17a1 1 0 1 0 0 2h17a1 1 0 1 0 0-2zM43.5 30h-7a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2zM16.5 25h5a1 1 0 1 0 0-2h-5a1 1 0 1 0 0 2zM31.5 25h7a1 1 0 1 0 0-2h-7a1 1 0 1 0 0 2zM25.5 24c0 .26.11.52.29.71.19.18.45.29.71.29.26 0 .52-.11.71-.29.18-.19.29-.45.29-.71 0-.261-.11-.521-.29-.71-.37-.37-1.04-.37-1.42 0-.18.189-.29.449-.29.71zM21.5 30a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2h-10zM16.5 32c.26 0 .52-.11.71-.29.18-.19.29-.45.29-.71 0-.261-.11-.521-.29-.71-.37-.37-1.05-.37-1.42 0-.18.189-.29.439-.29.71 0 .26.11.52.29.71.19.18.45.29.71.29zM43.5 44h-7a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2zM31.5 44h-10a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2zM15.79 44.29a1.013 1.013 0 0 0 0 1.42c.19.18.45.29.71.29.27 0 .52-.11.71-.29.18-.19.29-.45.29-.71a.987.987 0 0 0-.29-.7c-.37-.38-1.04-.38-1.42-.01zM42.79 23.29c-.18.189-.29.449-.29.71 0 .26.11.52.29.71.19.18.45.29.71.29.26 0 .52-.11.71-.29.19-.19.29-.45.29-.71 0-.261-.1-.521-.29-.71-.38-.37-1.04-.37-1.42 0zM39.21 38.71c.18-.19.29-.45.29-.71 0-.261-.11-.521-.29-.71-.38-.37-1.05-.37-1.42 0-.18.189-.29.449-.29.71 0 .26.11.52.29.71.19.189.44.29.71.29s.52-.11.71-.29zM42.79 37.29c-.18.189-.29.449-.29.71 0 .26.11.52.29.71.19.18.44.29.71.29s.52-.11.71-.29c.18-.19.29-.45.29-.71 0-.261-.11-.521-.29-.71-.37-.37-1.05-.37-1.42 0z"
    />
  </svg>
)

export default Document
