import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Button, withStyles, createStyles, Paper, Typography } from '@material-ui/core'
import FullCenteredLayout from '../components/layout/FullCenteredLayout'
import { withUser } from '../stores/UserProvider'

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: '23px 20px 32px',
    maxWidth: '660px'
  },
  paperBtn: {
    textAlign: 'center',
    marginTop: 23
  }
})

class Reset extends Component {
  componentDidMount() {
    document.title = "box'n services – Page non trouvée"
    // ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    const { classes } = this.props

    return (
      <FullCenteredLayout title="Page non trouvée">
        <Paper className={classes.paper}>
          <div>
            <Typography>La page que vous cherchez n'existe pas / plus.</Typography>
            <div className={classes.paperBtn}>
              <Button style={{ marginBottom: '12px' }} type="submit" align="center" size="large" variant="contained" color="secondary" component={Link} to="/">
                Revenir à la page d'accueil
              </Button>
            </div>
          </div>
        </Paper>
      </FullCenteredLayout>
    )
  }
}

export default withRouter(withUser(withStyles(styles)(Reset)))
