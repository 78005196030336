import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Menu, MenuItem } from '@material-ui/core'
import { ReactComponent as Arrow } from '../../assets/icons/downarrow.svg'
import palette from '../../theme/palette'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: ['Nunito', 'sans-serif'],
    fontSize: 12,
    fontWeight: '600',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    color: palette.dark,
    paddingBottom: 1,
    '&:before': {
      content: '""',
      backgroundColor: '#ff5d4e',
      height: 2,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      transition: 'all .2s',
      zIndex: -1,
      opacity: 0.6
    },
    '&:hover': {
      '&:before': {
        height: 8
      }
    }
  },
  arrow: {
    marginLeft: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(2)
  },
  item: {
    fontSize: '0.75rem',
    fontWeight: 600
  }
}))

const LinkButtonOption = props => {
  const classes = useStyles()
  const { selected, onChange, options } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeItem, setActiveItem] = useState(findOption())

  function findOption() {
    return selected ? options.find(option => option.value === selected) : options[0]
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleItemClick(option) {
    setActiveItem(option)
    onChange(option.value)
    handleClose()
  }

  function handleClose() {
    setAnchorEl(null)
  }

  useEffect(() => {
    setActiveItem(findOption())
  }, [selected, options])

  return (
    <div>
      <ButtonBase className={classes.root} aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={handleClick}>
        {activeItem && activeItem.label}
        <Arrow className={classes.arrow} />
      </ButtonBase>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option, key) => (
          <MenuItem key={`${key}`} className={classes.item} onClick={() => handleItemClick(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default LinkButtonOption
