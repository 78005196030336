const priceFormat = price => {
  return price ? parseFloat(price).toFixed(2) : '0.00'
}

const copyValue = value => {
  const el = document.createElement('textarea')
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const roleRoutes = {
  10: 'client',
  20: 'client',
  30: 'entreprise',
  40: 'livreur',
  50: 'artisan'
}

const getRoleRoute = role => {
  return roleRoutes[role]
}
export { priceFormat, copyValue, getRoleRoute }
