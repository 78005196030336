import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  img: {
    ...theme.common.avatar
  }
}))

function getOrientation(file, callback) {
  var reader = new FileReader()
  reader.onload = function(e) {
    var view = new DataView(e.target.result)
    if (view.getUint16(0, false) !== 0xffd8) {
      return callback(-2)
    }
    var length = view.byteLength,
      offset = 2
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1)
      var marker = view.getUint16(offset, false)
      offset += 2
      if (marker === 0xffe1) {
        if (view.getUint32((offset += 2), false) !== 0x45786966) {
          return callback(-1)
        }

        var little = view.getUint16((offset += 6), false) === 0x4949
        offset += view.getUint32(offset + 4, little)
        var tags = view.getUint16(offset, little)
        offset += 2
        for (var i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            return callback(view.getUint16(offset + i * 12 + 8, little))
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }
    return callback(-1)
  }
  reader.readAsArrayBuffer(file)
}

/**
 *
 * @param {*} image
 * @param {*} maxWidth
 * @param {*} maxHeight
 * @param {*} quality
 * @param {*} orientation
 */
const resizeImage = (image, maxWidth, maxHeight, quality, orientation) => {
  let canvas = document.createElement('canvas')
  let width = image.width
  let height = image.height

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width)
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height)
      height = maxHeight
    }
  }

  // set proper canvas dimensions before transform & export
  if (4 < orientation && orientation < 9) {
    canvas.width = height
    canvas.height = width
  } else {
    canvas.width = width
    canvas.height = height
  }

  // transform context before drawing image
  let ctx = canvas.getContext('2d')
  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0)
      break
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height)
      break
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height)
      break
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0)
      break
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0)
      break
    case 7:
      ctx.transform(0, -1, -1, 0, height, width)
      break
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width)
      break
    default:
      break
  }

  ctx.drawImage(image, 0, 0, width, height)
  return canvas.toDataURL('image/jpeg', quality)
}

/**
 *
 * @param {*} file
 * @param {*} maxWidth
 * @param {*} maxHeight
 * @param {*} fn
 */
const resize = (file, maxWidth, maxHeight, fn) => {
  let reader = new FileReader()
  getOrientation(file, function(orientation) {
    reader.readAsDataURL(file)
    reader.onload = function(event) {
      let dataUrl = event.target.result
      let image = new Image()
      image.src = dataUrl
      image.onload = function() {
        const resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.9, orientation)
        fn(resizedDataUrl)
      }
    }
  })
}

/**
 * This hook is able to preview an image and resize it before someone will send it to the server.
 * It will preserve the aspect ratio
 *
 * @author Matière Noire - Original author Hacks Mozilla
 * @see https://hacks.mozilla.org/2011/01/how-to-develop-a-html5-image-uploader/
 * @verions 1.0
 */
export default function ScalingUpload(props) {
  const classes = useStyles()
  const [value, setValue] = useState('')

  const onChange = e => {
    var files = e.target.files
    var maxWidth = props.maxWidth
    var maxHeight = props.maxHeight
    resize(files[0], maxWidth, maxHeight, img => {
      props.onLoadEnd(img)
      setValue('')
    })
  }

  return (
    <div className={classes.field}>
      <input id={props.id} value={value} type="file" accept="image/*" style={{ display: 'none' }} onChange={onChange} />
      <img alt="upload preview" src={props.src} className={classes.img} />
    </div>
  )
}
