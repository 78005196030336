import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip } from '@material-ui/core'
import { UserContext } from '../../stores/UserProvider'
import { RemoveCircle, CheckCircle } from '@material-ui/icons/'
import Api from '../../services/Api'
import palette from '../../theme/palette'
import PlanningDialog from './PlanningDialog'
import { TOAST, useToasts } from '../../hooks/HookToasts'

const week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const useStyles = makeStyles(theme => ({
  cell: {
    width: '120px',
    textAlign: 'center',
    borderRight: `${theme.typography.pxToRem(1)} solid #e7ecf2`,
    '&:last-child': {
      borderRight: 0
    }
  }
}))

const PlanningForm = props => {
  const classes = useStyles()
  const { schedule, global, readOnly } = props
  const { userContext } = useContext(UserContext)
  const [open, setOpen] = useState({})
  const { add } = useToasts()
  const [days, setDays] = useState([{ name: 'Lu' }, { name: 'Ma' }, { name: 'Me' }, { name: 'Je' }, { name: 'Ve' }, { name: 'Sa' }, { name: 'Di' }])

  function handleClose() {
    setOpen({})
  }

  function submitPlanning(newDays) {
    const place = schedule ? schedule.place : null
    Api.setSchedule(userContext.getId(), place, JSON.stringify(newDays)).then(res => {
      if (res.data.success) {
        add('Les changements ont été enregistrés.', TOAST.SUCCESS)
      }
    })
  }

  function submitHour(day) {
    const newDays = days.map(d => {
      let newDay = { ...d }
      if (d.name === day.name) {
        newDay = { ...day }
      }
      return newDay
    })

    setDays(newDays)
    submitPlanning(newDays)

    handleClose()
  }

  useEffect(() => {
    // call API pour avoir les préférences
    if (schedule) {
      let newDays = [...days]
      week.forEach((day, index) => {
        let hours = JSON.parse(schedule[day])
        if (hours) {
          newDays[index].hours = hours
        } else {
          delete newDays[index].hours
        }
      })
      setDays(newDays)
    }
  }, [schedule])

  return (
    <>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {days.map(day => (
                <TableCell className={classes.cell} key={`${day.name}`} align="center">
                  {day.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {days.map(day => (
                <TableCell className={classes.cell} key={`content-${day.name}`} align="center">
                  {readOnly ? (
                    day.hours && day.hours.length > 0 ? (
                      day.hours.map((hour, index) => (
                        <Typography key={`${day}-${index}`}>
                          {hour.start} / {hour.end}
                        </Typography>
                      ))
                    ) : (
                      <RemoveCircle color="secondary" />
                    )
                  ) : (
                    <Tooltip
                      title={day.hours && day.hours.length > 0 ? 'Modifier ce créneau' : 'Ajouter un créneau pour ce jour'}
                      aria-label={day.hours && day.hours.length > 0 ? 'Modifier ce créneau' : 'Ajouter un créneau pour ce jour'}
                    >
                      <IconButton onClick={() => setOpen(day)}>
                        {day.hours && day.hours.length > 0 ? <CheckCircle style={{ color: palette.green }} /> : <RemoveCircle color="secondary" />}
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
        {global && (
          <Typography align="right" variant="body2">
            Attention, en modifiant le planning global, vous effacerez les planning par lieu.
          </Typography>
        )}
      </div>

      <PlanningDialog onCancel={() => setOpen(false)} onSubmit={submitHour} day={open} />
    </>
  )
}

export default withRouter(PlanningForm)
