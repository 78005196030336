import React, { createContext, useContext, useState } from 'react'
import SnackbarToast from './SnackbarToast'

const Ctx = createContext({})

export const LOGIN_MESSAGE = {
  401: "L'email ou le mot de passe ne correspond à aucun compte.",
  406: "Votre compte n'a pas été activé, merci de vérifier vos emails.",
  409: 'Un compte existe déjà avec ce mail',
  410: 'Votre email est vide',
  411: 'Votre mot de passe est vide',
  412: "Cette adresse email ne correspond pas au domaine de l'entreprise",
  500: "Le serveur n'a pas répondu, veuillez réessayer"
}

export const TOAST = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
}

// Styled Components

const ToastContainer = props => <div style={{ zIndex: 9999 }} {...props} />
const Toast = ({ children, appearance, action }) => <SnackbarToast appearance={appearance} children={children} action={action} />

// Provider
let toastCount = 0

export function HookToastProvider({ children }) {
  const [toasts, setToasts] = useState([])

  const add = (content, appearance, action) => {
    const id = toastCount++
    const toast = { content, appearance, action, id }
    setToasts([...toasts, toast])
  }
  const remove = id => {
    const newToasts = toasts.filter(t => t.id !== id)
    setToasts(newToasts)
  }
  // avoid creating a new fn on every render
  const onDismiss = id => () => remove(id)

  return (
    <Ctx.Provider value={{ add, remove }}>
      {children}
      <ToastContainer>
        {toasts.map(({ content, appearance, action, id, ...rest }) => (
          <Toast key={id} appearance={appearance} action={action} onDismiss={onDismiss(id)} {...rest}>
            {content}
          </Toast>
        ))}
      </ToastContainer>
    </Ctx.Provider>
  )
}

// Consumer

export const useToasts = () => useContext(Ctx)
