import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Typography,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent
} from '@material-ui/core'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import { RemoveCircle, Delete } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  back: {
    marginRight: 5
  },
  modalInline: {
    ...theme.common.inline
  },
  modalIcon: {
    fontSize: 20,
    color: theme.palette.primary.main
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  form: {
    marginTop: theme.typography.pxToRem(20)
  },
  submit: {
    marginLeft: theme.typography.pxToRem(10)
  },
  list: {
    margin: `${theme.typography.pxToRem(20)} 0`
  }
}))

const ExceptionForm = props => {
  const classes = useStyles()
  const { schedule, global } = props

  const [day, setDay] = useState('')
  const [askDelete, setAskDelete] = useState(null)
  const { userContext } = useContext(UserContext)
  const [exceptions, setExceptions] = useState([])

  function submit() {
    const newExceptions = [...exceptions, day]
    Api.setScheduleExceptions(userContext.getId(), schedule.place, newExceptions).then(res => {
      setExceptions(newExceptions)
    })
  }

  function remove() {
    const newExceptions = [...exceptions]
    newExceptions.splice(askDelete, 1)
    Api.setScheduleExceptions(userContext.getId(), schedule.place, newExceptions).then(res => {
      if (!res.data.data.error) {
        handleClose()
        setExceptions(newExceptions)
      }
    })
  }

  function handleClose() {
    setAskDelete(null)
  }

  useEffect(() => {
    if (schedule) {
      setExceptions(schedule.exceptions ? schedule.exceptions : [])
    }
  }, [schedule])

  return (
    <>
      <Typography variant="body2">{global && 'Attention, Les changement appliqués ici seront répercutés sur tous les lieux.'}</Typography>
      <div className={classes.list}>
        {exceptions &&
          exceptions.map((exception, index) => (
            <List key={`${exception}-${index}`} dense>
              <ListItem disableGutters divider>
                <ListItemIcon>
                  <RemoveCircle color="secondary" />
                </ListItemIcon>
                <ListItemText primary={exception} />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Delete" onClick={() => setAskDelete(index)}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
      </div>

      <div className={classes.form}>
        <div className={classes.inline}>
          <FormControl margin="none" required fullWidth>
            <InputLabel shrink htmlFor="day">
              Ajouter un nouveau jour
            </InputLabel>
            <Input id="day" type="date" value={day} onChange={e => setDay(e.currentTarget.value)} name="day" fullWidth />
          </FormControl>
          <Button className={classes.submit} onClick={submit} variant="contained" color="primary" disabled={!day}>
            Ajouter
          </Button>
        </div>
      </div>

      <Dialog open={askDelete !== null} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Confimer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Êtes-vous sûr de vouloir supprimer cette exception ? </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Annuler
          </Button>
          <Button onClick={remove} variant="contained" color="secondary" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExceptionForm
