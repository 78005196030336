import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter, Redirect } from 'react-router-dom'
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
  Paper,
  InputAdornment,
  Switch,
  CircularProgress,
  IconButton,
  Grid,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core'
import { ReactComponent as BackIcon } from '../../assets/icons/leftarrow.svg'
import ProductFormProvider, { ProductFormContext } from '../../stores/ProductFormProvider'
import Select from '../../components/form/Select'
import config from '../../config'
import ScalingUpload from '../../components/form/ScalingUpload'
import defaultImg from '../../assets/default-image.svg'
import { UserContext } from '../../stores/UserProvider'
import { useToasts, TOAST } from '../../hooks/HookToasts'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  back: {
    marginRight: 5
  },
  new: {
    margin: '50px auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  miniSelect: {
    '& > div > div > div > div:last-child > div:last-child': { padding: 5 }
  },
  photoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 16
  },
  btn: {
    marginRight: 5,
    marginLeft: 'auto'
  }
}))

const ProductForm = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const { add } = useToasts()
  const service = props.location.state ? props.location.state.service : null
  const product = props.location.state ? props.location.state.product : null

  const submit = async productFormContext => {
    const { name, price_ttc, tva, category } = productFormContext
    const fields = [
      { id: 'name', label: 'le nom du produit', value: name },
      { id: 'category', label: 'la catégorie du produit', value: category },
      { id: 'price_ttc', label: 'le prix', value: price_ttc },
      { id: 'tva', label: 'le taux de TVA', value: tva }
    ]

    for (const field of fields) {
      if (!field.value) {
        add('Veuillez renseigner ' + field.label, TOAST.ERROR)
        return
      }
    }

    const res = await productFormContext.submit()
    if (res && res.data && res.data.success && res.data.data) {
      if (res.data.data.error) {
        add(res.data.data.error, TOAST.ERROR)
      } else if (product) {
        add('Produit modifié', TOAST.SUCCESS)
        props.history.goBack()
      } else {
        add('Produit ajouté', TOAST.SUCCESS)
        props.history.goBack()
      }
    } else {
      add('Une erreur est survenue', TOAST.ERROR)
    }
  }

  if (service) {
    return (
      <ProductFormProvider product={product} user={userContext.getId()} service={service.id}>
        <ProductFormContext.Consumer>
          {({ productFormContext }) => {
            return (
              <div className={classes.section}>
                <Typography variant="h1">
                  <IconButton className={classes.back} onClick={() => props.history.goBack()}>
                    <BackIcon />
                  </IconButton>
                  {product ? 'Modifier un' : 'Créer un nouveau'} produit du service {service.name}
                </Typography>
                <Paper className={classes.sectionPaper}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <FormControl margin="normal" required fullWidth>
                            <InputLabel shrink htmlFor="name">
                              Nom
                            </InputLabel>
                            <Input
                              id="name"
                              type="text"
                              name="name"
                              onChange={productFormContext.handleChange}
                              className={classes.input}
                              value={productFormContext.name}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel shrink htmlFor="button-desc-img">
                              Image
                            </InputLabel>

                            <div className={classes.photoContainer}>
                              <ScalingUpload
                                id="button-desc-img"
                                src={productFormContext.img ? productFormContext.img : defaultImg}
                                onLoadEnd={img => {
                                  productFormContext.handleImg(img)
                                }}
                                maxHeight={200}
                                maxWidth={300}
                              />
                              <label htmlFor="button-desc-img" className={classes.btn}>
                                <Button variant="outlined" color="primary" size="small" component="span">
                                  Modifier
                                </Button>
                              </label>
                              <Button
                                size="small"
                                disabled={productFormContext.img === null}
                                variant="outlined"
                                onClick={() => productFormContext.handleImg(null)}
                                color="secondary"
                              >
                                Supprimer
                              </Button>
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl margin="normal" required fullWidth>
                            <InputLabel shrink htmlFor="service">
                              Catégorie
                            </InputLabel>
                            <Select
                              onChange={productFormContext.handleChange}
                              name="category"
                              placeholder="Choisissez une catégorie"
                              options={[...productFormContext.categories, { label: 'Ajouter une nouvelle catégorie', value: 'new' }]}
                              value={productFormContext.category}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormControl margin="normal" fullWidth required>
                            <InputLabel shrink htmlFor="price_ttc">
                              Prix TTC
                            </InputLabel>
                            <Input
                              id="price_ttc"
                              type="number"
                              name="price_ttc"
                              className={classes.input}
                              value={productFormContext.price_ttc}
                              onChange={productFormContext.handleChange}
                              endAdornment={<InputAdornment position="end">€</InputAdornment>}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl margin="normal" required fullWidth>
                            <InputLabel shrink htmlFor="service">
                              Taux TVA
                            </InputLabel>
                            <Select onChange={productFormContext.handleChange} name="tva" options={config.tva} value={productFormContext.tva} />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl margin="none" required fullWidth>
                          <TextField
                            id="desc"
                            name="desc"
                            className={classes.textArea}
                            label="Description"
                            multiline
                            fullWidth={true}
                            value={productFormContext.desc}
                            onChange={productFormContext.handleChange}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div>
                        <FormControlLabel
                          label="Actif"
                          control={
                            <Switch color="primary" checked={productFormContext.status} value="status" onChange={productFormContext.handleSwitch('status')} />
                          }
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          label="Visible"
                          control={
                            <Switch
                              color="primary"
                              checked={productFormContext.visible}
                              value="visible"
                              onChange={productFormContext.handleSwitch('visible')}
                            />
                          }
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          label="TR Acceptés ?"
                          control={
                            <Switch
                              color="primary"
                              checked={productFormContext.tr_enable}
                              value="tr_enable"
                              onChange={productFormContext.handleSwitch('tr_enable')}
                            />
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
                <div className={classes.new}>
                  <Button size="large" variant="outlined" onClick={() => props.history.goBack()} color="primary">
                    Annuler
                  </Button>
                  <Button size="large" variant="contained" color="primary" onClick={() => submit(productFormContext)}>
                    {productFormContext.isLoading ? <CircularProgress size="24px" color="primary" /> : 'Valider'}
                  </Button>
                </div>
                <Dialog
                  fullWidth
                  open={typeof productFormContext.category !== 'undefined' && productFormContext.category && productFormContext.category === 'new'}
                  aria-labelledby="form-dialog-title"
                >
                  <div className={classes.modalInline}>
                    <DialogTitle id="form-dialog-title">Ajouter une catégorie</DialogTitle>
                  </div>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      productFormContext.addCategory()
                    }}
                  >
                    <DialogContent>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel shrink htmlFor="newCategory">
                          Nom de la nouvelle catégorie
                        </InputLabel>
                        <Input
                          id="newCategory"
                          fullWidth
                          autoFocus
                          type="text"
                          name="newCategory"
                          className={classes.input}
                          value={productFormContext.newCategory}
                          onChange={productFormContext.handleChange}
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={productFormContext.cancelCategory} variant="outlined" color="primary">
                        Annuler
                      </Button>
                      <Button
                        onClick={productFormContext.addCategory}
                        disabled={productFormContext.newCategory ? false : true}
                        variant="contained"
                        color="primary"
                      >
                        Ajouter
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </div>
            )
          }}
        </ProductFormContext.Consumer>
      </ProductFormProvider>
    )
  } else {
    return <Redirect to={{ pathname: '/artisan/produits/' }} />
  }
}

export default withRouter(ProductForm)
