import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import * as Sentry from '@sentry/react'

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    maxWidth: '20rem',
    padding: 16
  },
  text: {
    fontSize: '1.5rem',
    marginBottom: 16
  }
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    Sentry.captureException(error)
  }

  render() {
    const { classes } = this.props

    if (this.state.hasError) {
      return (
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <Typography className={classes.text}>Une erreur est survenue.</Typography>
            <div>
              <Button variant="contained" size="large" color="primary" onClick={() => window.location.reload()}>
                Réessayer
              </Button>
            </div>
          </Paper>
        </div>
      )
    }

    return this.props.children
  }
}

export default withStyles(styles)(ErrorBoundary)
