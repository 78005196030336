import Home from './Home'
import Products from './Products'
import ProductsServices from './ProductsServices'
import ProductForm from './ProductForm'
import TimeslotForm from '../../components/form/TimeslotForm'
import ProductsTimeSlot from './ProductsTimeSlot'
import Settings from './Settings'
import Schedule from './Schedule'
import Contact from '../common/Contact'
import Messages from '../common/Messages'
import Faq from '../common/Faq'
import Orders from './Orders'
import Order from '../common/Order'
import Account from '../common/Account'
import Promo from './Promo'
import Delivery from './Delivery'

export const menu = [
  {
    label: 'Accueil',
    path: '/artisan',
    icon: 'Home',
    exact: true,
    component: Home
  },
  {
    label: 'Mes commandes',
    path: '/artisan/commandes',
    icon: 'Orders',
    exact: true,
    component: Orders
  },
  {
    label: 'Commandes',
    path: '/artisan/commandes/:id',
    icon: 'Orders',
    hideInMenu: true,
    exact: true,
    component: Order
  },
  {
    label: 'Jours & Créneaux',
    path: '/artisan/creneaux',
    icon: 'Calendar',
    exact: true,
    component: Delivery,
    props: {
      title: 'Choisissez un service'
    }
  },
  {
    label: 'Produits',
    path: '/artisan/produits',
    icon: 'Products',
    exact: true,
    component: Products,
    props: {
      title: 'Mes services'
    }
  },
  {
    label: 'Lieux / jours',
    path: '/artisan/horaires',
    icon: 'Calendar',
    exact: true,
    hideInMenu: true,
    component: Schedule
  },
  {
    label: 'Créneaux',
    path: '/artisan/creneaux/nouveau',
    icon: 'Products',
    exact: true,
    hideInMenu: true,
    component: TimeslotForm
  },
  {
    label: 'Créneaux',
    path: '/artisan/creneaux/:sid/:id',
    icon: 'Products',
    exact: true,
    hideInMenu: true,
    component: TimeslotForm
  },
  {
    label: 'Produits',
    path: '/artisan/creneaux/:sid',
    icon: 'Calendar',
    exact: true,
    hideInMenu: true,
    component: ProductsTimeSlot
  },
  {
    label: 'Produits',
    path: '/artisan/produits/nouveau',
    icon: 'Products',
    exact: true,
    hideInMenu: true,
    component: ProductForm
  },
  {
    label: 'Produits',
    path: '/artisan/produits/:sid',
    icon: 'Products',
    exact: true,
    hideInMenu: true,
    component: ProductsServices
  },
  {
    label: 'Produits',
    path: '/artisan/produits/:sid/:id',
    icon: 'Products',
    exact: true,
    hideInMenu: true,
    component: ProductForm
  },
  {
    label: 'Messagerie',
    path: '/artisan/messages/',
    icon: 'Messages',
    exact: true,
    component: Messages,
    isLast: true
  },
  {
    label: 'Messagerie',
    path: '/artisan/messages/:id',
    icon: 'Messages',
    exact: true,
    component: Messages,
    hideInMenu: true
  },

  {
    label: 'Paramètres',
    path: '/artisan/parametres',
    icon: 'Settings',
    exact: true,
    component: Settings
  },
  {
    label: 'Promotions',
    path: '/artisan/promotions',
    icon: 'Cupons',
    component: Promo,
    exact: true,
    isLast: true
  },
  {
    label: 'Foire aux questions',
    path: '/artisan/faq',
    icon: 'Faq',
    component: Faq,
    exact: true
  },
  {
    label: 'Contact',
    path: '/artisan/contact',
    icon: 'Contact',
    exact: true,
    component: Contact
  },
  {
    label: 'Mon Compte',
    path: '/artisan/compte',
    icon: null,
    hideInMenu: true,
    exact: true,
    component: Account
  }
]
