import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, withStyles, createStyles, Paper, Typography, CircularProgress } from '@material-ui/core'
import FullCenteredLayout from '../../components/layout/FullCenteredLayout'
import { withUser } from '../../stores/UserProvider'
import Api from '../../services/Api'
const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: '23px 20px 32px',
    maxWidth: '660px'
  },
  paperBtn: {
    textAlign: 'center',
    marginTop: 23
  },
  loading: {
    textAlign: 'center'
  },
  loader: {
    marginTop: 10
  }
})

class Validation extends Component {
  state = {
    isLoading: true,
    lastname: '',
    firstname: ''
  }

  componentDidMount() {
    document.title = "box'n services – Validation de mon compte"
    // ReactGA.pageview(window.location.pathname + window.location.search)
    const token = this.props.match.params.token
    Api.activate(token).then(res => {
      if (!res.data.data.error) {
        this.setState({
          isLoading: false,
          lastname: res.data.data.user.lastname,
          firstname: res.data.data.user.firstname
        })
      }
    })
  }

  render() {
    const { classes } = this.props
    const { isLoading, firstname } = this.state

    return (
      <FullCenteredLayout title="Validation de mon compte">
        <Paper className={classes.paper}>
          <div>
            {!isLoading ? (
              <>
                <Typography align="center">Félicitations {firstname}, votre inscription a bien été prise en compte.</Typography>
                <Typography align="center">Bienvenue sur box'n services, votre conciergerie connectée !</Typography>
                <div className={classes.paperBtn}>
                  <Button
                    style={{ marginBottom: '12px' }}
                    type="submit"
                    align="center"
                    size="large"
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to="/"
                  >
                    Réaliser ma première commande
                  </Button>
                </div>
              </>
            ) : (
              <div className={classes.loading}>
                <CircularProgress size="24px" color="primary" className={classes.loader} />
                <Typography align="center">Veuillez patienter</Typography>
              </div>
            )}
          </div>
        </Paper>
      </FullCenteredLayout>
    )
  }
}

export default withUser(withStyles(styles)(Validation))
