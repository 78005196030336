import React, { Component } from 'react'

export class DynamicImport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      module: null
    }
  }

  // after the initial render, wait for module to load
  async componentDidMount() {
    this.mounted = true

    const { resolve } = this.props

    try {
      const { default: module } = await resolve()
      if (this.mounted) {
        this.setState({ module })
      }
    } catch (error) {}
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { module } = this.state
    const { resolve, ...rest } = this.props
    const Module = module

    if (module) return <Module {...rest} />
    return null
  }
}
