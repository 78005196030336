import React, { useState } from 'react'
import { FormControl, Input, IconButton, InputLabel, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const PasswordInput = props => {
  const [show, setShow] = useState(false)
  const { label, type, name, onChange, placeholder, value, ...rest } = props
  return (
    <FormControl {...rest}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Input
        id={name}
        type={show ? type : 'password'}
        value={value}
        onChange={onChange}
        name={name}
        fullWidth
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle visibility"
              onClick={() => {
                setShow(!show)
              }}
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

export default PasswordInput
