import React, { createContext, Component } from 'react'
import { withToastManager } from 'react-toast-notifications'
import Api from '../services/Api'
import config from '../config'
import { withUser } from './UserProvider'
import { TOAST } from '../hooks/HookToasts'

export const ProductFormContext = createContext({})

class ProductFormProvider extends Component {
  constructor(props) {
    super(props)
    const initialState = {
      name: props.product && props.product.name,
      service: props.service,
      desc: props.product && props.product.desc,
      price_ttc: props.product && props.product.price_ttc,
      category: props.product ? props.product.category.id : '',
      img: props.product && props.product.img ? props.product.img : null,
      tr_enable: props.product && props.product.tr_enable === 1 ? true : false,
      tva: props.product ? config.tva.find(p => p.value === props.product.tva).value : config.tva[0].value,
      visible: props.product && props.product.visible === 1 ? true : false,
      status: props.product && props.product.status === 1 ? true : false
    }

    this.state = {
      ...initialState,
      isLoading: false,
      isAddingCat: false,
      categories: [],
      newCategory: '',
      imgChanged: false,
      getCategories: async () => {
        try {
          const res = await Api.getCategories(props.user, props.service)

          if (!res.data.data.error) {
            this.setState({
              categories: res.data.data.category.map(cat => ({ value: cat.id, label: cat.name }))
            })
          }
        } catch (error) {
          console.error(error)
        }
      },
      cancelCategory: () => {
        let defaultCategory
        if (props.product) {
          defaultCategory = { value: props.product.category.id, label: props.product.category.name }
        } else {
          defaultCategory = this.state.categories[0]
        }
        this.setState({
          category: defaultCategory
        })
      },
      addCategory: async () => {
        this.setState({
          isAdding: true
        })

        try {
          const { toastManager } = this.props
          const res = await Api.addCategory(props.user, props.service, this.state.newCategory)

          if (!res.data.data.error) {
            await this.state.getCategories()

            toastManager.add('La catégorie a bien été ajoutée', { appearance: TOAST.SUCCESS })

            this.setState({
              isAdding: false,
              newCategory: '',
              category: res.data.data.id
            })
          }
        } catch (error) {
          console.error(error)
        }
      },
      submit: async () => {
        const { name, desc, price_ttc, tva, category, img, imgChanged, tr_enable, visible, status } = this.state
        const data = {
          name,
          desc,
          img,
          img_changed: imgChanged,
          category: category,
          ttc: parseFloat(price_ttc),
          tva: tva,
          tr: tr_enable ? 1 : 0,
          visible: visible ? 1 : 0,
          status: status ? 1 : 0,
          artisan: this.props.user,
          service: this.props.service
        }

        this.setState({ isLoading: true })
        const res = this.props.product ? await Api.updateProduct(this.props.product.id, data) : await Api.addProduct(data)
        this.setState({ isLoading: false })
        return res
      },
      handleChange: (e, i) => {
        this.setState({ [e.target.name]: e.target.value })
      },

      handleSelect: name => value => {
        this.setState({
          [name]: value
        })
      },

      handleSwitch: name => event => {
        this.setState({
          [event.target.value]: event.target.checked
        })
      },

      handleHT: (e, i) => {
        this.setState({ price_ht: e.currentTarget.value, price_ttc: Math.ceil(e.currentTarget.value * (1 + this.state.tva.rate / 100) * 100) / 100 })
      },

      handleImg: value => {
        this.setState(state => ({
          img: value,
          imgChanged: true
        }))
      },

      deleteProduct: id => {}
    }
  }

  componentDidMount = async () => {
    this.state.getCategories()
  }

  render = () => <ProductFormContext.Provider value={{ productFormContext: this.state }}>{this.props.children}</ProductFormContext.Provider>
}

export const withProductForm = Component => props => <ProductFormContext.Consumer>{store => <Component {...props} {...store} />}</ProductFormContext.Consumer>

export default withToastManager(withUser(ProductFormProvider))
