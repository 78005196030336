import Contact from '../common/Contact'
import Account from '../common/Account'
import Faq from '../common/Faq'
import Orders from './Orders'
import Order from '../common/Order'

export const menu = [
  {
    label: 'Accueil',
    path: '/livreur',
    icon: 'Home',
    exact: true,
    component: Orders
  },
  {
    label: 'Mes Commandes',
    path: '/livreur/commandes',
    icon: 'Orders',
    exact: true,
    isLast: true,
    component: Orders
  },
  {
    label: 'Commandes',
    path: '/livreur/commandes/:id',
    icon: 'Orders',
    hideInMenu: true,
    exact: true,
    component: Order
  },
  {
    label: 'Foire aux questions',
    path: '/livreur/faq',
    icon: 'Faq',
    component: Faq,
    exact: true
  },
  {
    label: 'Contact',
    path: '/livreur/contact',
    icon: 'Contact',
    exact: true,
    component: Contact
  },
  {
    label: 'Mon Compte',
    path: '/livreur/compte',
    icon: null,
    hideInMenu: true,
    exact: true,
    component: Account
  },
]
