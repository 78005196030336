import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper, Radio, FormControlLabel } from '@material-ui/core'
import LinkButton from '../buttons/LinkButton'
import MessageList from './MessageList'
import palette from '../../theme/palette'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import config from '../../config'
import MessageFullForm from '../form/MessageFullForm'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  header: {
    borderBottom: `1px solid ${palette.grey}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.typography.pxToRem(15)
  },
  messages: {
    paddingTop: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15)
  },
  other: {
    marginBottom: 0
  },
  unread: {
    textAlign: 'right'
  }
}))

const MessageFull = props => {
  const classes = useStyles()
  const { order, unRead } = props
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [messages, setMessages] = useState(null)
  const [unread, setUnread] = useState('false')
  const { userContext } = useContext(UserContext)
  const role = userContext.getRole()
  const other = role === config.roles.artisan ? config.roles.utilisateur : config.roles.artisan

  // Charge les messages via l'api à chaque changement d'order
  useEffect(() => {
    const load = async () => {
      await loadMessages()
    }
    load()
  }, [order])

  // Listen to WS
  useEffect(() => {
    userContext.socket.on('message', addMessage)
    return function() {
      userContext.socket.off('message')
    }
  }, [messages])

  useEffect(() => {
    if (unread === 'true') {
      Api.setMessagesUnread(order.id, userContext.getId())
      unRead(order)
    }
  }, [unread])

  const loadMessages = async () => {
    try {
      setIsLoading(true)
      const res = await Api.getMessages(order.id, userContext.getId(), userContext.getRole())

      if (res.data.success) {
        setMessages(res.data.data.message.reverse())
        setIsLoading(false)
        setUnread('false')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const addMessage = message => {
    if (messages !== null) {
      if (message.order === order.id) {
        const newMessages = [...messages]
        newMessages.push(message)
        setMessages(newMessages)
      }
    }
  }

  function sendMessage(message) {
    setIsSending(true)

    const data = {
      text: message,
      order_id: order.id,
      [`${other}_id`]: order[other].id,
      [`${role}_id`]: order[role].id,
      created_by: order[role].id
    }

    Api.sendMessage(data).then(res => {
      setIsSending(false)
    })
  }

  return (
    <Paper className={classes.sectionPaper}>
      {isLoading ? (
        <Typography variant="body2">Chargement...</Typography>
      ) : (
        <>
          <div className={classes.header}>
            <Typography variant="h3" className={classes.other}>
              {order[other].name}
            </Typography>
            <LinkButton to={`/${role}/commandes/${order.id}`}>Voir la commande</LinkButton>
          </div>
          <div className={classes.messages}>
            {messages && messages.length > 0 ? <MessageList messages={messages} /> : <Typography variant="body2">Aucun message.</Typography>}
          </div>

          {userContext.getRole() === config.roles.artisan && (
            <div className={classes.unread}>
              <FormControlLabel
                control={<Radio checked={unread === 'true'} onChange={() => setUnread('true')} value={unread} />}
                label={'Marquer comme non lu'}
              />
            </div>
          )}

          <MessageFullForm onSubmit={sendMessage} isSending={isSending} />
        </>
      )}
    </Paper>
  )
}

export default MessageFull
