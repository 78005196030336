import axios from 'axios'
import config from '../config'
import { captureException } from '@sentry/react'

const axiosClient = axios.create({
  baseURL: config.apiURL,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
})

axiosClient.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    }

    return config
  },
  function(error) {
    // faire quelque chose en cas d’erreur
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  response => {
    if (response.data && response.data.data && response.data.data.error) {
      captureException(response.data.data.error)
    }

    return response
  },
  function(error) {
    captureException(error)
    return Promise.reject(error)
  }
)

class Api {
  constructor() {
    this.api = axiosClient
  }

  //Generic
  get = url => this.api.get(url, null, { baseUrl: null })

  // User
  login = (email, password) => this.api.post('user/login', { email, password })
  forgotPassword = email => this.api.post('user/forgot', { email })
  register = data => this.api.post('user', data)
  activate = data => this.api.get(`user/activate/${data}`)
  resetPassword = data => this.api.post('user/reset', data)
  updatePassword = data => this.api.post('user/change', data)
  updateNewsletters = data => this.api.post('user/newsletter', data)
  getProfile = userId => this.api.get(`user/${userId}`)
  updateProfile = (userId, userProfile) => this.api.post(`user/update/${userId}`, userProfile)
  deleteAccount = data => this.api.post('user/delete', data)
  getUserPlaces = user_id => this.api.get(`user/places/${user_id}`)
  getUserBySponsor = data => this.api.post(`user/sponsor`, data)
  checkEmailDomain = data => this.api.post(`user/email-domain`, data)
  getDashboard = artisan_id => this.api.post(`artisan/dashboard-desk`, { artisan_id })
  getPromoDialog = user_id => this.api.get(`user/popin/${user_id}`)

  getEnterpriseUsers = enterpriseId => this.api.get(`user/enterprise/${enterpriseId}`)
  revokeUser = user_id => this.api.post(`user/revoke/${user_id}`)

  // Notifs
  getNotifications = user_id => this.api.get(`notif/user/${user_id}`)
  setNotificationAsViewed = notif_id => this.api.post(`notif/read/${notif_id}`)
  setAllNotificationsAsViewed = user_id => this.api.post(`notif/read-all/${user_id}`)

  // Misc
  getCities = (u, data) => this.api.get('city', { params: {...data, u} })
  getPlaces = (id, isPublic = 0, u) => this.api.get(`place/city/${id}`, { params: {public: isPublic ? 1 : 0, u} })
  getPlace = id => this.api.get(`place/${id}`)
  getArtisanPlaces = user_id => this.api.get(`place/artisan/${user_id}`)
  getDepartments = () => this.api.get('departments')
  getProDepartments = user_id => this.api.get(`departments/pro/${user_id}`)

  // Services
  getServicesByPlace = id => this.api.get(`service/place/${id}`)
  getServicesByArtisan = id => this.api.get(`service/artisan/${id}`)
  getServicePlaces = id => this.api.get(`service/listplaces/${id}`)

  // Process
  getProcess = id => this.api.get(`process/${id}`)

  // Commandes
  getOrders = (role, user_id, data) => this.api.get(`order/${role}/${user_id}`, data)
  getOrder = (id, data) => this.api.get(`order/${id}`, { params: data })
  getOrderStatus = role => this.api.get(`order/status/${role}`)
  setPreorder = data => this.api.post('order', data)
  updateOrder = (id, data) => this.api.post(`order/update/${id}`, data)
  cancelOrder = data => this.api.post('order/cancel', data)
  validatePayment = (id, data) => this.api.post(`order/validatepayment/${id}`, data)
  validateProPayment = (id, data) => this.api.post(`order/validatepropayment/${id}`, data)
  addPromoCodeToOrder = (id, data) => this.api.post(`order/promo-code/${id}`, data)

  // Devis
  sendQuote = (order, sender, accepted) => this.api.post(`order/quote/${order.id}`, { sender, accepted })

  // OrderItem
  addOrderItem = data => this.api.post(`order-item`, data)
  updateOrderItem = (id, data) => this.api.post(`order-item/update/${id}`, data)
  deleteOrderItem = id => this.api.post(`order-item/delete/${id}`)

  // Messages
  // getMessages = (user_id, role) => this.api.post('order-message/list', { user_id: user_id, role: role })
  getMessages = (order_id, user_id, role) => this.api.post('order-message/list', { order_id, user_id, role })
  getLastMessages = order_id => this.api.get('order-message/last', { params: { order_id } })
  sendMessage = data => this.api.post('order-message', data)
  setMessagesRead = (order_id, user_id) => this.api.post('order-message/read', { order_id, user_id })
  setMessagesUnread = (order_id, user_id) => this.api.post('order-message/unread', { order_id, user_id })

  // Produits des artisan
  addCategory = (artisan_id, service_id, name) => this.api.post('category', { artisan_id, service_id, name })
  getCategories = (artisan_id, service_id) => this.api.post(`category/list`, { artisan_id, service_id })
  getProducts = (artisan_id, service_id, visible) => this.api.post(`category/products`, { artisan_id, service_id, visible })
  // getProductsByService = (id, sid) => this.api.get(`product/artisan/${id}/service/${sid}`)
  // getProductsByServiceClient = (id, sid) => this.api.get(`product/artisan/${id}/service-client/${sid}`)
  addProduct = data => this.api.post('product', data)
  updateProduct = (id, data) => this.api.post(`product/update/${id}`, data)
  deleteProduct = id => this.api.post(`product/delete/${id}`)

  // Jours / lieux artisan
  setSchedule = (id, place_id, hours) => this.api.post(`module-planning/artisan/${id}`, { place_id, hours })
  getSchedule = (id, place_id) => this.api.post(`module-planning/all/${id}`, { place_id })
  getPlaceSchedules = place_id => this.api.get(`module-planning/place/${place_id}`)
  setScheduleExceptions = (id, place_id, days) => this.api.post(`module-planning/exceptions/${id}`, { place_id, days })
  getAvailabilities = data => this.api.post('module-planning/availabilities', data)

  // Modules
  getModules = (process_id, user_id) => this.api.post('module-artisan', { process_id, user_id })
  getModuleData = (process_module_id, user_id) => this.api.post('module-artisan/find', { process_module_id, user_id })
  addModuleData = data => this.api.post('module-artisan/add', data)
  setModuleData = (id, data) => this.api.post(`module-artisan/set/${id}`, data)
  getModuleForService = service_id => this.api.get(`service/${service_id}/module`)

  // Promo & parrainages
  getUserPromo = user_id => this.api.get(`promo-code/user/${user_id}`)
  getSponsors = user_id => this.api.get(`promo-code/sponsor/${user_id}`)
  checkPromo = data => this.api.post('promo-code/check', data)
  getEnterprisePromocodes = user_id => this.api.get(`promo-code/enterprise/${user_id}`)
  getArtisanPromocodes = user_id => this.api.get(`promo-code/artisan/${user_id}`)
  addPromoCode = (user_id, data) => this.api.post(`promo-code/add/${user_id}`, data)
  removePromoCode = user_id => this.api.post(`promo-code/remove/${user_id}`)

  // Faq
  getFaq = role => this.api.post('faq', { role })

  // Contact
  getContact = () => this.api.get('affiliate/contact')
  sendContact = data => this.api.post('user/contact', data)

  // PAYZEN
  getPayzenFormToken = data => this.api.post('payzen/createformtoken', data)
  getPayzenScript = url => this.api.get(`payzen/proxyjs?url=${url}`)
  getTrButton = id => this.api.get(`payzen/trbutton/${id}`)

  // Note
  getNotes = (order_id, user_id) => this.api.get(`order-note/order/${order_id}`, { params: { user_id } })
  setNote = (order_id, user_id, text) => this.api.post(`order-note/add`, { order_id, user_id, text })

  // Entreprises
  getEnterpriseInvoices = user_id => this.api.get(`invoice/enterprise/${user_id}`)
  getPdf = (order, client) => this.api.post('invoice/pdf', { order, client, device: 'front' }, { responseType: 'blob' })
  getEnterprisePlaces = user_id => this.api.get(`place/enterprise/${user_id}`)

  //Page
  getPage = place_id => this.api.get(`page/${place_id}`)

  //Timeslots
  getTimeslots = service_id => this.api.get(`timeslot`, { params: { service_id } })
  deleteTimeslot = id => this.api.post(`timeslot/${id}/delete`)
  getTimeslotsForPlace = (place_id, data) => this.api.get(`timeslot/place/${place_id}`, data)
  addTimeslot = (service_id, data) => this.api.post(`timeslot/create/${service_id}`, data)
  updateTimeslot = (timeslot_id, data) => this.api.post(`timeslot/update/${timeslot_id}`, data)

  //Deadline
  getDeadline = service_id => this.api.get(`deadline`, { params: { service_id } })
  addDeadline = (service_id, data) => this.api.post(`deadline/create/${service_id}`, data)
  updateDeadline = (deadline_id, data) => this.api.post(`deadline/update/${deadline_id}`, data)
}

export default new Api()
