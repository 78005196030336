import React, { useEffect, useContext, useState } from 'react'
import { UserContext } from '../../stores/UserProvider'
import { Typography, Paper, TableCell, TableRow, TableHead, Table, TableBody, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Api from '../../services/Api'
import defaultImg from '../../assets/default.png'
import { useToasts, TOAST } from '../../hooks/HookToasts'
import { FileCopyOutlined, EmailOutlined } from '@material-ui/icons'
import config from '../../config'
import { copyValue } from '../../services/helpers'
import LinkButton from '../../components/buttons/LinkButton'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-start'
  },
  avatar: {
    ...theme.common.avatar,
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  code: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      marginLeft: theme.typography.pxToRem(5)
    }
  },
  desc: {
    margin: `${theme.typography.pxToRem(10)} 0`
  },
  toggle: {
    marginLeft: theme.typography.pxToRem(8)
  },
  btns: {
    margin: `${theme.typography.pxToRem(10)} 0 ${theme.typography.pxToRem(15)}`
  },
  btn: {
    marginRight: `${theme.typography.pxToRem(5)}`
  }
}))

const Sponsor = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [sponsors, setSponsors] = useState([])
  const [detail, setDetail] = useState(false)
  const { add } = useToasts()
  const code = userContext.user.sponsor_code
  const shareLink = `${config.publicURL}inscription?c=${code}`

  useEffect(() => {
    Api.getSponsors(userContext.getId()).then(res => {
      if (!res.data.data.error) {
        setSponsors(res.data.data.promocode)
      }
    })
  }, [])

  function copyLink(value) {
    copyValue(value)
    add('Code copié !', TOAST.SUCCESS)
  }

  function getEmail() {
    let header = ' ?subject=Deviens mon filleul sur box’n services&body='
    let email = "Bonjour,\n\nConnais-tu box'n services ?\n"
    email +=
      "Il s'agit d'une conciergerie presente dans notre entreprise grâce à des casiers connectés. Elle te donne accès à des services d'artisans directement depuis notre lieu de travail !\n\n"
    email += "Tu n'es pas encore inscrit ?\n"
    email += "Deviens mon filleul en t'inscrivant via ce lien\n"
    email += shareLink + '\n'
    email += 'ou en inscrivant mon code de parrainage lors de ton inscription.\n'
    email += `Code de parrainage = ${code}\n\n`
    email += 'Pourquoi devenir mon filleul ?\n'
    email += "Tu bénéficieras d'une réduction de 10% sur ta première commande ! "
    email += "Chaque personne qui s'inscrit avec mon code de parrainage me permets de profiter également de 10% de réduction sur une commande.\n\n"
    email += "Je suis à ta disposition pour te présenter plus en détails box'n services."
    return header + encodeURI(email)
  }

  return (
    <>
      <div className={classes.section}>
        <Typography variant="h1">Parrainez vos collègues !</Typography>

        <Paper className={classes.sectionPaper}>
          <Typography className={classes.code}>
            Votre code :{' '}
            <Button variant="outlined" color="primary" onClick={() => copyLink(code)}>
              {code}
            </Button>
          </Typography>

          <Typography className={classes.desc}>
            Partagez ce code spécial avec vos amis. Vous leur offrez 10% de réduction sur leur première commande box’n services et vous bénéficiez de 10% de
            réduction sur la vôtre.
            <span className={classes.toggle}>
              <LinkButton onClick={() => setDetail(!detail)}>{detail ? '-' : '+'} détails</LinkButton>
            </span>
          </Typography>
          {detail && (
            <Typography className={classes.desc}>
              A chaque fois qu’une personne s’inscrit sur box’n services avec votre code de parrainage, elle bénéficie d’une réduction de 10% sur sa première
              commande. Une fois sa commande terminée, vous bénéficiez automatiquement d’une réduction de 10% valable sur une prochaine commande.
            </Typography>
          )}

          <div className={classes.btns}>
            <Button variant="contained" color="primary" className={classes.btn} onClick={() => copyLink(code)}>
              <FileCopyOutlined className={classes.leftIcon} />
              Copier le code
            </Button>
            <a
              href={`mailto:${getEmail()}`}
              target="_blank"
              rel="noreferrer noopener"
              onClick={e => {
                window.open(`mailto:${getEmail()}`)
                e.preventDefault()
              }}
            >
              <Button variant="contained" color="primary" className={classes.btn}>
                <EmailOutlined className={classes.leftIcon} />
                Envoyer par email
              </Button>
            </a>
          </div>
        </Paper>
      </div>

      <Typography variant="h1">Parrainages</Typography>
      <Paper className={classes.sectionPaper}>
        {sponsors.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vos filleuls</TableCell>
                <TableCell>Compte activé</TableCell>
                <TableCell>Commande passée</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sponsors &&
                sponsors.map(sponsor => (
                  <TableRow key={`${sponsor.id}`}>
                    <TableCell>
                      <div className={classes.inline}>
                        <img alt={sponsor.user.name} className={classes.avatar} src={sponsor.user.img ? sponsor.user.img : defaultImg} />
                        {sponsor.user.name}
                      </div>
                    </TableCell>
                    <TableCell>{sponsor.user.status === 1 ? 'Oui' : 'Non'}</TableCell>
                    <TableCell>
                      {sponsor.use === 1 && sponsor.isSponsorPromoCreated ? 'Oui, bénéficiez de -10% dans votre espace Promotions' : 'Pas encore !'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>Aucun parrainé pour le moment.</Typography>
        )}
      </Paper>
    </>
  )
}

export default Sponsor
