import React from 'react'
import classNames from 'classnames'
import { generatePath, withRouter } from 'react-router-dom'
import { Drawer, IconButton, List } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'
import { Menu } from '@material-ui/icons'
import MenuLink from './MenuLink'
import palette from '../../theme/palette'
import Logo from '../../assets/Logo'
import { ReactComponent as LeftArrow } from '../../assets/icons/leftarrow.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  drawerBase: {
    width: 280,
    flexShrink: 0
  },
  drawerPaperBase: {
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(-100%)'
    }
  },
  drawerOpen: {
    width: 280,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 80,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  sidebar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  sidebarHeader: {
    height: 75,
    minHeight: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eff3f8',
    padding: '0 20px'
  },
  sidebarHeaderMini: {
    padding: 0,
    justifyContent: 'center'
  },
  drawerMenu: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  burger: {
    marginLeft: 5,
    '&:hover': {
      backgroundColor: palette.clearRed,
      color: palette.red
    },
    '&:focus': {
      color: palette.red,
      backgroundColor: palette.clearRed
    }
  },
  burgerMini: {
    marginLeft: 0
  },
  social: {
    marginTop: 'auto'
  },
  socialTitle: {
    color: palette.dark,
    fontSize: 16,
    fontWeight: '300',
    letterSpacing: 0.5,
    textAlign: 'center'
  },
  closeArrow: {
    marginRight: 15,
    marginLeft: 'auto',
    marginTop: 10,
    justifyContent: 'flex-end'
  }
}))

const BNSDrawer = props => {
  const { isOpen, toggleSidebar, menu, location } = props
  const classes = useStyles()
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  function isActive(link) {
    const l = link.path.split('/')
    const currentL = location.pathname
    if (l.length <= 2) {
      return link.path === currentL
    } else {
      return currentL.startsWith(l[0] + '/' + l[1] + '/' + l[2])
    }
  }

  function generateLink(link) {
    return link.params ? generatePath(link.path, link.params) : link.path
  }

  // Récuperer le user context pour passer le placeId en props de menuLink
  // Plus besoin de passer le menu en fonction car on aura bien un array

  const renderLinks = () => {
    const links = menu.filter(menu => !menu.hideInMenu)
    return (
      <List className={classes.drawerMenu}>
        {links.map((link, index) => (
          <MenuLink
            key={`${index}`}
            isOpen={isOpen}
            to={generateLink(link)}
            isLast={link.isLast}
            active={isActive(link)}
            icon={link.icon}
            label={link.label} />
        ))}
      </List>
    )
  }

  if (isBrowser) {
    return (
      <Drawer
        variant="permanent"
        className={classNames(classes.drawerBase, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen
        })}
        classes={{
          paper: classNames(classes.drawerPaperBase, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen
          })
        }}
        open={isOpen}
      >
        <div className={classes.sidebar}>
          <div
            className={classNames(classes.sidebarHeader, {
              [classes.sidebarHeaderMini]: !isOpen
            })}
          >
            {isOpen && (
              <a href="https://boxnservices.fr">
                <Logo width={135} height={29} />
              </a>
            )}
            <IconButton
              className={classNames(classes.burger, {
                [classes.burgerMini]: !isOpen
              })}
              onClick={toggleSidebar}
            >
              <Menu style={{ fontSize: 20 }} color="primary" />
            </IconButton>
          </div>
          {renderLinks()}
        </div>
      </Drawer>
    )
  } else {
    return (
      <Drawer
        variant="temporary"
        classes={{
          paper: classNames({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen
          })
        }}
        onClick={toggleSidebar}
        onKeyDown={toggleSidebar}
        ModalProps={{ keepMounted: true }}
        open={isOpen}
      >
        <IconButton className={classes.closeArrow}>
          <LeftArrow style={{ fontSize: 20 }} color="primary" />
        </IconButton>
        {renderLinks()}
      </Drawer>
    )
  }
}

export default withRouter(BNSDrawer)
