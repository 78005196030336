import React from 'react'
import palette from '../../../theme/palette'

const Contact = ({ color = palette.primary }) => (
  <svg width="19" height="14" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.913 10.655C14.8371 10.655 14.7642 10.6252 14.71 10.572L11.294 7.28603C11.2365 7.2301 11.2029 7.15406 11.2003 7.07387C11.1977 6.99368 11.2262 6.91559 11.28 6.85604C11.3062 6.82674 11.3381 6.80305 11.3737 6.78646C11.4093 6.76986 11.4479 6.76069 11.4872 6.75948C11.5265 6.75826 11.5656 6.76501 11.6022 6.77938C11.6388 6.79374 11.672 6.81542 11.7 6.84303L15.117 10.129C15.1744 10.1851 15.2078 10.2612 15.2102 10.3414C15.2127 10.4216 15.1839 10.4996 15.13 10.559C15.1025 10.5893 15.069 10.6134 15.0317 10.63C14.9943 10.6465 14.9539 10.6551 14.913 10.655Z"
      fill={color}
    />
    <path
      d="M3.507 10.654C3.46614 10.6539 3.42575 10.6454 3.38839 10.6288C3.35102 10.6123 3.31752 10.5882 3.29 10.558C3.23624 10.4984 3.20765 10.4203 3.21026 10.3401C3.21287 10.2599 3.24648 10.1839 3.304 10.128L6.721 6.84196C6.74916 6.81466 6.78253 6.79333 6.81912 6.77921C6.85571 6.7651 6.89477 6.7585 6.93397 6.75981C6.97317 6.76111 7.01171 6.77032 7.04728 6.78684C7.08285 6.80337 7.11472 6.82688 7.141 6.856C7.19489 6.91542 7.22365 6.9934 7.22123 7.07359C7.21881 7.15378 7.18538 7.22993 7.128 7.28599L3.71 10.571C3.65581 10.6241 3.58292 10.6539 3.507 10.654V10.654Z"
      fill={color}
    />
    <path
      d="M1.484 0.898987C0.994001 0.898987 0.594002 1.30901 0.594002 1.81201V12.188C0.594002 12.692 0.994001 13.101 1.484 13.101H16.93C17.421 13.101 17.82 12.691 17.82 12.188V1.81201C17.82 1.30801 17.421 0.898987 16.93 0.898987H1.484ZM16.929 13.709H1.484C0.666001 13.709 0 13.028 0 12.189V1.81201C0 0.972012 0.666001 0.289978 1.484 0.289978H16.93C17.748 0.289978 18.414 0.972012 18.414 1.81201V12.188C18.414 13.028 17.748 13.71 16.93 13.71L16.929 13.709Z"
      fill={color}
    />
    <path
      d="M9.206 8.53403C8.811 8.53403 8.416 8.40001 8.116 8.13101L0.492001 1.31302C0.432196 1.25924 0.395632 1.18433 0.390034 1.10409C0.384437 1.02386 0.410244 0.944589 0.462003 0.883025C0.487392 0.853114 0.518509 0.828579 0.553525 0.810882C0.588541 0.793185 0.626746 0.782701 0.665887 0.779998C0.705027 0.777296 0.744314 0.782418 0.78143 0.795135C0.818546 0.807851 0.852741 0.827896 0.882001 0.854034L8.506 7.67203C8.886 8.01103 9.526 8.01103 9.906 7.67203L17.519 0.86502C17.5482 0.838682 17.5824 0.818546 17.6196 0.805755C17.6568 0.792964 17.6962 0.787817 17.7354 0.790618C17.7746 0.79342 17.8129 0.804105 17.8479 0.822051C17.8828 0.839998 17.9138 0.864828 17.939 0.895049C17.9909 0.956488 18.0169 1.03564 18.0114 1.11587C18.006 1.19611 17.9697 1.27113 17.91 1.32504L10.296 8.13101C9.99433 8.39437 9.60644 8.5378 9.206 8.53403V8.53403Z"
      fill={color}
    />
  </svg>
)

export default Contact
