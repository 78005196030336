import React, { useContext, useState, useEffect } from 'react'
import { Typography, Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MessageTeaser from '../../components/entities/MessageTeaser'
import MessageFull from '../../components/entities/MessageFull'
import LinkButtonOption from '../../components/buttons/LinkButtonOption'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import Pagination from '../../components/layout/Pagination'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  messageGrid: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  }
}))

const Messages = props => {
  const params = new URLSearchParams(props.location.search)
  const classes = useStyles()
  const { match, history } = props
  const [orders, setOrders] = useState([])
  const defaultFilters = [{ label: 'Toutes les commandes', value: 0 }, { label: 'Commandes en cours', value: 1 }]
  const filter = defaultFilters.find(filter => filter.value === parseInt(localStorage.getItem('messagefilter'))) || defaultFilters[0]
  const [selectedOrder, setSelectedOrder] = useState(null)
  const { userContext } = useContext(UserContext)
  const [paginationLinks, setPaginationLinks] = useState({})
  const [paginationMeta, setPaginationMeta] = useState({})
  const num = isMobile ? 3 : 5

  useEffect(() => {
    getOrders(localStorage.getItem('messagefilter'))
  }, [])

  useEffect(() => {
    if (selectedOrder) {
      Api.setMessagesRead(selectedOrder.id, userContext.getId())
      const ods = orders.map(order => {
        return {
          ...order,
          messages: order.id === selectedOrder.id ? 0 : order.messages
        }
      })

      setOrders(ods)
    }
  }, [selectedOrder])

  async function getOrders(filter) {
    try {
      const page = params.get('p')
      const res = await Api.getOrders(userContext.getRole(), userContext.getId(), { pending: filter, page, num })
      useResOrders(res.data)
    } catch (error) {
      console.error(error)
    }
  }

  const setOrdersData = data => {
    setOrders(data.data)
    setPaginationLinks({ prev: data.links.prev, next: data.links.next })
    setPaginationMeta({ currentPage: data.meta.current_page, pageCount: data.meta.last_page })
  }

  const useResOrders = data => {
    if (data) {
      setOrdersData(data)
      if (data.data) {
        const selected = match.params.id
        if (selected) {
          const selectedIsInOrdersList = data.data.find(order => order.id === match.params.id)
          //if selected is not in orders list we fetch the order
          if (selectedIsInOrdersList) {
            setSelectedOrder(selectedIsInOrdersList)
          } else {
            Api.getOrder(selected).then(response => {
              if (!response.data.data.error) {
                setSelectedOrder(response.data.data)
              } else {
                selectFirstOrder(data)
              }
            })
          }
        } else {
          selectFirstOrder(data)
        }
      }
    }
  }

  const selectFirstOrder = data => {
    setSelectedOrder(data.data[0])
    history.push(`/${userContext.getRole()}/messages/${data.data[0].id}?p=${data.meta.current_page}`)
  }

  const unRead = function(choosenOrder) {
    const ods = orders.map(order => {
      return {
        ...order,
        messages: order.id === choosenOrder.id ? { ...order.messages, [userContext.getRole()]: 1 } : order.messages
      }
    })

    setOrders(ods)
  }

  return (
    <div className={classes.section}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={5}>
          <div className={classes.title}>
            <Typography variant="h1">Messages</Typography>
            <LinkButtonOption
              onChange={item => {
                localStorage.setItem('messagefilter', item)
                history.push(`/${userContext.getRole()}/messages`)
              }}
              options={defaultFilters}
              selected={filter.value}
            />
          </div>
        </Grid>
      </Grid>

      {selectedOrder && (
        <Grid container spacing={2} alignItems="flex-start" className={classes.messageGrid}>
          <Grid item xs={12} md={5}>
            {orders.map(order => (
              <MessageTeaser
                key={order.id}
                order={order}
                selected={selectedOrder.id === order.id}
                onClick={() => {
                  const params = new URLSearchParams(props.location.search)
                  const page = params.get('p')
                  history.push(`/${userContext.getRole()}/messages/${order.id}?p=${page}`)
                  setSelectedOrder(order)
                }}
              />
            ))}
            <Pagination links={paginationLinks} meta={paginationMeta} onChangePage={res => useResOrders(res)} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Paper>
              <MessageFull order={selectedOrder} unRead={unRead} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default Messages
