import React from 'react'
import palette from '../../../theme/palette'

const Sponsor = ({ color = palette.primary }) => (
  <svg width="24" height="19" viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.811 0.731995C7.107 0.731995 8.161 1.77299 8.161 3.05399C8.161 4.33399 7.107 5.37601 5.811 5.37601C4.516 5.37601 3.461 4.33399 3.461 3.05399C3.461 1.77399 4.516 0.731995 5.811 0.731995ZM5.811 6.09799C7.516 6.09799 8.903 4.73 8.903 3.048C8.903 1.368 7.516 0 5.811 0C4.107 0 2.72 1.36801 2.72 3.04901C2.72 4.72901 4.107 6.09799 5.811 6.09799V6.09799Z"
      fill={color}
    />
    <path
      d="M13.971 0.731995C15.267 0.731995 16.321 1.77299 16.321 3.05399C16.321 4.33399 15.267 5.37601 13.971 5.37601C12.676 5.37601 11.621 4.33399 11.621 3.05399C11.621 1.77399 12.676 0.731995 13.971 0.731995ZM13.971 6.09799C15.676 6.09799 17.063 4.73 17.063 3.048C17.063 1.368 15.676 0 13.971 0C12.267 0 10.88 1.36801 10.88 3.04901C10.88 4.72901 12.267 6.09799 13.971 6.09799V6.09799Z"
      fill={color}
    />
    <path
      d="M17.987 10.187C18.0474 10.2644 18.136 10.3148 18.2334 10.3272C18.3308 10.3395 18.4292 10.3128 18.507 10.253C18.5454 10.2239 18.5776 10.1876 18.6018 10.1459C18.626 10.1043 18.6417 10.0583 18.6479 10.0105C18.6542 9.96279 18.6508 9.91428 18.6381 9.86783C18.6255 9.82139 18.6037 9.77793 18.574 9.73999C18.0282 9.04173 17.3298 8.4777 16.5322 8.09116C15.7347 7.70461 14.8593 7.50583 13.973 7.51001C12.8165 7.50768 11.6854 7.84874 10.723 8.48999C10.6829 8.5165 10.6485 8.55071 10.6217 8.59064C10.5949 8.63057 10.5763 8.67543 10.567 8.7226C10.5577 8.76977 10.5579 8.81831 10.5675 8.86542C10.5771 8.91253 10.596 8.95726 10.623 8.99701C10.6789 9.07787 10.7643 9.13349 10.8609 9.15183C10.9575 9.17017 11.0574 9.14975 11.139 9.095C11.9784 8.53647 12.9647 8.2396 13.973 8.24201C15.555 8.24201 17.018 8.95102 17.987 10.187V10.187Z"
      fill={color}
    />
    <path
      d="M5.812 7.51001C2.607 7.51001 0 10.081 0 13.242C0 13.444 0.165998 13.608 0.370998 13.608C0.419325 13.6083 0.467233 13.599 0.511982 13.5807C0.556731 13.5625 0.597444 13.5356 0.631802 13.5016C0.666159 13.4676 0.693487 13.4272 0.712223 13.3827C0.730959 13.3381 0.740739 13.2903 0.741001 13.242C0.741001 10.485 3.016 8.242 5.811 8.242C8.607 8.242 10.881 10.485 10.881 13.242C10.881 13.444 11.048 13.608 11.253 13.608C11.3013 13.6083 11.3492 13.599 11.394 13.5807C11.4387 13.5625 11.4794 13.5356 11.5138 13.5016C11.5482 13.4676 11.5755 13.4272 11.5942 13.3827C11.613 13.3381 11.6227 13.2903 11.623 13.242C11.623 10.082 9.016 7.51001 5.812 7.51001Z"
      fill={color}
    />
    <path
      d="M19.416 17.271C17.848 17.271 16.572 16.013 16.572 14.467C16.572 12.92 17.848 11.662 19.416 11.662C20.984 11.662 22.26 12.92 22.26 14.467C22.26 16.013 20.984 17.271 19.416 17.271ZM19.416 10.93C17.439 10.93 15.83 12.516 15.83 14.467C15.83 16.417 17.439 18.003 19.416 18.003C21.393 18.003 23.002 16.417 23.002 14.467C23.002 12.517 21.393 10.93 19.416 10.93Z"
      fill={color}
    />
    <path
      d="M20.654 14.1H19.788V13.246C19.7877 13.1977 19.778 13.1499 19.7592 13.1053C19.7405 13.0608 19.7132 13.0204 19.6788 12.9864C19.6444 12.9524 19.6037 12.9255 19.559 12.9073C19.5142 12.889 19.4663 12.8798 19.418 12.88C19.3696 12.8796 19.3216 12.8888 19.2767 12.907C19.2319 12.9252 19.191 12.952 19.1566 12.986C19.1221 13.02 19.0947 13.0605 19.0759 13.1051C19.0571 13.1497 19.0473 13.1976 19.047 13.246V14.1H18.181C18.1327 14.0996 18.0847 14.1087 18.0399 14.1269C17.9951 14.145 17.9544 14.1718 17.9199 14.2057C17.8855 14.2396 17.858 14.2799 17.8392 14.3244C17.8203 14.3689 17.8104 14.4167 17.81 14.465C17.81 14.667 17.976 14.831 18.181 14.831H19.047V15.685C19.047 15.887 19.213 16.051 19.417 16.051C19.4654 16.0514 19.5134 16.0423 19.5583 16.0241C19.6032 16.0059 19.644 15.979 19.6784 15.945C19.7129 15.911 19.7403 15.8705 19.7591 15.8259C19.7779 15.7813 19.7877 15.7334 19.788 15.685V14.831H20.654C20.7024 14.8314 20.7504 14.8223 20.7953 14.8041C20.8402 14.7859 20.881 14.759 20.9155 14.725C20.9499 14.691 20.9773 14.6505 20.9961 14.6059C21.0149 14.5613 21.0247 14.5134 21.025 14.465C21.0242 14.3676 20.9848 14.2745 20.9155 14.206C20.8461 14.1376 20.7524 14.0995 20.655 14.1H20.654Z"
      fill={color}
    />
  </svg>
)

export default Sponsor
