import {
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Paper,
  Switch,
  Typography,
  createStyles,
  withStyles
} from '@material-ui/core'
import classNames from 'classnames'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withToastManager } from 'react-toast-notifications'
import 'url-search-params-polyfill'
import LinkButton from '../../components/buttons/LinkButton'
import PasswordInput from '../../components/form/PasswordInput'
import Select from '../../components/form/Select'
import FullCenteredLayout from '../../components/layout/FullCenteredLayout'
import { TOAST } from '../../hooks/HookToasts'
import Api from '../../services/Api'
import { isEmailValid, isPasswordValid, isPhoneValid } from '../../services/formUtils'
import { withUser } from '../../stores/UserProvider'
import palette from '../../theme/palette'

const styles = createStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginPaper: {
    padding: '30px 45px',
    maxWidth: '660px'
  },
  paperBtn: {
    paddingTop: 64,
    textAlign: 'center'
  },
  green: {
    color: palette.green
  },
  switch: {
    margin: `${theme.typography.pxToRem(5)}`
  },
  inline: {
    ...theme.common.inline
  }
}))

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      phone: '',
      phoneMessage: '',
      email: '',
      emailMessage: '',
      password: '',
      isLoading: false,
      sponsorCode: '',
      sponsorMessage: '',
      isSponsorFound: false,
      newsletter: false,
      cgu: false,
      place: '',
      places: [],
      city: '',
      cities: [],
      isPublic: false,
      department: '',
      departments: [],
      errors: [{ firstname: false, lastname: false, phone: false, email: false, password: false, city: false, place: false, department: false }]
    }
  }

  componentDidMount = () => {
    document.title = "box'n services – Création d'un compte"
    const params = new URLSearchParams(this.props.location.search)
    const user = params.get('u')
    if (user) {
      document.title = "box'n services – Création d'un compte pro"
      this.setState({ pro_parent_id: user })
      Api.getProDepartments(user).then(res => {
        this.setState({ departments: res.data.data.map(department => ({ label: department.name, value: department.id })) })
        this.autoSelect('department', 'departments')
      })
    } else {
      Api.getDepartments().then(res => {
        this.setState({ departments: res.data.data.map(department => ({ label: department.name, value: department.id })) })
      })
    }
    const code = params.get('c')
    if (code) {
      this.setState({ sponsorCode: code })
    }

    // ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleChange = (e, i) => {
    this.setState({ [e.target.name]: e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value })
  }

  handlePhoneChange = (e, i) => {
    const value = e.target.value
    this.setState({ phone: value })
    if (isPhoneValid(value) || value.length === 0) {
      this.setState({ phoneMessage: '' })
    } else {
      this.setState({ phoneMessage: "Le téléphone n'est pas valide." })
    }
  }

  handleSelect = (name, value) => {
    if ('department' === name) {
      this.setState({ cities: [], city: '', place: '', places: [] })

      if ('' !== value) {
        this.updateCities(value)
      }
    }

    if (name === 'city' && value !== '') {
      this.updatePlaces(value)
    }
    this.setState({
      [name]: value
    })
  }

  handleSponsorChange = e => {
    const value = e.target.value
    this.setState({ sponsorCode: value, isSponsorFound: false })

    if (value.length === 8) {
      Api.getUserBySponsor({ sponsor_code: value }).then(res => {
        if (res.data.data && res.data.data.user) {
          this.setState({ isSponsorFound: true, sponsorMessage: '' })
        } else {
          this.setState({ sponsorMessage: "Le code n'est pas correct." })
        }
      })
    } else if (value.length === 0) {
      this.setState({ sponsorMessage: '' })
    } else {
      this.setState({ sponsorMessage: 'Le code doit avoir 8 caractères exactement.' })
    }
  }

  handleEmailChange = e => {
    const value = e.target.value
    this.setState({ email: value })
    if (isEmailValid(value)) {
      if (this.state.pro_parent_id) {
        Api.checkEmailDomain({
          pro_parent_id: this.state.pro_parent_id,
          email: value
        }).then(res => {
          if (res.data.success === true) {
            this.setState({ emailMessage: '' })
          } else {
            this.setState({ emailMessage: "Merci d'utiliser votre email d'entreprise." })
          }
        })
      } else {
        this.setState({ emailMessage: '' })
      }
    } else {
      this.setState({ emailMessage: "L'email n'est pas valide." })
    }
  }

  updateCities = department => {
    const { pro_parent_id } = this.state
    Api.getCities(pro_parent_id, { department }).then(res => {
      this.setState({ cities: res.data.data.city.map(city => ({ label: city.name, value: city.id })) })
      this.autoSelect('city', 'cities')
    })
  }

  updatePlaces = cityId => {
    const { isPublic, pro_parent_id } = this.state
    Api.getPlaces(cityId, isPublic, pro_parent_id).then(res => {
      if (res.data && res.data.data.error) {
        console.error(res.data.data.error)
        return
      }

      const places = res.data.data.place.map(place => ({ value: place.id, label: place.name }))
      this.setState({ places: places })
      this.autoSelect('place', 'places')
    })
  }

  handlePublic = () => {
    const { isPublic, city } = this.state
    this.setState({ isPublic: !isPublic }, () => {
      if (city) {
        this.updatePlaces(city)
      }
    })
  }

  autoSelect = (attribute, collections) => {
    if (this.state[collections].length === 1) {
      this.handleSelect(attribute, this.state[collections][0].value)
    }
  }

  register = async e => {
    e.preventDefault()
    const { toastManager } = this.props
    const { email, emailMessage, password, firstname, lastname, phone, city, place, sponsorCode, newsletter, cgu, pro_parent_id, department } = this.state

    const fields = [
      { id: 'lastname', label: 'nom', value: lastname },
      { id: 'firstname', label: 'prénom', value: firstname },
      { id: 'phone', label: 'numéro de téléphone', value: phone },
      { id: 'email', label: 'adresse email', value: email },
      { id: 'password', label: 'mot de passe', value: password },
      { id: 'department', label: 'département', value: department },
      { id: 'city', label: 'ville', value: city },
      { id: 'place', label: 'lieu favori', value: place }
    ]
    let errors = this.state.errors
    for (const field of fields) {
      if (field.value) {
        errors[field.id] = false
      } else {
        errors[field.id] = true
      }
    }
    this.setState({ ...this.state, errors })

    for (const field of fields) {
      if (!field.value) {
        toastManager.add('Veuillez renseigner votre ' + field.label, { appearance: TOAST.ERROR })
        return
      }
    }
    if (!isEmailValid(email) || emailMessage.length > 0) {
      toastManager.add("L'email est invalide", { appearance: TOAST.ERROR })
      return
    }
    if (!cgu) {
      toastManager.add('Veuillez accepter les CGU ainsi que notre politique de confidentialité', { appearance: TOAST.ERROR })
      return
    }

    if (!isPhoneValid(phone)) {
      toastManager.add('Le téléphone est invalide', { appearance: TOAST.ERROR })
      return
    }

    if (!isPasswordValid(password)) {
      toastManager.add('Le mot de passe est invalide. Il doit contenir au moins 8 caractères.', { appearance: TOAST.ERROR })
      return
    }

    this.setState({ isLoading: true })
    const user = {
      firstname,
      lastname,
      email,
      password,
      phone,
      pro_parent_id: pro_parent_id || undefined,
      sponsor_code: sponsorCode || undefined,
      nl_main: newsletter ? 1 : 0,
      accept: 1,
      client_place_id: place
    }
    const res = await this.props.userContext.register(user)

    if (res.status === 200) {
      this.setState({ isLoading: false })
      this.props.history.push('/', { isnew: true })
    } else {
      toastManager.add(res.status, { appearance: TOAST.ERROR })
      this.setState({ isLoading: false })
    }
  }

  render() {
    const params = new URLSearchParams(this.props.location.search)
    const user = params.get('u')
    const { classes } = this.props

    const {
      firstname,
      lastname,
      phone,
      phoneMessage,
      email,
      emailMessage,
      password,
      city,
      cities,
      place,
      places,
      sponsorCode,
      sponsorMessage,
      isSponsorFound,
      newsletter,
      cgu,
      errors,
      isPublic,
      pro_parent_id,
      department,
      departments
    } = this.state

    return (
      <FullCenteredLayout title={user ? 'Création de compte professionnel' : "Création d'un compte"}>
        <Paper className={classes.loginPaper}>
          {user && (
            <Typography>
              Ce compte pro est réservé au services utilisés dans le cadre professionnel. Toute commande donnera lieu à une facturation au nom de votre
              entreprise et un règlement par votre service comptabilité.
            </Typography>
          )}
          <form autoComplete="off" onSubmit={this.register}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required fullWidth error={errors.lastname}>
                  <InputLabel shrink htmlFor="lastname">
                    Nom
                  </InputLabel>
                  <Input id="lastname" type="text" value={lastname} onChange={this.handleChange} name="lastname" fullWidth placeholder="Entrez votre nom" />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required fullWidth error={errors.firstname}>
                  <InputLabel shrink htmlFor="firstname">
                    Prénom
                  </InputLabel>
                  <Input
                    id="firstname"
                    type="text"
                    value={firstname}
                    onChange={this.handleChange}
                    name="firstname"
                    fullWidth
                    placeholder="Entrez votre prénom"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required fullWidth error={errors.phone || phoneMessage.length > 0}>
                  <InputLabel shrink htmlFor="phone">
                    Téléphone
                  </InputLabel>
                  <Input
                    id="phone"
                    type="tel"
                    value={phone}
                    onChange={this.handlePhoneChange}
                    name="phone"
                    fullWidth
                    placeholder="Entrez votre numéro de téléphone"
                  />
                  <FormHelperText>{phoneMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required fullWidth error={errors.email || emailMessage.length > 0}>
                  <InputLabel shrink htmlFor="email">
                    Email
                  </InputLabel>
                  <Input id="email" type="email" value={email} onChange={this.handleEmailChange} name="email" fullWidth placeholder="Entrez votre email" />
                  <FormHelperText>{emailMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <PasswordInput
                  required
                  fullWidth
                  error={errors.password}
                  margin="normal"
                  value={password}
                  name="password"
                  label="Mot de passe"
                  placeholder="Entrez votre mot de passe"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required fullWidth error={errors.department}>
                  <InputLabel shrink htmlFor="department">
                    Département
                  </InputLabel>
                  <Select
                    id="department"
                    name="department"
                    value={department}
                    onChange={e => this.handleSelect('department', e.target.value)}
                    options={departments}
                    placeholder="Entrez votre département"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Collapse in={Boolean(department)}>
                  <FormControl margin="normal" required fullWidth error={errors.city}>
                    <InputLabel shrink htmlFor="city">
                      Ville
                    </InputLabel>
                    <Select
                      id="city"
                      name="city"
                      value={city}
                      onChange={e => this.handleSelect('city', e.target.value)}
                      options={cities}
                      placeholder="Entrez votre ville"
                    />
                  </FormControl>
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Collapse in={Boolean(city)}>
                  <FormControl margin="normal" required fullWidth error={errors.place}>
                    <InputLabel shrink htmlFor="place">
                      Lieu favori
                    </InputLabel>
                    <Select
                      id="place"
                      name="place"
                      value={place}
                      onChange={e => this.handleSelect('place', e.target.value)}
                      options={places}
                      placeholder="Entrez votre lieu favori"
                    />
                  </FormControl>
                  {!pro_parent_id && (
                    <div className={classes.switch}>
                      <FormControlLabel
                        label="Afficher uniquement les lieux publics"
                        required
                        classes={{ label: classes.miniLabel }}
                        control={<Switch color="primary" checked={isPublic} value="isPublic" name="isPublic" aria-label="A" onChange={this.handlePublic} />}
                      />
                    </div>
                  )}
                </Collapse>
              </Grid>
              {!pro_parent_id && (
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth error={!isSponsorFound && sponsorCode.length > 0 && sponsorMessage.length > 0}>
                    <InputLabel shrink htmlFor="sponsorCode">
                      Code de parrainage
                    </InputLabel>
                    <Input
                      id="sponsorCode"
                      type="sponsorCode"
                      value={sponsorCode}
                      onChange={this.handleSponsorChange}
                      name="sponsorCode"
                      fullWidth
                      placeholder="Entrez votre code de parrainage"
                    />
                    {sponsorCode.length > 0 && <FormHelperText className={classes.green}>{sponsorMessage ? sponsorMessage : 'Code valide'}</FormHelperText>}
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.switch}
                  label="S'abonner à la lettre d'information Le Bon Plan Conciergerie (recevez un mail tous les mois sur l'actualité et les promotions de box'n services)"
                  control={<Switch color="primary" checked={newsletter} value={newsletter} onChange={this.handleChange} name="newsletter" aria-label="A" />}
                />

                <div className={classNames[(classes.inline, { alignItems: 'flex-start', marginLeft: 8 })]}>
                  <FormControlLabel
                    className={classes.switch}
                    label="J'accepte les conditions générales d'utilisation *"
                    required
                    control={<Switch color="primary" checked={cgu} value={cgu} onChange={this.handleChange} name="cgu" aria-label="A" />}
                  />
                  {place && (
                    <LinkButton target="_blank" rel="noreferrer noopener" to={`/cgu/${place}`}>
                      Lire les CGU
                    </LinkButton>
                  )}
                </div>
              </Grid>
            </Grid>

            <div className={classes.paperBtn}>
              <Button style={{ marginBottom: '12px' }} type="submit" size="large" variant="contained" color="secondary" onClick={this.register}>
                {this.state.isLoading ? <CircularProgress size="24px" color="primary" /> : "S'inscrire"}
              </Button>
            </div>
          </form>
        </Paper>
      </FullCenteredLayout>
    )
  }
}

export default withToastManager(withRouter(withUser(withStyles(styles)(Register))))
