import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MenuItem, Select, Input } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  select: {
    marginTop: 10
  }
}))

const MNSelect = ({ options, id, textFieldProps, placeholder, ...rest }) => {
  const classes = useStyles()

  return (
    <>
      <Select {...rest} displayEmpty input={<Input name={id} id={id} />} className={classes.select}>
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default MNSelect
