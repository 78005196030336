import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper, Grid, List, ListItem, ListItemText } from '@material-ui/core'
import classNames from 'classnames'
import { VictoryPie } from 'victory'
import Color from 'color'
import palette from '../../theme/palette'

const useStyles = makeStyles(theme => ({
  blocPaper: {
    padding: 18
  },
  blocTitle: {
    ...theme.common.blocTitle
  },
  squareColor: {
    width: 14,
    height: 14,
    marginRight: 10
  }
}))

const colors = [palette.dark, palette.clearBlue, palette.green, palette.yellow, palette.red, palette.purple]

const TopPlaces = ({ rawData }) => {
  const classes = useStyles()
  const [placesData, setPlacesData] = useState([])

  function getColor(index) {
    const i = index > colors.length ? index % colors.length : index
    const percent = (Math.trunc(index / colors.length) > 0 ? Math.trunc(index / colors.length) : 0) * 10

    return Color(colors[i])
      .lighten((0 + percent) / 100)
      .hex()
  }

  useEffect(() => {
    let data = []
    if (rawData && rawData.length > 0) {
      data = rawData.map((place, index) => {
        return {
          x: place.name,
          y: place.percent,
          fill: getColor(index)
        }
      })
    }
    setPlacesData(data)
  }, [rawData])

  return (
    <Paper className={classes.blocPaper}>
      <Typography variant="h2" className={classes.blocTitle}>
        Top Lieux
      </Typography>
      {placesData.length > 0 ? (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={6}>
            <VictoryPie
              style={{
                data: {
                  fill: ({ fill }) => fill
                }
              }}
              width={160}
              height={160}
              innerRadius={60}
              labels={d => null}
              padAngle={2}
              data={placesData}
            />
          </Grid>
          <Grid item xs={6}>
            <List>
              {placesData.map((line, index) => (
                <ListItem key={`${index}`} disableGutters dense>
                  <div className={classNames(classes.squareColor)} style={{ backgroundColor: line.fill }} />
                  <ListItemText disableTypography>
                    <Typography variant="body2">{line.x}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body2">Aucune commande</Typography>
      )}
    </Paper>
  )
}

export default TopPlaces
