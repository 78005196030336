import { Button, CircularProgress, Collapse, FormControl, FormControlLabel, Grid, Input, InputLabel, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import defaultImg from '../../assets/default.png'
import ScalingUpload from '../../components/form/ScalingUpload'
import Select from '../../components/form/Select'
import config from '../../config'
import { getRoleRoute } from '../../services/helpers'
import AccountFormProvider, { AccountFormContext } from '../../stores/AccountFormProvider'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: 52
  },
  miniLabel: {
    fontSize: 12
  },
  photoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 16
  },
  btn: {
    marginRight: 5,
    marginLeft: 'auto'
  },
  switch: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

const AccountForm = props => {
  const classes = useStyles()

  return (
    <AccountFormProvider>
      <AccountFormContext.Consumer>
        {({ accountFormContext }) => {
          return (
            <form autoComplete="off" onSubmit={accountFormContext.submit}>
              <Grid container spacing={4} alignItems="flex-start">
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink htmlFor="lastname">
                      Nom
                    </InputLabel>
                    <Input
                      id="lastname"
                      type="lastname"
                      value={accountFormContext.lastname}
                      onChange={accountFormContext.handleChange}
                      name="lastname"
                      fullWidth
                      placeholder="Entrez votre nom"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink htmlFor="firstname">
                      Prénom
                    </InputLabel>
                    <Input
                      id="firstname"
                      type="firstname"
                      value={accountFormContext.firstname}
                      onChange={accountFormContext.handleChange}
                      name="firstname"
                      fullWidth
                      placeholder="Entrez votre prénom"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink htmlFor="phone">
                      Téléphone
                    </InputLabel>
                    <Input
                      id="phone"
                      type="tel"
                      value={accountFormContext.phone}
                      onChange={accountFormContext.handleChange}
                      name="phone"
                      fullWidth
                      placeholder="Entrez votre numéro de téléphone"
                    />
                  </FormControl>
                </Grid>

                {[config.roles.deliverer, config.roles.artisan].includes(getRoleRoute(accountFormContext.role)) && (
                  <Grid item xs={12} sm={6}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel shrink htmlFor="phone2">
                        Téléphone Fixe
                      </InputLabel>
                      <Input
                        id="phone2"
                        type="tel"
                        value={accountFormContext.phone2}
                        onChange={accountFormContext.handleChange}
                        name="phone2"
                        fullWidth
                        placeholder="Entrez votre numéro de téléphone fixe"
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel shrink htmlFor="button-desc-img">
                      Photo de profil
                    </InputLabel>

                    <div className={classes.photoContainer}>
                      <ScalingUpload
                        id="button-desc-img"
                        src={accountFormContext.img ? accountFormContext.img : defaultImg}
                        onLoadEnd={img => {
                          accountFormContext.handleImg(img)
                        }}
                        maxHeight={200}
                        maxWidth={300}
                      />
                      <label htmlFor="button-desc-img" className={classes.btn}>
                        <Button variant="outlined" color="primary" size="small" component="span">
                          Modifier
                        </Button>
                      </label>
                      <Button
                        size="small"
                        disabled={accountFormContext.img === null}
                        variant="outlined"
                        onClick={() => accountFormContext.handleImg(null)}
                        color="secondary"
                      >
                        Supprimer
                      </Button>
                    </div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink htmlFor="email">
                      Email
                    </InputLabel>
                    <Input
                      id="email"
                      type="email"
                      value={accountFormContext.email}
                      onChange={accountFormContext.handleChange}
                      name="email"
                      fullWidth
                      placeholder="Entrez votre email"
                    />
                  </FormControl>
                </Grid>

                {[config.roles.entreprise, config.roles.artisan, config.roles.livreur].includes(getRoleRoute(accountFormContext.role)) === false && (
                  <Grid item xs={12}>
                    <Grid container spacing={4} alignItems="flex-start">
                      <Grid item xs={12} sm={6}>
                        <FormControl margin="normal" required={true} fullWidth>
                          <InputLabel shrink htmlFor="department">
                            Département
                          </InputLabel>
                          <Select
                            id="department"
                            name="department"
                            value={accountFormContext.department}
                            onChange={e => accountFormContext.handleSelect('department', e.target.value)}
                            options={accountFormContext.departments}
                            placeholder="Entrez votre département"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Collapse in={Boolean(accountFormContext.department)}>
                          <FormControl margin="normal" required={true} fullWidth>
                            <InputLabel shrink htmlFor="city">
                              Ville
                            </InputLabel>
                            <Select
                              id="city"
                              name="city"
                              value={accountFormContext.city}
                              onChange={e => accountFormContext.handleSelect('city', e.target.value)}
                              options={accountFormContext.cities}
                              placeholder="Entrez votre ville"
                            />
                          </FormControl>
                        </Collapse>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Collapse in={Boolean(accountFormContext.city)}>
                          <FormControl margin="normal" required={true} fullWidth>
                            <InputLabel shrink htmlFor="place">
                              Lieu favori
                            </InputLabel>
                            <Select
                              id="place"
                              name="place"
                              value={accountFormContext.place}
                              onChange={e => accountFormContext.handleSelect('place', e.target.value)}
                              options={accountFormContext.places}
                              placeholder="Entrez votre lieu favori"
                            />
                          </FormControl>
                          <div className={classes.switch}>
                            <FormControlLabel
                              label="Afficher uniquement les lieux publics"
                              required
                              classes={{ label: classes.miniLabel }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={accountFormContext.isPublic}
                                  value="isPublic"
                                  name="isPublic"
                                  aria-label="A"
                                  onChange={accountFormContext.handlePublic}
                                />
                              }
                            />
                          </div>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Button style={{ marginBottom: '12px' }} type="submit" size="large" variant="contained" color="primary" onClick={accountFormContext.submit}>
                    {accountFormContext.isLoading ? <CircularProgress size="24px" color="primary" /> : 'Enregistrer'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </AccountFormContext.Consumer>
    </AccountFormProvider>
  )
}

export default AccountForm
