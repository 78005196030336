import React from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import Api from '../../services/Api'

const useStyles = makeStyles(theme => ({
  pagination: {
    display: 'flex',
    listStyle: 'none',
    justifyContent: 'center',
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '600'
  },
  item: {
    marginRight: theme.typography.pxToRem(5),
    marginLeft: theme.typography.pxToRem(5),
    cursor: 'pointer'
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    margin: 0
  },
  disabled: {
    opacity: '0.5',
    cursor: 'default'
  }
}))

const Pagination = props => {
  const classes = useStyles()
  const { links, meta, onChangePage } = props

  const fetchItems = e => {
    e.preventDefault()
    const anchor = e.currentTarget.getAttribute('href')
    if (!anchor) return
    Api.get(anchor).then(res => onChangePage(res.data))
  }

  return (
    <>
      {(links.prev || links.next) && (
        <ul className={classes.pagination}>
          <li
            className={classNames(classes.item, {
              [classes.disabled]: !links.prev
            })}
          >
            <a className={classes.link} onClick={fetchItems} href={links.prev}>
              &lt; Précédent
            </a>
          </li>
          <li className={classes.item}>
            <p className={classes.link}>
              {meta.currentPage} / {meta.pageCount}
            </p>
          </li>
          <li
            className={classNames(classes.item, {
              [classes.disabled]: !links.next
            })}
          >
            <a className={classes.link} onClick={fetchItems} href={links.next}>
              Suivant &gt;
            </a>
          </li>
        </ul>
      )}
    </>
  )
}

export default Pagination
