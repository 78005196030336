import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import PlanningForm from '../../components/form/PlanningForm'
import LinkButtonOption from '../../components/buttons/LinkButtonOption'
import ExceptionForm from '../../components/form/ExceptionForm'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  back: {
    marginRight: 5
  },
  inline: {
    ...theme.common.inline
  },
  new: {
    margin: '50px auto',
    textAlign: 'center'
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

const ScheduleTab = props => {
  const classes = useStyles()
  const { global } = props
  const { userContext } = useContext(UserContext)
  const [places, setPlaces] = useState([])
  const [schedule, setSchedule] = useState(null)
  const [selectedPlace, setSelectedPlace] = useState(null)

  function getSchedule() {
    Api.getSchedule(userContext.getId(), selectedPlace).then(res => {
      if (res.data && !res.data.data.error) {
        setSchedule(res.data.data)
      }
    })
  }

  useEffect(() => {
    if (global) {
      getSchedule()
      return
    }

    Api.getUserPlaces(userContext.getId()).then(res => {
      if (res.data.success) {
        let newPlaces = res.data.data.map(place => ({ ...place, value: place.id, label: place.name }))
        if (newPlaces.length === 1) {
          setSelectedPlace(newPlaces[0].id)
        } else {
          newPlaces.unshift({ value: null, label: 'Choisir un lieu' })
        }
        setPlaces(newPlaces)
      }
    })
  }, [])

  useEffect(() => {
    getSchedule()
  }, [selectedPlace])

  return (
    <div className={classes.sectionPaper}>
      <div className={classes.section}>
        <div className={classes.inline}>
          <Typography variant="h4">Planning</Typography>
          {!global && (
            <LinkButtonOption options={places} value={selectedPlace} onChange={item => setSelectedPlace(item)} placeholder="Choisissez un lieu à éditer" />
          )}
        </div>

        {global ? (
          <>
            <PlanningForm schedule={schedule} global />
          </>
        ) : (
          <>{selectedPlace && <PlanningForm schedule={schedule} />}</>
        )}
      </div>
      <div className={classes.section}>
        <Typography variant="h4">Exceptions</Typography>

        {global ? <ExceptionForm schedule={schedule} global /> : <>{selectedPlace && <ExceptionForm schedule={schedule} />}</>}
      </div>
    </div>
  )
}

export default ScheduleTab
