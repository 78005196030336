import React, { useEffect, useContext } from 'react'
import { PreorderContext } from '../../stores/PreorderProvider'
import { UserContext } from '../../stores/UserProvider'
import { Redirect } from 'react-router-dom'
import { Typography, Button, Grid, IconButton, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DynamicImport } from '../../components/DynamicImport'
import { ReactComponent as BackIcon } from '../../assets/icons/leftarrow.svg'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: 5
  },
  navButtons: {
    ...theme.common.inline,
    margin: `${theme.typography.pxToRem(50)} 0`,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  btn: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.typography.pxToRem(15),
      width: '100%'
    }
  }
}))

const Process = props => {
  const classes = useStyles()
  const { location, history } = props
  const { orderContext } = useContext(PreorderContext)
  const { userContext } = useContext(UserContext)
  const currentStep = location.hash ? location.hash.substr(1) : 1

  if (orderContext.services.length === 0) return <Redirect to="/client/" />

  useEffect(() => {
    orderContext.getProcess()
  }, [])

  function handlePrev() {
    orderContext.showNextButton()
    if (parseInt(currentStep) === 1) {
      history.push({ pathname: '/client', state: { add: true } })
    } else {
      history.push(`${location.pathname}#${parseInt(currentStep) - 1}`)
    }
  }

  function handleNext() {
    // Todo : Payment Module should alwqays come Last ??

    if (isPaymentModule() || isLastStep()) {
      orderContext.hideNextButton()

      orderContext.submitPreorder(userContext.user.place.value).then(res => {
        if (res.data.data && res.data.data.id) {
          const redirect =
            isPaymentModule() && !res.data.data.paid ? `${location.pathname}#${parseInt(currentStep) + 1}` : `/client/commandes/${res.data.data.id}`
          history.push(redirect)
        }
      })
    } else {
      history.push(`${location.pathname}#${parseInt(currentStep) + 1}`)
    }
  }

  function isPaymentModule() {
    let isPayment = false
    if (orderContext.process.steps[parseInt(currentStep) + 1]) {
      isPayment = orderContext.process.steps[parseInt(currentStep) + 1].modules.find(item => {
        return item.module.component === 'Payment'
      })
    }
    return Boolean(isPayment)
  }

  function isLastStep() {
    return !Boolean(orderContext.process.steps[parseInt(currentStep) + 1]) || isPaymentModule()
  }

  if (orderContext.isLoading) {
    return <CircularProgress size="24px" color="primary" />
  }

  return (
    <>
      <Typography variant="h3">{orderContext.services.map(ser => ser.name).join(' & ')}</Typography>
      <Typography variant="h1">
        <IconButton className={classes.backButton} onClick={handlePrev}>
          <BackIcon />
        </IconButton>
        {orderContext.process.steps[currentStep].title}
      </Typography>

      <div className="modules">
        <Grid container spacing={2}>
          {orderContext.process.steps[currentStep].modules.map(module => {
            if (module.module.component) {
              return (
                <DynamicImport
                  key={module.module.id}
                  module={module}
                  step={currentStep}
                  history={props.history}
                  artisan={orderContext.services[0].artisan}
                  resolve={() => import(`../../components/modules/${module.module.component.toLowerCase()}/${module.module.component}`)}
                />
              )
            }
            return <p>Aucun template défini pour ce module.</p>
          })}
        </Grid>
      </div>

      <div className={classes.navButtons}>
        <Button className={classes.btn} size="large" variant="outlined" color="primary" onClick={handlePrev}>
          Etape précédente
        </Button>
        {orderContext.showNextBtn && (
          <Button
            className={classes.btn}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!orderContext.canGoToNext(currentStep)}
          >
            {isLastStep() ? 'Valider ma commande' : 'Etape Suivante'}
          </Button>
        )}
      </div>
    </>
  )
}

export default Process
