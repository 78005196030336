import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import OrderTeaser from '../../components/entities/OrderTeaser'
import LinkButtonOption from '../../components/buttons/LinkButtonOption'
import Pagination from '../../components/layout/Pagination'
import Api from '../../services/Api'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  filterContainer: {
    display: 'inline-block',
    marginBottom: theme.typography.pxToRem(15),
    marginLeft: theme.typography.pxToRem(15)
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  }
}))

const Orders = props => {
  const classes = useStyles()
  const [orders, setOrders] = useState([])
  const [filters, setFilters] = useState({
    status: '',
    date: 'DESC',
    page: 1,
    isDefault: true
  })
  const { userContext } = useContext(UserContext)
  const [statusOptions, setStatusOptions] = useState([{ value: '', label: 'Tous les statuts' }])
  const [paginationLinks, setPaginationLinks] = useState({})
  const [paginationMeta, setPaginationMeta] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!filters.isDefault) {
      setLoading(true)
      Api.getOrders(userContext.getRole(), userContext.getId(), { params: filters }).then(res => {
        if (res.data) {
          setOrdersData(res.data)
          changeCurrentUrlParams()
        }
        setLoading(false)
      })
    }
  }, [filters])

  useEffect(() => {
    Api.getOrderStatus(userContext.getRole()).then(res => {
      if (res.data) {
        const statuses = Object.keys(res.data.data).map((key, index) => ({ value: parseInt(key), label: res.data.data[key] }))
        setStatusOptions([...statusOptions, ...statuses])

        const params = new URLSearchParams(props.location.search)
        const page = params.get('p')
        const filterStatus = params.get('s')
        setFilters({
          ...filters,
          page,
          status: filterStatus ? parseInt(filterStatus) : '',
          isDefault: false
        })
      }
    })
  }, [])

  const setOrdersData = data => {
    setOrders(data.data)
    setPaginationLinks({ prev: data.links.prev, next: data.links.next })
    setPaginationMeta({ currentPage: data.meta.current_page, pageCount: data.meta.last_page })
  }

  const changeCurrentUrlParams = () => {
    const currentUrlParams = new URLSearchParams(props.location.search)
    if (filters.page) {
      currentUrlParams.set('p', filters.page)
    }
    if (filters.status) {
      currentUrlParams.set('s', filters.status)
    }
    props.history.push(window.location.pathname + '?' + currentUrlParams.toString())
  }

  return (
    <div className={classes.section}>
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h1">Mes commandes {paginationMeta && paginationMeta.totalCount > 0 && `(${paginationMeta.totalCount})`}</Typography>
        </Grid>
        <Grid item>
          <div className={classes.filterContainer}>
            <LinkButtonOption
              onChange={date => {
                setFilters({
                  ...filters,
                  date
                })
              }}
              options={[{ label: 'Trier par date', value: '' }, { label: 'Croissante', value: 'ASC' }, { label: 'Décroissante', value: 'DESC' }]}
            />
          </div>
          <div className={classes.filterContainer}>
            {statusOptions.length > 1 && (
              <LinkButtonOption
                selected={parseInt(filters.status)}
                onChange={status => {
                  setFilters({
                    ...filters,
                    status
                  })
                }}
                options={statusOptions}
              />
            )}
          </div>
        </Grid>
      </Grid>

      {loading ? (
        <CircularProgress />
      ) : orders.length > 0 ? (
        <>
          {orders.map((order, key) => {
            return <OrderTeaser order={order} key={`order${key}`} withLinks />
          })}
          <Pagination
            links={paginationLinks}
            meta={paginationMeta}
            onChangePage={data => {
              if (data) {
                setOrdersData(data)
                props.history.push(`?p=${data.meta.current_page}`)
                window.scrollTo(0, 0)
              }
            }}
          />
        </>
      ) : (
        <Typography className={classes.noResults} variant="h4">
          Aucune commande ne correspond à ces critères.
        </Typography>
      )}
    </div>
  )
}

export default Orders
