import React, { useContext, useState, useEffect } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  CircularProgress
} from '@material-ui/core'
import { CheckCircle, ExpandMore, RemoveCircle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import config from '../../config'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { Link } from 'react-router-dom'
import defaultImg from '../../assets/default-image.svg'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  root: {
    width: '100%'
  },
  img: {
    ...theme.common.avatar,
    width: 30,
    height: 30
  }
}))

const ProductsBlock = props => {
  const classes = useStyles()
  const [categories, setCategories] = useState([])
  const [toDelete, setToDelete] = useState(null)
  const [loading, setLoading] = useState(false)
  const { userContext } = useContext(UserContext)
  const { service } = props

  useEffect(() => {
    getList()
  }, [])

  const getList = () =>
    Api.getProducts(userContext.getId(), service.id, 0).then(res => {
      if (res.data.success) {
        setCategories(res.data.data.category)
      }
    })

  const handleClose = () => setToDelete(null)

  const confirmDelete = async () => {
    setLoading(true)
    const res = await Api.deleteProduct(toDelete)
    if (res && res.data && res.data.success) {
      getList()
    }
    setToDelete(null)
    setLoading(false)
  }

  return (
    <Paper className={classes.sectionPaper}>
      <div className={classes.root}>
        {categories &&
          categories.map(category => (
            <ExpansionPanel square key={`${category.id}`}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h3" style={{ marginBottom: 0 }}>
                  {category.name}
                </Typography>
              </ExpansionPanelSummary>
              {category.products && (
                <ExpansionPanelDetails>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Categorie</TableCell>
                        <TableCell align="center">Actif</TableCell>
                        <TableCell align="center">TVA</TableCell>
                        <TableCell align="center">Prix TTC</TableCell>
                        <TableCell align="center">Visible</TableCell>
                        <TableCell align="center">T.R.</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {category.products.map(product => (
                        <TableRow key={`${product.id}`}>
                          <TableCell>
                            <img alt={product.name} src={product.img ? product.img : defaultImg} className={classes.img} />
                          </TableCell>

                          <TableCell style={{ width: 150 }}>
                            <Typography>{product.name}</Typography>
                            <Typography variant="body2">
                              {product.desc && product.desc.length > 50 ? product.desc.substring(0, 50) + '...' : product.desc}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ width: 250 }}>{category.name}</TableCell>

                          <TableCell align="center">{product.status === 1 ? <CheckCircle color="primary" /> : <RemoveCircle color="secondary" />}</TableCell>
                          <TableCell align="center">{config.tva.find(p => p.value === product.tva).label}</TableCell>
                          <TableCell align="center">{product.price_ttc}€</TableCell>
                          <TableCell align="center">{product.visible === 1 ? <CheckCircle color="primary" /> : <RemoveCircle color="secondary" />}</TableCell>
                          <TableCell align="center">{product.tr_enable === 1 ? <CheckCircle color="primary" /> : <RemoveCircle color="secondary" />}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Editer le produit" aria-label="Editer le produit">
                              <IconButton
                                component={Link}
                                to={{
                                  pathname: `/artisan/produits/${service.id}/${product.id}`,
                                  state: {
                                    service: service,
                                    product: product,
                                    categories: categories.map(category => ({ value: category.id, label: category.name }))
                                  }
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Supprimer le produit" aria-label="Supprimer le produit">
                              <IconButton onClick={() => setToDelete(product.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                      {!category.products && (
                        <div className={classes.section}>
                          <Typography>Aucun product pour le moment</Typography>
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          ))}
      </div>
      <Dialog open={toDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmation requise</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir supprimer ce contenu ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="contained" size="large" color="secondary" onClick={confirmDelete}>
            {loading ? <CircularProgress size="24px" color="white" /> : 'Supprimer'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ProductsBlock
