import React, { createContext, Component } from 'react'
import { withUser } from './UserProvider'
import Api from '../services/Api'
import has from '../utils/has' // @check https://github.com/lodash/lodash/blob/master/has.js
import { getTotal, getTotalTTC, getProcessingFee, getTotalPromo } from '../services/preOrderUtils'

export const PreorderContext = createContext({})

class PreorderProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      services: [],
      process: null,
      showNextBtn: true,
      canGoToNext: step => {
        let isValid = true
        this.state.process.steps[step].modules.map(mod => {
          if (!mod.isValid) {
            isValid = false
          }
          return mod
        })
        return isValid
      },
      handleModuleValid: (step, module, valid) => {
        const process = { ...this.state.process }
        const newStep = { ...this.state.process.steps[step] }
        newStep.modules.map(mod => {
          if (module.id === mod.id) {
            mod.isValid = valid
          }
          return mod
        })

        process.steps[step] = newStep
        this.setState({ process })
      },
      order: {
        products: [],
        promo: null,
        role: 'client',
        minAmount: 0
      },
      getProcess: () => {
        this.setState({ isLoading: true })
        Api.getProcess(this.state.services[0].process).then(res => {
          if (!res.data.data.error) {
            const process = { ...res.data.data }
            delete process.process
            process.steps = []

            res.data.data.process.forEach(module => {
              process.steps[module.step] = process.steps[module.step] || { modules: [] }
              if (module.sort_order === 1) {
                process.steps[module.step].title = module.module.title ? module.module.title : 'Ma commande'
              }
              process.steps[module.step].modules.push({ ...module, isValid: true })
            })

            this.setState({ isLoading: false, process })
          }
        })
      },
      handlePreorder: (name, value) => {
        this.setState(state => ({ order: { ...state.order, [name]: value } }))
      },
      getTotal: () => getTotal(this.state.order),
      getTotalTTC: () => getTotalTTC(this.state.order),
      getProcessingFee: () => getProcessingFee(this.state.order),
      getTotalPromo: () => getTotalPromo(this.state.order),
      handleService: async services => {
        await this.setState(state => ({
          services: services,
          showNextBtn: true
        }))
      },
      submitPreorder: place_id => {
        const order = {
          ...this.state.order,
          place_id,
          service_id: this.state.services[0].id,
          artisan_id: this.state.services[0].artisan,
          min_amount: this.state.order.minAmount
        }
        return Api.setPreorder(order)
          .then(res => {
            if (res.data.data && res.data.data.id) {
              this.setState(state => ({
                order: {
                  ...state.order,
                  ...res.data.data
                }
              }))
              return res
            }
          })
          .catch(e => {
            console.log('submitPreorder', e)
          })
      },
      hideNextButton: () => {
        if (this.state.showNextBtn) this.setState({ showNextBtn: false })
      },
      showNextButton: () => {
        if (!this.state.showNextBtn) this.setState({ showNextBtn: true })
      },
      orderCreated: () => {
        return has(this.state.order, 'id')
      },
      resetStateOrder: () => {
        this.setState(state => ({
          ...state,
          order: {
            products: [],
            promo: null,
            client_id: props.userContext.getId()
          }
        }))
      }
    }
  }

  componentDidMount = () => {
    this.setState((state, props) => ({
      order: {
        ...state.order,
        client_id: props.userContext.getId()
      }
    }))
  }

  render = () => <PreorderContext.Provider value={{ orderContext: this.state }}>{this.props.children}</PreorderContext.Provider>
}

export const withPreorder = Component => props => <PreorderContext.Consumer>{store => <Component {...props} {...store} />}</PreorderContext.Consumer>

export default withUser(PreorderProvider)
