import palette from '../theme/palette'
const config = {
  bearer: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
  baseURL: process.env.REACT_APP_BASE_URL,
  apiURL: process.env.REACT_APP_BASE_URL + '/api/v1',
  wsURL: process.env.REACT_APP_WS_ENDPOINT,
  publicURL: process.env.REACT_APP_PUBLIC_URL,
  mediaURL: process.env.REACT_APP_MEDIA_URL,
  maxTR: process.env.REACT_APP_MAX_TR_PER_DAY,
  monitorAPI: process.env.REACT_APP_MONITOR_API === 'true', // check Service/Api.js
  roles: {
    unauthorized: 'public',
    guest: 'invite',
    utilisateur: 'client',
    'utilisateur pro': 'client',
    entreprise: 'entreprise',
    livreur: 'livreur',
    artisan: 'artisan'
  },
  socialUrls: [
    { title: 'Facebook', url: 'https://www.facebook.com/boxnservices/' },
    { title: 'Instagram', url: 'https://www.instagram.com/boxnservices/' },
    { title: 'Twitter', url: 'https://twitter.com/boxNservices' },
    { title: 'YouTube', url: 'https://www.youtube.com/channel/UCsYoJhZhYCiwbIRhL3jzABQ' }
  ],
  tva: [
    { value: 4, label: `0 %`, rate: 0 },
    { value: 1, label: `5.5 %`, rate: 5.5 },
    { value: 2, label: `10 %`, rate: 10 },
    { value: 3, label: `20 %`, rate: 20 }
  ],
  orderStatuses: [
    {
      value: 0,
      color: palette.grey
    },
    {
      value: 1,
      color: palette.clearBlue
    },
    {
      value: 2,
      color: palette.red
    },
    {
      value: 3,
      color: palette.yellow
    },
    {
      value: 4,
      color: palette.clearBlue
    },
    {
      value: 5,
      color: palette.purple
    },
    {
      value: 6,
      color: palette.red
    },
    {
      value: 7,
      color: palette.green
    },
    {
      value: 8,
      color: palette.grey
    }
  ]
}

export default config
