import React, { useState, useContext, useEffect } from 'react'
import { Typography, Paper, Input, Grid, FormControl, InputAdornment, InputLabel, TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

import config from '../../config'
import { TOAST, useToasts } from '../../hooks/HookToasts'
import InputNumber from '../../hooks/InputNumber'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import Select from './Select'
dayjs.locale('fr')

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  },
  inline: {
    ...theme.common.inline
  },
  avatar: {
    ...theme.common.avatar,
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30)
  },
  submit: {
    margin: `${theme.typography.pxToRem(15)} 0`
  },
  textArea: {
    '& textarea': {
      overflow: 'hidden'
    }
  }
}))

export default function PromoCodeForm(props) {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const unit = [{ value: 0, label: '€' }, { value: 1, label: '%' }]
  const defaultState = {
    name: '',
    reduction: '',
    min_order: '',
    unit: unit[0].value,
    start: '',
    end: '',
    desc: '',
    services: [],
    service: '',
    place: []
  }
  const [values, setValues] = useState(defaultState)
  const [services, setServices] = useState([])
  const { add } = useToasts()

  useEffect(() => {
    if (userContext.getRole() === config.roles.artisan) {
      Api.getServicesByArtisan(userContext.getId()).then(res => {
        if (!res.data.data.error) {
          const newServices = res.data.data.service.map(ser => ({ label: ser.name, value: ser.id }))
          setServices([...services, ...newServices])
        }
      })
    }
  }, [])

  const handleChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  const handleChangeUpper = e => setValues({ ...values, [e.target.name]: e.target.value.toUpperCase() })

  const submit = async () => {
    if (!values.name) {
      add('Le code est obligatoire', TOAST.ERROR)
    } else if (!values.reduction) {
      add('Le montant est obligatoire', TOAST.ERROR)
    } else if (!values.start) {
      add('La date de début est obligatoire', TOAST.ERROR)
    } else if (!values.end) {
      add('La date de fin est obligatoire', TOAST.ERROR)
    } else if (userContext.getRole() === config.roles.artisan && !values.service) {
      add('Le service est obligatoire', TOAST.ERROR)
    } else if (dayjs(values.end).isBefore(values.start)) {
      add('La date de fin doit être après la date de début', TOAST.ERROR)
    } else if (dayjs(values.end).isBefore(dayjs())) {
      add('La date de fin est dans le passé', TOAST.ERROR)
    } else {
      // Remove empty properties
      let data = Object.entries({ ...values }).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
      data.is_percent = data.unit === unit[1].value

      const res = await Api.addPromoCode(userContext.getId(), data)
      if (res && res.data && res.data.success && res.data.data) {
        if (res.data.data.error) {
          add(res.data.data.error, TOAST.ERROR)
        } else {
          add('Le code promo a été créé', TOAST.SUCCESS)
          setValues(defaultState)
          props.onSuccess()
        }
      } else {
        add("Le code promo n'a pas pu être créé", TOAST.ERROR)
      }
    }
  }

  return (
    <Paper className={classes.sectionPaper}>
      <Typography>
        Les codes créés ici ne seront valables que sur les lieux et/ou les services qui vous sont rattachés. Merci de les transmettre à vos utilisateurs pour
        qu'ils puissent en bénéficier.
      </Typography>
      <form
        autoComplete="off"
        onSubmit={async e => {
          e.preventDefault()
          submit()
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink htmlFor="name">
                Code
              </InputLabel>
              <Input id="name" type="text" value={values.name} onChange={handleChangeUpper} name="name" fullWidth placeholder="Entrez votre code" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink htmlFor="reduction">
                Montant
              </InputLabel>
              <Input
                id="reduction"
                type="text"
                inputComponent={InputNumber}
                value={values.reduction}
                onChange={handleChange}
                name="reduction"
                fullWidth
                placeholder="Entrez un montant"
              />
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink htmlFor="unit">
                Unité
              </InputLabel>
              <Select onChange={handleChange} name="unit" value={values.unit} options={unit} />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink htmlFor="start">
                Date de début
              </InputLabel>
              <Input id="start" type="date" value={values.start} onChange={handleChange} name="start" fullWidth placeholder="Entrez une date de début" />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink htmlFor="end">
                Date de fin
              </InputLabel>
              <Input id="end" type="date" value={values.end} onChange={handleChange} name="end" fullWidth placeholder="Entrez une date de fin" />
            </FormControl>
          </Grid>
          {userContext.getRole() === config.roles.artisan ? (
            <>
              <Grid item xs={6}>
                <FormControl margin="none" required fullWidth>
                  <TextField
                    id="desc"
                    name="desc"
                    className={classes.textArea}
                    label="Description"
                    placeholder="Donnez une brève description de la promotion."
                    multiline
                    fullWidth={true}
                    value={values.desc}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel shrink htmlFor="service">
                    Service
                  </InputLabel>
                  <Select onChange={handleChange} name="service" options={services} value={values.service} placeholder="Choisissez un service" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel shrink htmlFor="min_order">
                    Montant minimum de commande
                  </InputLabel>
                  <Input
                    id="min_order"
                    type="text"
                    value={values.min_order}
                    onChange={handleChange}
                    name="min_order"
                    fullWidth
                    placeholder="Entrez un montant"
                    inputComponent={InputNumber}
                    endAdornment={<InputAdornment position="end">€</InputAdornment>}
                  />
                </FormControl>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel shrink htmlFor="place">
                    Lieu
                  </InputLabel>
                  <Select
                    multiple
                    onChange={handleChange}
                    name="place"
                    options={userContext.user.enterprise_places}
                    value={values.place}
                    placeholder="Choisissez un lieu"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="none" required fullWidth>
                  <TextField
                    id="desc"
                    name="desc"
                    className={classes.textArea}
                    label="Description"
                    placeholder="Donnez une brève description de la promotion."
                    multiline
                    fullWidth={true}
                    value={values.desc}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container justify="flex-end" spacing={3} className={classes.submit}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={submit}>
              Ajouter un code
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}
