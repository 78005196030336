import React, { useState, useContext, useEffect } from 'react'
import { Typography, Paper, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core'
import { CheckCircle, RemoveCircle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import isBetween from 'dayjs/plugin/isBetween'

import PromoCodeForm from '../../components/form/PromoCodeForm'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import palette from '../../theme/palette'

dayjs.locale('fr')
dayjs.extend(isBetween)

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-start'
  },
  avatar: {
    ...theme.common.avatar,
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30)
  },
  tableRow: {
    '&:nth-of-type(2n + 1)': {
      backgroundColor: palette.skyBlue
    }
  }
}))

export default function Promo() {
  const classes = useStyles()
  const [promos, setPromos] = useState([])
  const { userContext } = useContext(UserContext)

  const getPromoCodes = () =>
    Api.getArtisanPromocodes(userContext.getId()).then(res => {
      if (res.data && res.data.success) {
        setPromos(res.data.data.promocode)
      }
    })

  useEffect(() => {
    getPromoCodes()
  }, [])

  const table = (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Code</TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="center">Montant</TableCell>
          <TableCell align="center">Date</TableCell>
          <TableCell align="center">Actif</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {promos.map(promo => {
          const start = dayjs.unix(promo.start)
          const end = dayjs.unix(promo.end)
          const isActive = dayjs().isBetween(start, end)
          return (
            <TableRow className={classes.tableRow} key={promo.id}>
              <TableCell>{promo.name.toUpperCase()}</TableCell>
              <TableCell>{promo.desc}</TableCell>
              <TableCell align="center">
                {parseFloat(promo.reduction).toFixed(2)} {promo.is_percent ? '%' : '€'}
              </TableCell>
              <TableCell align="center">
                {start.format('DD/MM/YY').toLowerCase()}
                {' – '}
                {end.format('DD/MM/YY').toLowerCase()}
              </TableCell>
              <TableCell align="center">{isActive ? <CheckCircle color="primary" /> : <RemoveCircle color="secondary" />}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )

  return (
    <>
      <div className={classes.section}>
        <Typography variant="h1">Créer un code promo</Typography>
        <PromoCodeForm onSuccess={getPromoCodes} />
      </div>

      <div className={classes.section}>
        <Typography variant="h1">Mes codes promo</Typography>

        {promos.length > 0 ? (
          <Paper className={classes.sectionPaper}>{table}</Paper>
        ) : (
          <Typography className={classes.noResults} variant="h4">
            Aucun code pour le moment.
          </Typography>
        )}
      </div>
    </>
  )
}
