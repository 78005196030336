import React, { useContext, useState } from 'react'
import CancelOrderDialog from '../dialog/CancelOrderDialog'
import { ButtonBase, Typography, Paper, IconButton, Tooltip } from '@material-ui/core'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import palette from '../../theme/palette'
import { withRouter } from 'react-router-dom'
import config from '../../config'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { isMobile } from 'react-device-detect'
import { UserContext } from '../../stores/UserProvider'
import Hour from '../misc/Hour'

dayjs.locale('fr')

const useStyles = makeStyles(theme => ({
  order: {
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'nowrap',
    marginBottom: '1.75rem',
    position: 'relative',
    '&:last-child': {
      marginBottom: 0
    }
  },
  teaserPart1: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  cell: {
    padding: '1.063rem 1.75rem',
    borderRight: '0.063rem solid #e7ecf2',
    [theme.breakpoints.down('xs')]: {
      borderRight: '0',
      margin: '0 auto',
      textAlign: 'center',
      padding: '1rem'
    }
  },
  orderService: {
    textAlign: 'center',
    width: '11rem',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0
    }
  },
  icon: {
    marginBottom: '0.625rem',
    display: 'block',
    margin: '0 auto',
    '& svg': {
      maxWidth: '2.875rem',
      height: 'auto'
    }
  },
  orderInfos: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderBottom: '0.063rem solid #e7ecf2',
      paddingTop: 0
    }
  },
  orderPickup: {
    flexGrow: 1
  },
  orderStatus: {
    width: '10rem',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      borderRight: '0.063rem solid #e7ecf2'
    }
  },
  orderStatusCircle: {
    backgroundColor: palette.dark,
    width: 8,
    height: 8,
    display: 'inline-block',
    borderRadius: 4,
    marginRight: '0.625rem',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: '0 auto 0.625rem auto'
    }
  },
  orderMessages: {
    borderRight: 0,
    width: '6rem',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'stretch'
  },
  orderActions: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '0.063rem solid #e7ecf2',
    [theme.breakpoints.down('xs')]: {
      borderLeft: 0,
      position: 'absolute',
      top: 0,
      right: 0,
      flexDirection: 'column',
      padding: '0.75rem'
    }
  },
  xsCellCol: {
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0 auto',
      width: '50%'
    }
  },
  bold: {
    ...theme.common.bold
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(10)
  }
}))

function getStatus(code) {
  return config.orderStatuses.find(orderStatus => orderStatus.value === code)
}

function renderTeaser(order, classes, ctx) {
  const status = getStatus(order.status.id)
  const messagesUnread = parseInt(order.messages) >= 1
  const role = ctx.getRole() === config.roles.utilisateur ? config.roles.utilisateur : config.roles.artisan

  return (
    <>
      <div className={classNames(classes.cell, classes.orderService)}>
        {order.service.img && <img className={classes.icon} src={order.service.img} alt="" />}
        <Typography variant="h3">{order.service.name}</Typography>
        {ctx.getRole() === config.roles.artisan && (
          <>
            <Typography variant="body2" className={classes.bold}>
              {order.client.name}
            </Typography>
            {order.client.phone && <Typography variant="body2">Tel:&nbsp;{order.client.phone}</Typography>}
          </>
        )}
        <Typography variant="body2">
          {dayjs
            .unix(order.created_at)
            .format('DD MMMM YYYY')
            .toLowerCase()}
        </Typography>
      </div>
      <div className={classNames(classes.cell, classes.orderInfos)}>
        <Typography variant="body2">{order.artisan.name}</Typography>
        <Typography variant="body2">{order.place.name}</Typography>
        <Typography variant="body2">Ref {order.reference}</Typography>
        {order.date_delivery_start && (
          <Typography variant="body2" className={classes.marginTop}>
            Créneau choisi : le <Hour withYear date={order.date_delivery_start} end={order.date_delivery_end} />
          </Typography>
        )}
        {ctx.getRole() === config.roles.livreur && (
          <Typography variant="body2" className={classes.bold}>
            {order.client.name}
          </Typography>
        )}
      </div>
      <div className={classNames(classes.cell, classes.xsCellCol, classes.orderStatus)}>
        {isMobile || <Typography variant="h3">Statut</Typography>}
        {status && (
          <Typography variant="body2">
            <span className={classes.orderStatusCircle} style={{ backgroundColor: status.color }} />
            {order.status.label}
          </Typography>
        )}
      </div>
      <div
        className={classNames(classes.cell, classes.xsCellCol, classes.orderMessages, {
          [classes.unread]: order.messages[role]
        })}
      >
        <Typography variant="h3" align="center" style={{ color: messagesUnread ? palette.red : palette.dark }}>
          {order.messages[role]}
        </Typography>
        <Typography variant="body2" align="center" style={{ color: messagesUnread ? palette.red : palette.dark }}>
          {messagesUnread ? 'Message(s) non lu(s)' : 'Message non lu'}
        </Typography>
      </div>
    </>
  )
}

const OrderTeaser = props => {
  const classes = useStyles()
  const { history, order, withLinks } = props
  const { userContext } = useContext(UserContext)
  const role = userContext.getRole()
  const [openDialog, setOpenDialog] = useState(false)

  const dismissNotifAndOpenOrder = () => {
    history.push(`/${role}/commandes/${order.id}`)
    const notifs = userContext.notifs.filter(notif => notif.type === 'messages')
    const toMarkAsRead = notifs.filter(notif => notif.type_id === order.id)
    if (toMarkAsRead.length > 0) {
      for (const notif of toMarkAsRead) {
        userContext.setNotificationAsViewed(notif.id)
      }
    }
  }

  return (
    <Paper className={classes.order}>
      {withLinks ? (
        <ButtonBase className={classes.teaserPart1} onClick={dismissNotifAndOpenOrder}>
          {renderTeaser(order, classes, userContext)}
        </ButtonBase>
      ) : (
        <div className={classes.teaserPart1}>{renderTeaser(order, classes, userContext)}</div>
      )}

      {role === config.roles.artisan && (
        <div className={classNames(classes.cell, classes.orderActions)}>
          {withLinks && (
            <Tooltip title="Editer la commande" aria-label="Editer la commande">
              <IconButton onClick={dismissNotifAndOpenOrder}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Annuler la commande" aria-label="Annuler la commande">
            <IconButton onClick={() => setOpenDialog(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <CancelOrderDialog order={order} onClose={() => setOpenDialog(false)} openDialog={openDialog} />
        </div>
      )}
    </Paper>
  )
}

export default withRouter(OrderTeaser)
