import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, createStyles } from '@material-ui/core'
import FullCenteredLayout from '../../components/layout/FullCenteredLayout'
import StaticPage from '../common/StaticPage'

const styles = createStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginPaper: {
    padding: '30px 45px',
    maxWidth: '460px'
  },
  flexContainer: {
    margin: '25px 0 50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '10px 0 30px'
    }
  }
}))

class Cgv extends Component {
  componentDidMount() {
    document.title = "box'n services – Conditions Générales d'Utilisation"
    // ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    return (
      <FullCenteredLayout title="CGU">
        <StaticPage />
      </FullCenteredLayout>
    )
  }
}

export default withRouter(withStyles(styles)(Cgv))
