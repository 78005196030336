import React, { Component, createContext } from 'react'
import { withToastManager } from 'react-toast-notifications'
import { TOAST } from '..//hooks/HookToasts'
import Api from '../services/Api'
import { isEmailValid } from '../services/formUtils'
import { withUser } from './UserProvider'
import { getRoleRoute } from '../services/helpers'

export const AccountFormContext = createContext({})

class AccountFormProvider extends Component {
  constructor(props) {
    const {
      userContext: { user }
    } = props
    super(props)
    this.state = {
      isLoading: false,
      lastname: user.lastname,
      firstname: user.firstname,
      phone: user.phone,
      phone2: user.phone2 || '',
      email: user.email,
      password: '',
      img: user.img ? user.img : null,
      imgChanged: false,
      department: (user.department && user.department.value) || '',
      city: (user.city && user.city.value) || '',
      place: (user.place && user.place.value) || '',
      cgu: user.firstname.length > 0,
      pro_parent_id: user.ppid || null,
      departments: [],
      cities: [],
      places: [],
      isPublic: false,
      role: user.role,
      submit: async e => {
        e.preventDefault()
        const { lastname, firstname, place, email, phone, phone2, img, imgChanged } = this.state
        const { userContext, toastManager } = this.props
        const errors = []

        if (!email || !firstname || !lastname || !phone) {
          errors.push('Certains champs sont obligatoires')
        }

        if (!isEmailValid(email)) {
          errors.push("L'email est invalide")
        }

        if (errors.length > 0) {
          toastManager.add(errors.join('\n'), { appearance: TOAST.ERROR })
          return false
        } else {
          this.setState({ isLoading: true })

          let userData = {
            lastname: lastname,
            firstname: firstname,
            phone: phone,
            phone2: phone2,
            email: email,
            client_place_id: place || undefined,
            img: img,
            img_changed: imgChanged
          }

          const res = await userContext.update(userData)
          if (res.data.data.error) {
            toastManager.add('Une erreur a eu lieu', { appearance: TOAST.ERROR })
          }
          this.setState({ isLoading: false })
        }
      },
      handleChange: (e, i) => {
        this.setState({ [e.target.name]: e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value })
      },
      handleSelect: (name, value) => {
        if ('department' === name) {
          this.setState({ cities: [], city: '', place: '', places: [] })

          if ('' !== value) {
            this.updateCities(value)
          }
        }

        if (name === 'city' && value !== '') {
          this.setState({ place: '' })
          this.updatePlaces(value)
        }
        this.setState({
          [name]: value
        })
      },
      handleImg: value => {
        this.setState(state => ({
          img: value,
          imgChanged: true
        }))
      },
      handlePublic: () => {
        const { isPublic, city } = this.state
        this.setState({ isPublic: !isPublic }, () => {
          if (city) {
            this.updatePlaces(city)
          }
        })
      }
    }
  }

  componentDidMount = () => {
    const {
      userContext: { user }
    } = this.props
    // get departments, places and cities
    if (getRoleRoute(user.role) === 'client') {
      Api.getDepartments().then(res => {
        this.setState({ departments: res.data.data.map(department => ({ label: department.name, value: department.id })) })
      })

      Api.getCities(null, { department: this.state.department }).then(res => {
        const cities = res.data.data.city.map(city => ({ value: city.id, label: city.name }))
        let state = { cities: cities }
        if (user.city) {
          state.city = user.city.value
          this.updatePlaces(state.city)
        }

        this.setState(state)
      })
    }
  }

  updateCities = department => {
    Api.getCities(null, { department }).then(res => {
      this.setState({ cities: res.data.data.city.map(city => ({ label: city.name, value: city.id })) })
    })
  }

  updatePlaces = cityId => {
    const {
      userContext: { user }
    } = this.props
    const { isPublic, pro_parent_id } = this.state

    Api.getPlaces(cityId, isPublic, pro_parent_id).then(res => {
      if (res.data && res.data.data.error) {
        console.error(res.data.data.error)
        return
      }
      const places = res.data.data.place.map(place => ({ value: place.id, label: place.name }))
      let state = { places: places }
      if (user.city && this.state.city === user.city.value) {
        state.place = user.place.value
      }

      this.setState(state)
    })
  }

  render = () => <AccountFormContext.Provider value={{ accountFormContext: this.state }}>{this.props.children}</AccountFormContext.Provider>
}

export const withAccountForm = Component => props => <AccountFormContext.Consumer>{store => <Component {...props} {...store} />}</AccountFormContext.Consumer>

export default withToastManager(withUser(AccountFormProvider))
