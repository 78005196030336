import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withToastManager } from 'react-toast-notifications'
import LinkButton from '../../components/buttons/LinkButton'
import InfoDialog from '../../components/dialog/InfoDialog'
import {
  Button,
  withStyles,
  createStyles,
  CircularProgress,
  FormControlLabel,
  Grow,
  FormControl,
  Collapse,
  Checkbox,
  Paper,
  Input,
  InputLabel,
  Typography
} from '@material-ui/core'
import FullCenteredLayout from '../../components/layout/FullCenteredLayout'
import { withUser } from '../../stores/UserProvider'
import PasswordInput from '../../components/form/PasswordInput'
import { isEmailValid } from '../../services/formUtils'
import { TOAST, LOGIN_MESSAGE } from '../../hooks/HookToasts'

const styles = createStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginPaper: {
    padding: '30px 45px',
    maxWidth: '460px'
  },
  flexContainer: {
    margin: '25px 0 50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '10px 0 30px'
    }
  }
}))

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      remember: false,
      email: '',
      password: '',
      isLoading: false,
      forgot: false,
      from: null,
      infoDialog: {
        open: false,
        title: '',
        content: ''
      }
    }
  }

  componentDidMount() {
    document.title = "box'n services – Connexion"
    const { isnew = null, from = null } = this.props.location.state ? this.props.location.state : {}

    const infoDialog = isnew
      ? {
          open: true,
          title: 'Inscription en cours.',
          content: 'Vous allez recevoir par mail un lien afin de confirmer votre inscription. Merci de cliquer dessus pour activer votre compte !'
        }
      : this.state.infoDialog

    this.setState({
      infoDialog,
      from
    })
  }

  handleChange = (e, i) => {
    this.setState({ [e.target.name]: e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value })
  }

  handleCloseInfoDialog = () => {
    this.setState(state => ({
      infoDialog: {
        ...state.infoDialog,
        open: false
      }
    }))
  }

  toggleForgot = () => {
    if (this.state.isLoading) return
    this.setState(state => ({
      forgot: !state.forgot
    }))
  }

  handleSubmit = async () => {
    if (this.state.forgot) {
      return this.reinitPassword()
    } else {
      return this.login()
    }
  }

  login = async () => {
    const { email, password, remember } = this.state
    const { toastManager } = this.props

    if (!email || !password) {
      toastManager.add('Les 2 champs sont obligatoires', { appearance: TOAST.ERROR })
      return
    }

    if (!isEmailValid(email)) {
      toastManager.add("L'email renseigné est invalide", { appearance: TOAST.ERROR })
      return
    }

    this.setState({ isLoading: true })
    try {
      const res = await this.props.userContext.login(email, password, remember)
      // const fromTarget = from && from.pathname.split('/')
      // const sameDomain = from && from.pathname.split('/')[1] === res.route

      if (res.status === 200) {
        this.setState(
          state => ({ isLoading: false }),
          async () => {
            this.props.history.replace(res.route)
          }
        )
      } else {
        this.setState({ isLoading: false })
        toastManager.add(res.status, { appearance: TOAST.ERROR })
      }
    } catch (error) {
      toastManager.add(error, { appearance: TOAST.ERROR })
      this.setState({ isLoading: false })
    }
  }

  reinitPassword = async () => {
    const { email } = this.state
    const { toastManager } = this.props
    if (!email) {
      toastManager.add('Veuillez renseigner un email.', { appearance: TOAST.ERROR })
      return
    }
    this.setState({ isLoading: true })
    const res = await this.props.userContext.forgotPassword(email)
    if (res.status === 200) {
      this.setState({
        isLoading: false,
        forgot: false,
        infoDialog: {
          open: true,
          title: 'Réinitialisation du mot de passe.',
          content: 'Un lien pour générer un nouveau mot de passe vient de vous être envoyé par mail.'
        }
      })
    } else {
      toastManager.add(LOGIN_MESSAGE[res.status], { appearance: TOAST.ERROR })
      this.setState({ isLoading: false })
    }
  }

  render() {
    const { classes } = this.props
    const { forgot, email, password, remember, infoDialog } = this.state

    return (
      <FullCenteredLayout title="Connexion">
        <Paper className={classes.loginPaper}>
          <form
            autoComplete="off"
            onSubmit={async e => {
              e.preventDefault()
              await this.handleSubmit()
            }}
          >
            <Collapse in={forgot}>
              <Typography>
                Si vous avez oublié votre mot de passe, saisissez votre adresse email ci-dessous. Un email vous sera envoyé et vous pourrez en définir un
                nouveau.
              </Typography>
            </Collapse>

            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink htmlFor="email">
                Email
              </InputLabel>
              <Input id="email" type="email" value={email} onChange={this.handleChange} name="email" fullWidth placeholder="Entrez votre email" />
            </FormControl>

            <Collapse in={!forgot}>
              <PasswordInput
                required
                fullWidth
                margin="normal"
                value={password}
                name="password"
                label="Mot de passe"
                placeholder="Entrez votre mot de passe"
                onChange={this.handleChange}
              />
            </Collapse>

            <div className={classes.flexContainer}>
              <Grow in={!forgot}>
                <FormControlLabel
                  label="Se souvenir de moi"
                  control={<Checkbox checked={remember} value="remember" onChange={this.handleChange} name="remember" aria-label="A" />}
                />
              </Grow>
              <LinkButton to="#" onClick={this.toggleForgot}>
                {forgot ? 'Annuler' : 'Mot de passe oublié ?'}
              </LinkButton>
            </div>

            <div>
              <Collapse in={!forgot}>
                <div>
                  <Button
                    style={{ marginBottom: '12px' }}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleSubmit}
                  >
                    {this.state.isLoading ? <CircularProgress size="24px" color="primary" /> : 'Connexion'}
                  </Button>
                  <Button variant="contained" size="large" color="primary" fullWidth to="/inscription" component={Link}>
                    Inscription
                  </Button>
                </div>
              </Collapse>

              <Collapse in={forgot}>
                <div>
                  <Button style={{ marginBottom: '12px' }} fullWidth size="large" variant="contained" color="secondary" onClick={this.handleSubmit}>
                    {this.state.isLoading ? <CircularProgress size="24px" color="primary" /> : 'Réinitialiser'}
                  </Button>
                </div>
              </Collapse>
            </div>
          </form>
        </Paper>
        <InfoDialog title={infoDialog.title} content={infoDialog.content} openDialog={infoDialog.open} onClose={this.handleCloseInfoDialog} />
      </FullCenteredLayout>
    )
  }
}

export default withToastManager(withRouter(withUser(withStyles(styles)(Login))))
