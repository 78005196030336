import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { menu } from './routes'
import NoMatch from '../NoMatch'
import SidebarLayout from '../../components/layout/SidebarLayout'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.common.main
  },
  container: {
    ...theme.common.container
  }
}))
const Enterprise = props => {
  const classes = useStyles()

  return (
    <div>
      <SidebarLayout menu={menu}>
        <div className={classes.container}>
          <Switch>
            {menu.map(item => (
              <Route
                key={`${item.path}`}
                exact={item.exact}
                path={item.path}
                render={props => {
                  const Screen = item.component
                  document.title = `box'n services - ${item.label}`
                  return <Screen {...props} />
                }}
              />
            ))}
            <Route render={() => <NoMatch />} />
          </Switch>
        </div>
      </SidebarLayout>
    </div>
  )
}

export default Enterprise
