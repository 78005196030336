import React, { useContext, useState } from 'react'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Input
} from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import { OrderContext } from '../../stores/OrderProvider'
import Api from '../../services/Api'
import { radioOptions } from '../../config/cancellationCauses'
import { TOAST, useToasts } from '../../hooks/HookToasts'

const useStyles = makeStyles(theme => ({
  noMargin: {
    margin: 0
  },
  centerBtn: {
    margin: '0 auto'
  },
  textArea: {
    '& textarea': {
      overflow: 'hidden'
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(25)
  }
}))

const CancelOrderDialog = props => {
  const classes = useStyles()
  const { order, openDialog, onClose } = props
  const [stepDialog, setStepDialog] = useState(1)
  const [selectedOptionValue, setSelectedOptionValue] = useState('0')
  const [otherCause, setOtherCause] = useState('')
  const { add } = useToasts()
  const { userContext } = useContext(UserContext)
  const { orderContext } = useContext(OrderContext)

  const cancelOrder = () => {
    const cancellationCause = selectedOptionValue === 'other' ? otherCause : radioOptions[parseInt(selectedOptionValue)]
    Api.cancelOrder({
      order_id: order.id,
      user_id: userContext.getToken(),
      cancellation_cause: cancellationCause
    }).then(res => {
      if (res.data.success === true && res.data.data.order) {
        add('La commande a été annulée.', TOAST.SUCCESS)
        if (orderContext.order.id) {
          orderContext.updateStraight(res.data.data.order)
        } else {
          window.location.reload()
        }
      } else {
        add("La commande n'a pas pu être annulée.", TOAST.ERROR)
      }
      props.onClose()
    })
  }

  return (
    <>
      <Dialog fullWidth={true} open={openDialog} onClose={() => props.onClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Annuler la commande</DialogTitle>
        <DialogContent>
          {stepDialog === 1 && (
            <>
              <Typography variant="h3">Voulez-vous annuler cette commande ?</Typography>
              <Typography variant="body2">{order.service.name}</Typography>
              <Typography variant="body2">Ref {order.reference}</Typography>
              <Typography variant="body2">{order.client.name}</Typography>
            </>
          )}

          {stepDialog === 2 && (
            <>
              <Typography variant="h3">Pourquoi voulez-vous annuler la commande {order.reference} ?</Typography>
              <RadioGroup
                aria-label="Raison de l'annulation"
                name="cancellation-cause"
                value={selectedOptionValue}
                onChange={event => setSelectedOptionValue(event.target.value)}
              >
                {radioOptions.map((option, index) => {
                  return <FormControlLabel key={`cause-${index}`} value={`${index}`} control={<Radio />} label={option} />
                })}

                <FormControlLabel value="other" control={<Radio />} label="Autre" />
              </RadioGroup>

              {selectedOptionValue === 'other' && (
                <FormControl margin="none" required fullWidth>
                  <Input
                    className={classes.textArea}
                    label="Autre raison"
                    placeholder="Veuillez décrire en quelques mots la raison d'annulation de la commande."
                    fullWidth={true}
                    value={otherCause}
                    onChange={e => setOtherCause(e.target.value)}
                  />
                </FormControl>
              )}
            </>
          )}

          <DialogActions className={classNames(classes.noMargin, classes.dialogActions)}>
            {stepDialog === 1 && (
              <>
                <Button className={classes.noMargin} variant="contained" size="large" color="primary" onClick={onClose}>
                  Non
                </Button>
                <Button className={classes.noMargin} variant="contained" size="large" color="secondary" onClick={() => setStepDialog(2)}>
                  Oui
                </Button>
              </>
            )}
            {stepDialog === 2 && (
              <>
                <Button className={classes.centerBtn} variant="contained" size="large" color="secondary" onClick={() => cancelOrder()}>
                  Oui
                </Button>
              </>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CancelOrderDialog
