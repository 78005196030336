import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, FormControl, TextField, CircularProgress, Button, List, ListItem, Typography } from '@material-ui/core'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import { TOAST, useToasts } from '../../hooks/HookToasts'
import moment from 'moment'
import 'moment/locale/fr'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper,
    marginTop: theme.typography.pxToRem(28)
  },
  textArea: {
    '& textarea': {
      overflow: 'hidden'
    }
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  note: {
    display: 'block'
  },
  submit: {
    marginLeft: theme.typography.pxToRem(10),
    marginBottom: theme.typography.pxToRem(10)
  }
}))

const PersonalNote = props => {
  const classes = useStyles()
  const { order } = props
  const [isSaving, setIsSaving] = useState(false)
  const { add } = useToasts()
  const [notes, setNotes] = useState([])
  const [note, setNote] = useState('')
  const { userContext } = useContext(UserContext)

  useEffect(() => {
    const load = async () => {
      await loadNotes()
    }
    load()
  }, [])

  const loadNotes = async () => {
    try {
      const res = await Api.getNotes(order.id)
      if (res.data.success && res.data.data.notes) {
        setNotes(res.data.data.notes)
      }
    } catch (error) {
      add("Les notes n'ont pas pu être récupérées.", TOAST.ERROR)
      console.error(error)
    }
  }

  function saveNote(e) {
    e.preventDefault()
    setIsSaving(true)

    Api.setNote(order.id, userContext.getId(), note).then(res => {
      setIsSaving(false)
      if (res.data.success) {
        add('La note a été sauvegardée.', TOAST.SUCCESS)
        loadNotes()
        setNote('')
      } else {
        add("La note n'a pas pu être sauvegardée.", TOAST.ERROR)
      }
    })
  }

  return (
    <Paper className={classes.sectionPaper}>
      <Typography variant="h3">Notes</Typography>
      {notes.length > 0 && (
        <List>
          {notes.map(note => (
            <ListItem disableGutters className={classes.note}>
              <Typography variant="body">{note.text}</Typography>
              <Typography variant="body2">Le {moment.unix(note.created_at).format('DD MMMM YYYY')}</Typography>
            </ListItem>
          ))}
        </List>
      )}
      <form onSubmit={saveNote}>
        <div className={classes.inline}>
          <FormControl margin="none" required fullWidth>
            <TextField
              className={classes.textArea}
              label="Note Personnelle"
              placeholder="Écrivez vos notes ici, elles seront uniquement visibles par vous."
              multiline
              fullWidth={true}
              value={note}
              onChange={e => setNote(e.target.value)}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <Button className={classes.submit} variant="contained" size="large" color="primary" onClick={saveNote}>
            {isSaving ? <CircularProgress size="24px" color="primary" /> : 'Enregistrer'}
          </Button>
        </div>
      </form>
    </Paper>
  )
}

export default PersonalNote
