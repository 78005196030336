import React, { useState } from 'react'
import classNames from 'classnames'
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import { CheckCircle, Error, Info, Close, Warning } from '@material-ui/icons'
import toastStyles from '../theme/styles/toast'

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info
}
/**
 * This component is a replacement one for the ToastProvider.
 *
 * @author Matière Noire - Matthieu
 * @version 1.0
 */
export default function SnackbarToast(props) {
  const classes = toastStyles()
  const [openSnackBar, setOpenSnackBar] = useState(true)

  // Extra action can be passed as callback, mostly like an action button to Undo, to go to some other page, etc.
  const { className, ...other } = props
  const action = props.action || []

  const onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackBar(false)
  }

  const Icon = variantIcon[props.appearance]
  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={onClose} open={openSnackBar} autoHideDuration={5000}>
      <SnackbarContent
        className={classNames(classes[props.appearance], classes.content)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {props.children}
          </span>
        }
        action={[
          ...action,
          <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
            <Close className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    </Snackbar>
  )
}
