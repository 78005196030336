import { makeStyles } from '@material-ui/styles'

const toastStyles = makeStyles(
  theme => ({
    success: {
      background: '#43a047'
    },
    error: {
      background: '#ff5d4d'
    },
    info: {
      background: 'rgb(23, 105, 170)'
    },
    warning: {
      background: '#ffa000'
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1)
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      color: '#fff'
    },
    content: {
      flexWrap: 'nowrap'
    },
    close: {
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.4)'
      }
    }
  }),
  { name: 'HookToast', index: 2 }
)

export default toastStyles
