import Home from './Home'
import Account from '../common/Account'
import Contact from '../common/Contact'
import Orders from './Orders'
import Order from '../common/Order'
import Messages from '../common/Messages'
import StaticPage from '../common/StaticPage'
import Promo from './Promo'
import Sponsor from './Sponsor'
import Process from './Process'
import Faq from '../common/Faq'

export const menu = (placeId) => [
  {
    label: 'Accueil',
    path: '/client',
    icon: 'Home',
    exact: true,
    component: Home
  },
  {
    label: 'Mes commandes',
    path: '/client/commandes',
    icon: 'Orders',
    exact: true,
    component: Orders
  },
  {
    label: 'Commandes',
    path: '/client/commandes/nouveau',
    icon: 'Orders',
    hideInMenu: true,
    exact: true,
    component: Process
  },
  {
    label: 'Commandes',
    path: '/client/commandes/:id',
    icon: 'Orders',
    hideInMenu: true,
    exact: true,
    component: Order
  },
  {
    label: 'Messagerie',
    path: '/client/messages',
    icon: 'Messages',
    component: Messages,
    exact: true,
    isLast: true
  },
  {
    label: 'Messagerie',
    path: '/client/messages/:id',
    icon: 'Messages',
    component: Messages,
    exact: true,
    hideInMenu: true,
    isLast: true
  },

  {
    label: 'Parrainage',
    path: '/client/parrainages',
    icon: 'Sponsor',
    exact: true,
    component: Sponsor
  },
  {
    label: 'Promotions',
    path: '/client/promotions',
    icon: 'Cupons',
    component: Promo,
    exact: true,
    isLast: true
  },
  {
    label: 'Foire aux questions',
    path: '/client/faq',
    icon: 'Faq',
    component: Faq,
    exact: true
  },
  {
    label: 'Contact',
    path: '/client/contact',
    icon: 'Contact',
    exact: true,
    component: Contact
  },
  {
    label: 'Mon Compte',
    path: '/client/compte',
    icon: null,
    hideInMenu: true,
    exact: true,
    component: Account
  },
  {
    label: 'Mes préférences',
    path: '/client/compte',
    icon: null,
    hideInMenu: true,
    exact: true,
    component: Account
  },
  {
    label: 'CGU',
    path: `/client/cgu/:placeId`,
    icon: 'Document',
    exact: true,
    params: {placeId },
    component: StaticPage
  }
]
