import React, { createContext, Component } from 'react'
import { withUser } from './UserProvider'
import Api from '../services/Api'

export const OrderContext = createContext({})

class OrderProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: props.order || {},
      isLoading: true,
      update: async data => {
        const d = { ...data, role: this.props.userContext.getRole() }
        const res = await Api.updateOrder(this.state.order.id, d)
        if (res.data.success === true && res.data.data.order) {
          this.setState(state => ({ ...state, order: res.data.data.order, isLoading: false }))
          return true
        } else {
          return false
        }
      },
      updateStraight: od => {
        this.setState(state => ({ order: od, isLoading: false }))
      },
      isPaid: () => {
        return this.state.order.paid === 1 || this.state.order.paid === 2
      },
      setLoading: () => {
        this.setState({ isLoading: true })
      }
    }
  }

  render = () => <OrderContext.Provider value={{ orderContext: this.state }}>{this.props.children}</OrderContext.Provider>
}

export const withOrder = Component => props => <OrderContext.Consumer>{store => <Component {...props} {...store} />}</OrderContext.Consumer>

export default withUser(OrderProvider)
