import React from 'react'
import { ButtonBase, Paper, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import palette from '../../theme/palette'

const useStyles = makeStyles(theme => ({
  btn: {
    width: '100%',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  btnDisabled: {
    '& svg': {
      opacity: 0.3
    },
    '& p': {
      opacity: 0.3
    }
  },
  root: {
    width: '100%',
    padding: 20,
    height: 216,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inline: {
    height: 'auto',
    marginBottom: 20
  },
  icon: {
    display: 'block',
    margin: '0 auto 10px'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: '700',
    letterSpacing: 1.33,
    position: 'relative',
    '&:before': {
      content: '""',
      height: 1,
      backgroundColor: palette.red,
      left: 0,
      right: 0,
      bottom: -2,
      position: 'absolute',
      transform: 'scale(0,1)',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  titleActive: {
    color: palette.red,
    '&:before': {
      transform: 'scale(1,1)'
    }
  }
}))

const ServiceTeaser = props => {
  const classes = useStyles()
  const { disabled, service, active, onClick, inline } = props
  return (
    <ButtonBase
      disabled={disabled}
      disableTouchRipple
      classes={{
        root: classes.btn,
        disabled: classes.btnDisabled
      }}
      key={service.name}
      focusRipple
      focusVisibleClassName={classes.titleActive}
      onClick={onClick}
    >
      <Paper
        className={classNames(classes.root, {
          [classes.inline]: inline
        })}
      >
        {service.img && <img className={classes.icon} src={service.img} alt="" />}
        <Typography
          align="center"
          className={classNames(classes.title, {
            [classes.titleActive]: active
          })}
        >
          {service.name}
        </Typography>
      </Paper>
    </ButtonBase>
  )
}

export default ServiceTeaser
