import React, { useState, useRef, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Badge,
  ButtonBase,
  Grow,
  ClickAwayListener,
  List,
  ListItem,
  Paper,
  Popper,
  Typography,
  IconButton,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close } from '@material-ui/icons'
import { UserContext } from '../../stores/UserProvider'
import { ReactComponent as Notif } from '../../assets/icons/notifications.svg'
const useStyles = makeStyles(theme => ({
  badge: {
    minWidth: theme.typography.pxToRem(14),
    height: theme.typography.pxToRem(14),
    padding: '0 2px',
    borderRadius: 8,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    fontSize: '0.625rem',
    fontWeight: 700,
    letterSpacing: '0.019rem',
    top: theme.typography.pxToRem(1),
    right: theme.typography.pxToRem(1)
  },
  popper: {
    zIndex: 2
  },
  menu: {
    border: '1px solid #eff3f8',
    borderTop: 0,
    width: theme.typography.pxToRem(200),
    maxHeight: theme.typography.pxToRem(300),
    overflow: 'auto'
  },
  notification: {
    padding: theme.typography.pxToRem(20)
  },
  text: {
    paddingRight: theme.typography.pxToRem(20)
  },
  deleteAll: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
    borderBottom: '1px solid #eff3f8',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

const Notifications = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)
  const count = userContext.getNotifsCount()

  function handleToggle() {
    setOpen(!open)
  }

  function handleClose(event) {
    if (anchorEl.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonBase
        disableRipple
        className={props.className}
        aria-label={`${count} notifications`}
        buttonRef={anchorEl}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge classes={{ badge: classes.badge }} badgeContent={count} color="secondary">
          <Notif />
        </Badge>
      </ButtonBase>
      <Popper open={open} anchorEl={anchorEl.current} className={classes.popper} transition placement="bottom-end">
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} id="menu-list-grow" style={{ transformOrigin: 'center top' }}>
            <Paper elevation={2} square className={classes.menu}>
              <ClickAwayListener onClickAway={handleClose}>
                {count ? (
                  <>
                    <Typography
                      onClick={e => {
                        handleClose(e)
                        userContext.setAllNotificationsAsViewed()
                      }}
                      className={classes.deleteAll}
                    >
                      Marquer toutes les notifications comme lues
                    </Typography>
                    <List disablePadding>
                      {userContext.notifs.map((notif, index) => (
                        <ListItem
                          className={classes.notification}
                          divider={index !== count - 1}
                          dense
                          key={notif.id}
                          button
                          onClick={e => {
                            handleClose(e)
                            userContext.setNotificationAsViewed(notif.id)
                            props.history.push(`/${userContext.getRole()}/${notif.type}/${notif.type_id}`, { notif: true })
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography className={classes.text} variant="body2">
                                {notif.message}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Effacer la notification" onClick={() => userContext.setNotificationAsViewed(notif.id)}>
                              <Close />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : (
                  <Typography className={classes.notification}>Aucune notification, vous êtes à jour !</Typography>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default withRouter(Notifications)
