import React, { useEffect, useContext } from 'react'
import { IconButton, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as BackIcon } from '../../assets/icons/leftarrow.svg'
import OrderTeaser from '../../components/entities/OrderTeaser'
import MessageLast from '../../components/entities/MessageLast'
import PersonalNote from '../../components/entities/PersonalNote'
import OrderModulesList from '../../components/order/OrderModulesList'
import Api from '../../services/Api'
import { OrderContext } from '../../stores/OrderProvider'
import { UserContext } from '../../stores/UserProvider'
import config from '../../config'
import PlaceInfos from '../../components/entities/PlaceInfos'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  }
}))

const Order = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const { orderContext } = useContext(OrderContext)
  const role = userContext.getRole()

  useEffect(() => {
    orderContext.setLoading()
    if (props.match.params.id) {
      fetchOrder()
    }
  }, [props.match.params.id])

  useEffect(() => {
    //if there is an order and user is either artisan or client we check if user can see the order
    if (orderContext.order.id === props.match.params.id) {
      const order = orderContext.order
      if ([config.roles.utilisateur, config.roles.artisan].includes(role)) {
        if ([order.artisan.id, order.client.id].indexOf(userContext.getId()) === -1) {
          props.history.push(`/${role}/commandes`)
        }
      }
    }
  }, [orderContext])

  const fetchOrder = () => {
    Api.getOrder(props.match.params.id, { role: userContext.getRole() }).then(res => {
      if (!res.data.error) {
        orderContext.updateStraight(res.data.data)
        Api.setMessagesRead(res.data.data.id, userContext.getId())
      }
    })
  }

  if (orderContext.isLoading) {
    return <CircularProgress size="24px" color="primary" />
  }

  return (
    <div className={classes.section}>
      <Typography variant="h1">
        <IconButton onClick={() => props.history.push(`/${role}/commandes`)}>
          <BackIcon />
        </IconButton>
        Détail de la commande {orderContext.order.reference}
      </Typography>
      <OrderTeaser order={orderContext.order} />
      {userContext.getRole() !== config.roles.livreur && <MessageLast order={orderContext.order} />}
      <OrderModulesList />
      {(userContext.getRole() === config.roles.artisan || userContext.getRole() === config.roles.livreur) && <PlaceInfos order={orderContext.order} />}
      {userContext.getRole() === config.roles.artisan && <PersonalNote order={orderContext.order} />}
    </div>
  )
}

export default Order
