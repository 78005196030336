import React from 'react'
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  noMargin: {
    margin: 0
  },
  centerBtn: {
    margin: '0 auto'
  },
  textArea: {
    '& textarea': {
      overflow: 'hidden'
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(25),
    paddingLeft: 0
  }
}))

const InfoDialog = props => {
  const classes = useStyles()
  const { title, content, openDialog, onClose } = props

  return (
    <>
      <Dialog fullWidth={true} open={openDialog} onClose={() => props.onClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>
          <DialogActions className={classNames(classes.noMargin, classes.dialogActions)}>
            <Button className={classes.noMargin} variant="contained" size="large" color="primary" onClick={onClose}>
              Compris
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default InfoDialog
