import React from 'react'
import palette from '../../../theme/palette'

const Faq = ({ color = palette.primary }) => (
  <svg width={19} height={19} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="M9.5 0C4.261 0 0 4.261 0 9.5S4.261 19 9.5 19 19 14.739 19 9.5 14.739 0 9.5 0zm0 18.27C4.665 18.27.73 14.334.73 9.5.73 4.665 4.666.73 9.5.73c4.835 0 8.77 3.935 8.77 8.77 0 4.835-3.935 8.77-8.77 8.77z"
    />
    <path
      fill={color}
      d="M9.5 13.52a.365.365 0 0 0-.365.365v.73a.365.365 0 1 0 .73 0v-.73a.365.365 0 0 0-.365-.366zM9.541 3.289H9.5a2.9 2.9 0 0 0-2.051.841 2.903 2.903 0 0 0-.871 2.082.365.365 0 1 0 .73 0c0-.592.233-1.146.654-1.561a2.16 2.16 0 0 1 1.57-.631c1.155.016 2.145 1.005 2.16 2.16a2.182 2.182 0 0 1-1.004 1.875c-.972.627-1.552 1.735-1.552 2.964v1.039a.365.365 0 1 0 .73 0v-1.039c0-.965.467-1.865 1.218-2.35a2.909 2.909 0 0 0 1.34-2.5A2.943 2.943 0 0 0 9.54 3.29z"
    />
  </svg>
)

export default Faq
