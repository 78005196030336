import React, { useState, useEffect, useMemo } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  CircularProgress
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import Api from '../../services/Api'

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { Link } from 'react-router-dom'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  section: {
    margin: '15px 0'
  },
  root: {
    width: '100%'
  },
  img: {
    ...theme.common.avatar,
    width: 30,
    height: 30
  }
}))

const TimeslotsTable = ({ timeslots, service, past, onDelete }) => (
  <ExpansionPanel square defaultExpanded={!past}>
    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography variant="h3" style={{ marginBottom: 0 }}>
        {past ? 'Créneaux passés' : 'Créneaux ouverts'}
      </Typography>
    </ExpansionPanelSummary>

    <ExpansionPanelDetails>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jour</TableCell>
            <TableCell>Début</TableCell>
            <TableCell>Fin</TableCell>
            <TableCell>Réservations</TableCell>
            <TableCell>Lieu(x)</TableCell>
            {past ? '' : <TableCell align="right">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {timeslots.map(timeslot => (
            <TableRow key={timeslot.id} scope="row">
              <TableCell style={{ width: '20%' }}>{moment.unix(timeslot.date_start).format('dddd DD MMMM')}</TableCell>
              <TableCell style={{ width: '10%' }}>{moment.unix(timeslot.date_start).format('HH:mm')}</TableCell>
              <TableCell style={{ width: '10%' }}>{moment.unix(timeslot.date_end).format('HH:mm')}</TableCell>
              {timeslot.available_reservations ? (
                <TableCell style={{ width: '15%' }}>
                  {timeslot.confirmed_reservations} / {timeslot.available_reservations}
                </TableCell>
              ) : (
                <TableCell style={{ width: '15%' }}>{timeslot.confirmed_reservations}</TableCell>
              )}

              <TableCell style={{ width: '25%' }}>{timeslot.places.map(place => place.name).join(', ') || ' - '}</TableCell>
              {past || timeslot.status === -1 ? (
                ''
              ) : (
                <TableCell style={{ width: '20%' }} align="right">
                  <Tooltip title="Editer le créneau" aria-label="Editer le créneau">
                    <IconButton
                      component={Link}
                      to={{
                        pathname: `/artisan/creneaux/${timeslot.service}/${timeslot.id}`,
                        state: {
                          service: service,
                          timeslot: timeslot
                        }
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Supprimer le créneau" aria-label="Supprimer le créneau">
                    <IconButton onClick={() => onDelete(timeslot.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ExpansionPanelDetails>
  </ExpansionPanel>
)

const TimeslotsBlock = props => {
  const classes = useStyles()
  const [openedTimeslots, setOpenedTimeslots] = useState(null)
  const [closedTimeslots, setClosedTimeslots] = useState(null)
  const [toDelete, setToDelete] = useState(null)
  const [loading, setLoading] = useState(false)
  const { service } = props

  useEffect(() => {
    getList()
  }, [])

  const getList = () => {
    setLoading(true)
    Api.getTimeslots(service.id).then(res => {
      setLoading(false)

      if (res && res.data && res.data.success) {
        const closed = []
        const opened = []
        res.data.data.timeslot.map(timeslot => {
          const isBeforeNow = moment.unix(timeslot.date_end).isBefore(new Date())
          if (isBeforeNow) {
            closed.push(timeslot)
          } else {
            opened.push(timeslot)
          }
          return timeslot
        })
        opened.length > 0 && setOpenedTimeslots(opened)
        closed.length > 0 && setClosedTimeslots(closed)
      }
    })
  }

  const timeslotToDelete = useMemo(() => {
    return openedTimeslots && openedTimeslots.find(timeslot => timeslot.id === toDelete)
  }, [toDelete, openedTimeslots])

  const handleClose = () => setToDelete(null)

  const confirmDelete = async () => {
    setLoading(true)
    const res = await Api.deleteTimeslot(toDelete)
    if (res && res.data && res.data.success) {
      getList()
    }
    setToDelete(null)
    setLoading(false)
  }

  return (
    <Paper className={classes.sectionPaper}>
      <div className={classes.root}>
        {loading && <CircularProgress size="24px" />}
        {!loading && !openedTimeslots && !closedTimeslots && <Typography>Aucun créneau pour le moment</Typography>}
        {!loading && openedTimeslots && openedTimeslots.length > 0 && <TimeslotsTable timeslots={openedTimeslots} service={service} onDelete={setToDelete} />}
        {!loading && closedTimeslots && closedTimeslots.length > 0 && <TimeslotsTable timeslots={closedTimeslots} service={service} past />}
      </div>
      <Dialog open={toDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmation requise</DialogTitle>
        <DialogContent>
          {timeslotToDelete && timeslotToDelete.confirmed_reservations > 0 ? (
            <Typography>Une ou plusieurs réservations ont été effectuées sur ce créneau, souhaitez-vous le marquer comme complet?</Typography>
          ) : (
            <Typography>Êtes-vous sûr de vouloir supprimer ce contenu ?</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="contained" size="large" color="secondary" onClick={confirmDelete}>
            {loading ? <CircularProgress size="24px" color="white" /> : 'Supprimer'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default TimeslotsBlock
