import React, { useContext, useState, useEffect } from 'react'
import { Typography, Paper, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, CircularProgress } from '@material-ui/core'
import { OrderContext } from '../../stores/OrderProvider'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { ExpandMore } from '@material-ui/icons'
import Api from '../../services/Api'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper,
    marginTop: theme.typography.pxToRem(28)
  },
  bold: {
    ...theme.common.bold
  },
  margin: {
    marginTop: 20
  }
}))

const PlaceInfos = props => {
  const { orderContext } = useContext(OrderContext)
  const [expandedPannel, setExpandedPannel] = useState(false)
  const { order } = orderContext
  const [place, setPlace] = useState()
  const classes = useStyles()

  useEffect(() => {
    Api.getPlace(order.place.id).then(res => {
      if (res.data && res.data.success) {
        setPlace(res.data.data)
      } else {
        console.error(res.data.error)
      }
    })
  }, [])

  return (
    <Paper className={classes.sectionPaper}>
      <ExpansionPanel expanded={expandedPannel} onChange={() => setExpandedPannel(!expandedPannel)}>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography variant="h3" style={{ marginBottom: 0 }}>
            Informations du lieu
          </Typography>
        </ExpansionPanelSummary>
        {place ? (
          <ExpansionPanelDetails>
            <div>
              <Typography variant="body2" className={classes.bold}>
                Adresse
              </Typography>
              <Typography variant="body2">{place.address}</Typography>
              <Typography variant="body2">{place.postal}</Typography>
              <Typography variant="body2">{place.city}</Typography>
              <Typography variant="body2" className={classNames(classes.bold, classes.margin)}>
                Horaires
              </Typography>
              <Typography variant="body2">{place.schedule}</Typography>
              <Typography variant="body2" className={classNames(classes.bold, classes.margin)}>
                Informations
              </Typography>
              <Typography variant="body2">{place.infos}</Typography>
              {place.plan && (
                <>
                  <Typography variant="body2" className={classNames(classes.bold, classes.margin)}>
                    Plan
                  </Typography>
                  <Typography variant="body2">{place.plan}</Typography>
                </>
              )}
            </div>
          </ExpansionPanelDetails>
        ) : (
          <CircularProgress size="24px" />
        )}
      </ExpansionPanel>
    </Paper>
  )
}

export default PlaceInfos
