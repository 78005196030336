const getProcessingFee = order => {
  let processingFee = 0
  const totalTTC = getTotalTTC(order)
  if (order.minAmount && totalTTC < order.minAmount) {
    processingFee = order.minAmount - totalTTC
  }
  return processingFee.toFixed(2)
}

const getTotalTTC = order => {
  let totalTTC = 0
  order.products.forEach(product => {
    totalTTC += product.price_ttc * product.qty
  })
  return totalTTC.toFixed(2)
}

const getTotalPromo = order => {
  let totalPromo = 0
  let totalPrice = parseFloat(getTotalTTC(order)) + parseFloat(getProcessingFee(order))

  if (order.promo && order.promo.reduction) {
    if (order.promo.min_order && order.promo.min_order > totalPrice) {
      totalPromo = 0
    } else if (order.promo.product && order.promo.product.id) {
      const productInOrder = order.products.find(product => {
        return order.promo.product.id === product.id
      })

      //The product concerned by the promo is in the order
      if (productInOrder) {
        const totalPriceProduct = productInOrder.price_ttc * productInOrder.qty
        // The promo is in percent
        if (order.promo.is_percent) {
          totalPromo = (totalPriceProduct * order.promo.reduction) / 100
        } else {
          // check if the promo is not bigger than the reduction
          totalPromo = totalPriceProduct >= order.promo.reduction ? order.promo.reduction : totalPriceProduct
        }
      }
    } else {
      // The promo is in percent
      if (order.promo.is_percent) {
        totalPromo = (totalPrice * order.promo.reduction) / 100
      } else {
        totalPromo = totalPrice >= order.promo.reduction ? order.promo.reduction : totalPrice
      }
    }
  }
  return totalPromo.toFixed(2)
}

const getTotal = order => {
  const totalTTC = parseFloat(getTotalTTC(order))
  const processingFee = parseFloat(getProcessingFee(order))
  const totalPromo = parseFloat(getTotalPromo(order))
  return (totalTTC + processingFee - totalPromo).toFixed(2)
}

export { getTotal, getTotalTTC, getProcessingFee, getTotalPromo }
