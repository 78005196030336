import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { withUser } from '../stores/UserProvider'

const PrivateRoute = withUser(({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.userContext.loggedIn() && rest.restrictedTo === rest.userContext.getRole() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: rest.userContext.loggedIn() ? null : props.location } }} />
        )
      }
    />
  )
})

export default PrivateRoute
