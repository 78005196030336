import React, { useState, useContext, useEffect } from 'react'
import { Typography, Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import LinkButtonOption from '../../components/buttons/LinkButtonOption'
import PlanningForm from '../../components/form/PlanningForm'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper,
    '&:not(:first-child):not(:last-child)': {
      borderRadius: 0
    },
    '&:first-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    '&:last-child': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
  },
  invite: {},
  inviteLink: {
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-start'
  },
  avatar: {
    ...theme.common.avatar,
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30)
  },
  title: {
    marginBottom: theme.typography.pxToRem(5)
  },
  subtitle: {
    marginBottom: theme.typography.pxToRem(20)
  }
}))

const Schedule = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [places, setPlaces] = useState([{ value: null, label: 'Choisir un lieu' }])
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [schedules, setSchedules] = useState()

  useEffect(() => {
    Api.getEnterprisePlaces(userContext.getId()).then(res => {
      if (res.data && res.data.success) {
        const placesData = res.data.data.map(place => ({ value: place.id, label: place.name }))
        setPlaces(placesData)
        if (placesData.length > 0) {
          setSelectedPlace(placesData[0].value)
        }
      }
    })
  }, [])

  function getSchedule() {
    Api.getPlaceSchedules(selectedPlace).then(res => {
      if (res.data && !res.data.data.error) {
        setSchedules(res.data.data)
      }
    })
  }
  useEffect(() => {
    if (selectedPlace) {
      getSchedule()
    }
  }, [selectedPlace])

  return (
    <>
      <div className={classes.section}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h1">Jours & Créneaux</Typography>
          </Grid>
          <Grid item>
            <LinkButtonOption options={places} value={selectedPlace} onChange={item => setSelectedPlace(item)} placeholder="Choisissez un lieu à éditer" />
          </Grid>
        </Grid>
        <div>
          {schedules &&
            schedules.map(schedule => (
              <Paper key={schedule.id} className={classes.sectionPaper}>
                <Typography className={classes.title} variant="h2">
                  {schedule.artisan.name}
                </Typography>
                <Typography className={classes.subtitle}>{schedule.artisan.services.map(service => service.name).join(', ')}</Typography>
                <PlanningForm schedule={schedule} readOnly />
              </Paper>
            ))}
        </div>
        {schedules && schedules.length === 0 && <Typography align="center">Aucun horaire pour le moment.</Typography>}
        {!selectedPlace && <Typography align="center">Veuillez choisir un lieu.</Typography>}
      </div>
    </>
  )
}

export default Schedule
