import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter, Redirect } from 'react-router-dom'
import { Button, FormControl, Input, InputLabel, Typography, Paper, IconButton, Grid } from '@material-ui/core'
import { ReactComponent as BackIcon } from '../../assets/icons/leftarrow.svg'
import Select from './Select'
import { useToasts, TOAST } from '../../hooks/HookToasts'
import Api from '../../services/Api'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  back: {
    marginRight: 5
  },
  new: {
    margin: '50px auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  btn: {
    marginRight: 5,
    marginLeft: 'auto'
  },
  reservations: {
    margin: '15px auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}))

const TimeslotForm = props => {
  const classes = useStyles()
  const { add } = useToasts()
  const service = props.location.state ? props.location.state.service : null
  const timeslot = props.location.state ? props.location.state.timeslot : null
  const [places, setPlaces] = useState([])

  const defaultState = {
    start: timeslot ? moment.unix(timeslot.date_end).format('YYYY-MM-DD') : new Date().toISOString().substr(0, 10),
    start_time: timeslot ? moment.unix(timeslot.date_start).format('HH:mm') : '11:30',
    end_time: timeslot ? moment.unix(timeslot.date_end).format('HH:mm') : '12:30',
    available_reservations: timeslot ? timeslot.available_reservations : '',
    confirmed_reservations: timeslot ? timeslot.confirmed_reservations : '0',
    place: timeslot ? timeslot.places.map(place => place.id) : []
  }

  useEffect(() => {
    Api.getServicePlaces(service.id).then(res => {
      if (res.data.success) {
        let p = res.data.data.map(pl => ({
          ...pl,
          value: pl.id,
          label: pl.name
        }))
        setPlaces(p)
      }
    })
  }, [])

  const [values, setValues] = useState(defaultState)
  const handleChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  const submit = async () => {
    if (!values.start) {
      add('Le jour est obligatoire.', TOAST.ERROR)
    } else if (values.start_time > values.end_time) {
      add('L’heure de début doit être inférieure à l’heure de fin.', TOAST.ERROR)
    } else if (!values.start_time) {
      add("L'heure de début est obligatoire.", TOAST.ERROR)
    } else if (!values.end_time) {
      add("L'heure de fin est obligatoire.", TOAST.ERROR)
    } else if (values.available_reservations && values.available_reservations.length > 0 && isNaN(parseInt(values.available_reservations, 10))) {
      add('Le nombre de réservation possible est invalide.', TOAST.ERROR)
    } else if (values.available_reservations && values.available_reservations !== '' && values.confirmed_reservations > values.available_reservations) {
      add('Le nombre de réservations disponibles doit être supérieur ou égal au nombre de réservations déjà faites.', TOAST.ERROR)
    } else if (!values.place.length) {
      add('Veuillez choisir un ou plusieurs lieux.', TOAST.ERROR)
    } else {
      let data = { ...values, available_reservations: parseInt(values.available_reservations, 10) }
      let res
      if (timeslot) {
        res = await Api.updateTimeslot(timeslot.id, data)
      } else {
        res = await Api.addTimeslot(service.id, data)
      }
      if (res && res.data && res.data.success && res.data.data) {
        if (res.data.data.error) {
          add(res.data.data.error, TOAST.ERROR)
        } else {
          add('Le créneau a été créé', TOAST.SUCCESS)
          setValues(defaultState)
          props.history.push(`/artisan/creneaux/${service.id}`, { service: service })
        }
      } else {
        add("Le créneau n'a pas pu être créé", TOAST.ERROR)
      }
    }
  }

  if (service) {
    return (
      <div className={classes.section}>
        <Typography variant="h1">
          <IconButton className={classes.back} onClick={() => props.history.goBack()}>
            <BackIcon />
          </IconButton>
          {timeslot ? 'Modifier un' : 'Ouvrir un nouveau'} créneau pour le service {service.name}
        </Typography>
        <Paper className={classes.sectionPaper}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink htmlFor="start">
                  Jour
                </InputLabel>
                <Input
                  id="start"
                  type="date"
                  onChange={handleChange}
                  defaultValue={defaultState.start}
                  inputProps={{ min: new Date().toISOString().substr(0, 10) }}
                  name="start"
                  placeholder="Entrez une date de début"
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink htmlFor="start_time">
                  Début
                </InputLabel>
                <Input
                  id="start_time"
                  type="time"
                  defaultValue={defaultState.start_time}
                  onChange={handleChange}
                  name="start_time"
                  placeholder="Entrez une heure de début"
                  disableUnderline={true}
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel shrink htmlFor="end_time">
                  Fin
                </InputLabel>
                <Input
                  id="end_time"
                  type="time"
                  defaultValue={defaultState.end_time}
                  onChange={handleChange}
                  name="end_time"
                  placeholder="Entrez une heure de fin"
                  disableUnderline={true}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl margin="normal" fullWidth>
                <InputLabel shrink htmlFor="confirmed_reservations">
                  Réservation(s) possible(s)
                </InputLabel>
                <div className={classes.reservations}>
                  <Grid item xs={1}>
                    <Input
                      id="confirmed_reservations"
                      type="number"
                      defaultValue={values.confirmed_reservations}
                      onChange={handleChange}
                      name="confirmed_reservations"
                      placeholder="0"
                      disableUnderline={true}
                      disabled
                      xs={1}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    /
                  </Grid>
                  <Grid item xs={9}>
                    <Input
                      id="available_reservations"
                      type="text"
                      defaultValue={defaultState.available_reservations}
                      onChange={handleChange}
                      name="available_reservations"
                      placeholder="Champ facultatif"
                      disableUnderline={true}
                    />
                  </Grid>
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink htmlFor="place">
                Lieu
              </InputLabel>
              <Select multiple onChange={handleChange} name="place" options={places} value={values.place} placeholder="Choisissez un lieu" />
            </FormControl>
          </Grid>{' '}
        </Paper>
        <div className={classes.new}>
          <Button size="large" variant="outlined" onClick={() => props.history.goBack()} color="primary">
            Annuler
          </Button>
          <Button size="large" variant="contained" color="primary" onClick={submit}>
            {'Valider'}
          </Button>
        </div>
      </div>
    )
  } else {
    return <Redirect to={{ pathname: '/artisan/creneaux/' }} />
  }
}

export default withRouter(TimeslotForm)
