const isEmailValid = email => {
  const regExEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regExEmail.test(email)
}

const isPasswordValid = password => {
  if (password.length < 8) {
    return false
  } else {
    return true
  }
}

const isPhoneValid = phone => {
  const regExPhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  return regExPhone.test(phone)
}

export { isEmailValid, isPasswordValid, isPhoneValid }
