import React, { useState, useContext, useEffect } from 'react'
import { Typography, Paper, Table, TableRow, TableCell, TableHead, TableBody, IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import palette from '../../theme/palette'
import Api from '../../services/Api'
import { TOAST, useToasts } from '../../hooks/HookToasts'
import { Delete } from '@material-ui/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import PromoCodeForm from '../../components/form/PromoCodeForm'
import ConfirmationDialog from '../../components/dialog/ConfirmationDialog'
dayjs.locale('fr')

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  noResults: {
    marginTop: theme.typography.pxToRem(15),
    textAlign: 'center'
  },
  inline: {
    ...theme.common.inline,
    justifyContent: 'flex-start'
  },
  avatar: {
    ...theme.common.avatar,
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30)
  },
  tableRow: {
    '&:nth-of-type(2n + 1)': {
      backgroundColor: palette.skyBlue
    }
  }
}))

const Promo = props => {
  const classes = useStyles()
  const [promos, setPromos] = useState([])
  const [open, setOpen] = useState(false)
  const { add } = useToasts()
  const { userContext } = useContext(UserContext)

  function getPromoCodes() {
    Api.getEnterprisePromocodes(userContext.getId()).then(res => {
      if (res.data && res.data.success) {
        setPromos(res.data.data.promocode)
      }
    })
  }

  useEffect(() => {
    getPromoCodes()
  }, [])

  function cancel(promo) {
    Api.removePromoCode(promo).then(res => {
      if (res.data && res.data.success) {
        add('Code promo supprimé.', TOAST.SUCCESS)
        setOpen(false)
        getPromoCodes()
      } else {
        add("Le code promo n'a pas pu être supprimé.", TOAST.ERROR)
      }
    })
  }

  const table = (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Code</TableCell>
          <TableCell>Lieu</TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="center">Montant</TableCell>
          <TableCell align="center">Date</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {promos.map(promo => (
          <TableRow key={promo.id} className={classes.tableRow}>
            <TableCell>{promo.name.toUpperCase()}</TableCell>
            <TableCell>{promo.place ? promo.place.name : '-'}</TableCell>
            <TableCell>{promo.desc}</TableCell>
            <TableCell align="center">
              {parseFloat(promo.reduction).toFixed(2)} {promo.is_percent ? '%' : '€'}
            </TableCell>
            <TableCell align="center">
              {dayjs
                .unix(promo.start)
                .format('DD MMMM YYYY')
                .toLowerCase()}{' '}
              /{' '}
              {dayjs
                .unix(promo.end)
                .format('DD MMMM YYYY')
                .toLowerCase()}
            </TableCell>
            <TableCell align="right">
              <Tooltip title="Supprimer le code">
                <IconButton variant="contained" size="small" color="primary" onClick={() => setOpen(promo.id)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
        <ConfirmationDialog
          open={!!open}
          onClose={() => setOpen(false)}
          onConfirm={() => cancel(open)}
          title="Suppression de code promo"
          text="Êtes-vous sûr de vouloir supprimer ce code promo ?"
        />
      </TableBody>
    </Table>
  )

  return (
    <>
      <div className={classes.section}>
        <Typography variant="h1">Créer un code promo</Typography>
        <PromoCodeForm onSuccess={getPromoCodes} />
      </div>

      <div className={classes.section}>
        <Typography variant="h1">Mes codes promo</Typography>

        {promos.length > 0 ? (
          <Paper className={classes.sectionPaper}>{table}</Paper>
        ) : (
          <Typography className={classes.noResults} variant="h4">
            Aucun code pour le moment.
          </Typography>
        )}
      </div>
    </>
  )
}

export default Promo
