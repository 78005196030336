import { createMuiTheme } from '@material-ui/core/styles'
import Common from './styles/Common'
import palette from './palette'

const defaultTheme = createMuiTheme({
  typography: {
    useNextVariants: true
    // fontSize: 16
  }
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette.dark
    },
    secondary: {
      main: palette.red
    },
    background: {
      default: '#f8fbfe'
    }
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'],
    fontSize: 16
  },
  overrides: {
    MuiAvatar: {
      root: {
        width: defaultTheme.typography.pxToRem(50),
        height: defaultTheme.typography.pxToRem(50)
      }
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid #e7ecf2'
      },
      indicator: {
        backgroundColor: palette.dark
      }
    },
    MuiTab: {
      root: {
        minWidth: 72,
        marginRight: 32,
        fontFamily: ['Nunito', 'sans-serif'],
        fontSize: '0.563rem',
        marginBottom: '0.563rem',
        fontWeight: 700,
        letterSpacing: '0.063rem',
        textTransform: 'uppercase',
        color: palette.dark,

        [defaultTheme.breakpoints.up('md')]: {
          fontSize: '0.563rem'
        },

        '&$tabSelected': {
          color: palette.dark,
          fontWeight: 700,
          opacity: 1
        }
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: 4,
        borderBottom: 0,
        '&:last-child': {
          paddingRight: 0
        }
      },
      head: {
        fontSize: defaultTheme.typography.pxToRem(10),
        fontWeight: '700',
        letterSpacing: 1,
        textTransform: 'uppercase',
        color: palette.dark
      },
      body: {
        fontFamily: 'Nunito',
        fontSize: 14,
        fontWeight: '300',
        color: palette.dark
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#fff'
      },
      paperAnchorDockedLeft: {
        borderRight: '1px solid #eff3f8'
      }
    },
    MuiButton: {
      root: {
        transition: 'all .2s',
        '&:hover': {
          opacity: 0.6
        },
        '&$disabled': {
          opacity: 0.5
        }
      },
      contained: {
        backgroundColor: '#fff',
        border: `solid 1px ${palette.dark}`,
        color: palette.dark
      },
      containedSecondary: {
        background: 'linear-gradient(to right, #fe816b, #ff5d4d)',
        border: '0',
        color: 'white'
      },
      containedPrimary: {
        background: 'linear-gradient(to right, #4e5497, #343a84)',
        border: '0',
        color: 'white',
        '&$disabled': {
          color: 'white'
        }
      },
      label: {
        fontSize: '0.75rem',
        fontWeight: '700',
        letterSpacing: 0.86
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: palette.clearRed
        },
        '&:focus': {
          backgroundColor: palette.clearRed
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        color: palette.dark,
        fontSize: 14
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: 14
      }
    },
    MuiInputLabel: {
      root: {
        color: palette.dark,
        fontSize: 12,
        fontWeight: '700',
        letterSpacing: 1,
        textTransform: 'uppercase'
      }
    },
    MuiInputBase: {
      root: {
        color: palette.dark,
        fontSize: 14,
        fontWeight: '300'
      },
      input: {
        '&::placeholder': { color: '#03073b' }
      }
    },
    MuiSwitch: {
      root: {
        marginTop: 5
      },
      switchBase: {
        '&$checked': {
          '& + $track': {
            backgroundColor: '#343a84',
            borderColor: '#343a84',
            opacity: 1
          },
          '&$disabled$checked + $track': {
            backgroundColor: '#343a84',
            borderColor: '#343a84'
          }
        },
        '&$disabled + $track': {
          border: 0
        }
      },
      track: {
        borderRadius: 9,
        width: 32,
        height: 18,
        marginTop: -5,
        marginLeft: -5,
        border: 'solid 2px',
        opacity: 0.3,
        backgroundColor: '#343a84',
        borderColor: '#343a84'
      },
      thumb: {
        width: 14,
        height: 14,
        backgroundColor: '#fff'
      }
    },
    MuiExpansionPanel: {
      root: {
        borderRadius: 0,
        boxShadow: 'none',
        '&:before': {
          content: 'none'
        },
        '& + &': {
          borderTop: '0.063rem solid #e7ecf2'
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
        marginBottom: '1rem'
      }
    },
    MuiTypography: {
      h1: {
        fontFamily: ['Nunito', 'sans-serif'],
        fontSize: '1.25rem',
        fontWeight: 600,
        color: palette.dark,
        marginBottom: 18
      },
      h2: {
        fontFamily: ['Nunito', 'sans-serif'],
        fontSize: 14,
        fontWeight: '700',
        color: palette.dark,
        letterSpacing: 1.33,
        textTransform: 'uppercase'
      },
      h3: {
        fontFamily: ['Nunito', 'sans-serif'],
        fontSize: defaultTheme.typography.pxToRem(11),
        marginBottom: '0.563rem',
        fontWeight: 700,
        letterSpacing: '0.063rem',
        textTransform: 'uppercase',
        color: palette.dark
      },
      h4: {
        fontFamily: ['Nunito', 'sans-serif'],
        fontSize: '1rem',
        marginBottom: '0.563rem',
        fontWeight: 400,
        letterSpacing: '0.063rem',
        color: palette.dark
      },
      body1: {
        color: palette.dark,
        fontFamily: ['Nunito', 'sans-serif'],
        fontSize: defaultTheme.typography.pxToRem(14),
        fontWeight: '300',
        lineHeight: 1.4
      },
      body2: {
        color: palette.dark,
        fontFamily: ['Nunito', 'sans-serif'],
        fontSize: defaultTheme.typography.pxToRem(13),
        fontWeight: 300,
        lineHeight: 1.6
      }
    }
  },
  common: { ...Common(defaultTheme) }
})

export default theme
