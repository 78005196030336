import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper } from '@material-ui/core'
import LinkButton from '../../components/buttons/LinkButton'
import MessageHome from '../../components/entities/MessageHome'

const useStyles = makeStyles(theme => ({
  blocPaper: {
    padding: 18
  },
  blocTitle: {
    ...theme.common.blocTitle
  },
  allMessages: {
    paddingTop: 15,
    textAlign: 'center'
  }
}))

const UnreadMessages = ({ orders }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.blocPaper}>
      <Typography className={classes.blocTitle}>Messages non lus</Typography>
      {orders && orders.length > 0 ? (
        <div>
          {orders.map((order, index) => (
            <MessageHome key={`${index}`} order={order} last={index === orders.length - 1} />
          ))}
        </div>
      ) : (
        <Typography variant="body2">Aucun message non lu.</Typography>
      )}

      <div className={classes.allMessages}>
        <LinkButton to="/artisan/messages">Voir tous les messages</LinkButton>
      </div>
    </Paper>
  )
}

export default UnreadMessages
