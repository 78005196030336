import React from 'react'
import classNames from 'classnames'
import { Dialog, DialogTitle, Typography, DialogActions, Button, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  noMargin: {
    margin: 0
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(25)
  }
}))

export default function ConfirmationDialog({ open, onClose, onConfirm, text, title }) {
  const classes = useStyles()
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose} aria-labelledby="form-confirmation">
      <DialogTitle id="form-dialog-title">{title ? title : 'Confirmer votre action'}</DialogTitle>
      <DialogContent>
        <Typography variant="h3">{text ? text : 'Êtes-vous sûr ?'}</Typography>
        <DialogActions className={classNames(classes.noMargin, classes.dialogActions)}>
          <Button className={classes.noMargin} variant="contained" size="large" color="primary" onClick={onClose}>
            Non
          </Button>
          <Button className={classes.noMargin} variant="contained" size="large" color="secondary" onClick={onConfirm}>
            Oui
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
