import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Link, withRouter, Redirect } from 'react-router-dom'
import { Fab, IconButton, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { ReactComponent as BackIcon } from '../../assets/icons/leftarrow.svg'
import ProductsBlock from '../../components/entities/ProductsBlock'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  back: {
    marginRight: 5
  },
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem'
  }
}))

const ProductsServices = props => {
  const classes = useStyles()
  const service = props.location.state ? props.location.state.service : null

  if (!service) {
    return <Redirect to={{ pathname: '/artisan/produits' }} />
  }

  return (
    <div className={classes.section}>
      <Typography variant="h1">
        <IconButton className={classes.back} onClick={() => props.history.push('/artisan/produits')}>
          <BackIcon />
        </IconButton>
        {service.name}
      </Typography>
      <ProductsBlock service={service} />
      <Fab
        className={classes.fab}
        color="secondary"
        aria-label="Créer"
        component={Link}
        to={{
          pathname: '/artisan/produits/nouveau',
          state: { service }
        }}
      >
        <AddIcon fontSize="small" />
      </Fab>
    </div>
  )
}

export default withRouter(ProductsServices)
