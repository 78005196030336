import React from 'react'
import palette from '../../../theme/palette'

const Messages = ({ color = palette.primary }) => (
  <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.023 10.368C15.9841 10.3951 15.9524 10.4311 15.9305 10.4731C15.9085 10.515 15.897 10.5616 15.897 10.609V14.112L13.282 11.656C13.2301 11.6062 13.161 11.5782 13.089 11.578H13.036C12.536 11.674 12.028 11.722 11.519 11.722C8.767 11.722 7.103 10.625 6.289 9.92C5.45378 9.17473 4.85076 8.20478 4.552 7.12601C4.47871 6.80545 4.44082 6.47782 4.439 6.149C4.461 3.09 7.592 0.578995 11.439 0.578995C15.287 0.578995 18.419 3.082 18.419 6.161C18.419 7.772 17.546 9.306 16.023 10.368V10.368ZM6.243 15.108C5.806 15.109 5.369 15.068 4.939 14.988C4.89584 14.9797 4.85134 14.9817 4.80911 14.9939C4.76689 15.006 4.72813 15.028 4.696 15.058L2.638 17.006V14.228C2.63807 14.1807 2.62674 14.1342 2.60498 14.0922C2.58322 14.0503 2.55167 14.0142 2.513 13.987C1.27 13.126 0.569 11.872 0.569 10.578C0.569 8.802 1.891 7.185 3.897 6.453C4.03421 7.92795 4.73133 9.29426 5.845 10.271C5.898 10.321 7.824 12.269 11.481 12.316C10.598 14.01 8.559 15.109 6.243 15.109V15.108ZM11.44 0C7.414 0 4.128 2.588 3.916 5.838C1.566 6.616 0 8.492 0 10.578C0.0189196 11.3296 0.216088 12.0659 0.575275 12.7264C0.934462 13.3869 1.4454 13.9526 2.066 14.377V17.654C2.066 17.814 2.193 17.946 2.35 17.946C2.42197 17.9458 2.49108 17.9178 2.543 17.868L4.977 15.579C5.395 15.649 5.819 15.685 6.243 15.684C8.896 15.684 11.231 14.33 12.118 12.298C12.414 12.275 12.71 12.244 13.005 12.193L15.992 15C16.0191 15.026 16.0511 15.0462 16.0862 15.0596C16.1213 15.0729 16.1587 15.0791 16.1962 15.0777C16.2337 15.0763 16.2705 15.0673 16.3045 15.0514C16.3385 15.0355 16.3689 15.0129 16.394 14.985C16.4432 14.9304 16.4702 14.8595 16.47 14.786V10.762C18.073 9.59499 18.99 7.925 18.99 6.166C18.987 2.768 15.602 0.00100708 11.44 0.00100708V0Z"
      fill={color}
    />
  </svg>
)

export default Messages
