import React, { useContext, useState, useEffect } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Api from '../../services/Api'
import { UserContext } from '../../stores/UserProvider'
import ServiceTeaser from '../../components/entities/ServiceTeaser'
import { DynamicImport } from '../../components/DynamicImport'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  }
}))

const Settings = props => {
  const classes = useStyles()
  const [services, setServices] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [modules, setModules] = useState(null)
  const { userContext } = useContext(UserContext)

  function getServices() {
    Api.getServicesByArtisan(userContext.getId()).then(res => {
      if (res.data && res.data.success) {
        setServices(res.data.data.service)
      }
    })
  }

  function getModules(service) {
    if (service) {
      Api.getProcess(service.process).then(res => {
        if (res.data && res.data.success) {
          setModules(res.data.data.process)
        }
      })
    } else {
      setModules(null)
    }
  }

  function isServiceActif(service) {
    return service && selectedService && service.id === selectedService.id
  }

  useEffect(() => {
    getServices()
  }, [])

  useEffect(() => {
    if (services) {
      setSelectedService(services[0])
    }
  }, [services])

  useEffect(() => {
    getModules(selectedService)
  }, [selectedService])

  return (
    <div className={classes.section}>
      <Typography variant="h1">Paramètres</Typography>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={4}>
          {services &&
            services.map(service => (
              <ServiceTeaser key={`${service.id}`} active={isServiceActif(service)} service={service} inline onClick={() => setSelectedService(service)} />
            ))}
        </Grid>
        {modules && (
          <Grid item xs={12} md={8}>
            {modules.map(module => {
              if (module.module && module.module.component) {
                return (
                  <DynamicImport
                    key={`${module.id}`}
                    processModule={module}
                    service={selectedService}
                    resolve={() => import(`../../components/modules/${module.module.component.toLowerCase()}/${module.module.component}Edit`)}
                  />
                )
              }
              return null
            })}
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Settings
