import palette from '../palette'

export default function Common(theme) {
  return {
    container: {
      maxWidth: theme.typography.pxToRem(860),
      width: '100%',
      margin: '0 auto'
    },
    main: {
      padding: `${theme.typography.pxToRem(50)} 0`,
      marginTop: 75
    },
    section: {
      marginBottom: 52
    },
    sectionPaper: {
      padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(42)}`,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(18)}`
      }
    },
    blocTitle: {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
      letterSpacing: 1.22,
      marginBottom: theme.typography.pxToRem(24)
    },
    inline: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    avatar: {
      width: theme.typography.pxToRem(50),
      height: theme.typography.pxToRem(50),
      textAlign: 'center',
      objectFit: 'cover',
      borderRadius: '50%'
    },
    legend: {
      fontFamily: ['Nunito', 'sans-serif'],
      fontStyle: 'italic',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 200
    },
    orderInstruction: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: 1.5,
      color: palette.red,
      paddingLeft: theme.typography.pxToRem(11),
      borderLeft: `2px solid ${palette.red}`
    },
    code: {
      position: 'relative',
      margin: `0 ${theme.typography.pxToRem(5)}`,
      display: 'inline-block',
      fontSize: theme.typography.pxToRem(18),
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: -1,
        left: 0,
        right: 0,
        height: 2,
        backgroundColor: theme.palette.secondary.main
      }
    },
    hiddenXs: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    visibleSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    bold: {
      fontWeight: 700
    }
  }
}
