import React, { useContext } from 'react'
import { Typography, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import AccountForm from './AccountForm'
import PasswordForm from './PasswordForm'
import NewsletterForm from './NewsletterForm'
import DeleteAccountForm from './DeleteAccountForm'
import config from '../../config'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: 52
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  miniLabel: {
    fontSize: 12
  }
}))

const Account = props => {
  const { userContext } = useContext(UserContext)
  const classes = useStyles()

  return (
    <>
      <div className={classes.section}>
        <Typography variant="h1">Mon compte</Typography>
        <Paper className={classes.sectionPaper}>
          <AccountForm />
        </Paper>
      </div>

      <div className={classes.section}>
        <Typography variant="h1">Mot de passe</Typography>
        <Paper className={classes.sectionPaper}>
          <PasswordForm />
        </Paper>
      </div>

      {userContext.getRole() === config.roles.utilisateur && (
        <>
          <div className={classes.section}>
            <Typography variant="h1">Newsletters</Typography>
            <Paper className={classes.sectionPaper}>
              <NewsletterForm />
            </Paper>
          </div>
          <div className={classes.section}>
            <Typography variant="h1">Supprimer mon compte</Typography>
            <Paper className={classes.sectionPaper}>
              <DeleteAccountForm />
            </Paper>
          </div>
        </>
      )}
    </>
  )
}

export default Account
