import React, { useCallback, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { menu } from './routes'
import NoMatch from '../NoMatch'
import SidebarLayout from '../../components/layout/SidebarLayout'
import PreorderProvider from '../../stores/PreorderProvider'
import { useEffect } from 'react'
import Api from '../../services/Api'
import { useContext } from 'react'
import { UserContext } from '../../stores/UserProvider'
import PromoDialog from '../../components/dialog/PromoDialog'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.common.main
  },
  container: {
    ...theme.common.container
  }
}))
const Client = () => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [dialogs, setDialogs] = useState([])

  const newMenu = menu(userContext.user.place.value)

  useEffect(() => {
    const getPromoDialog = async () => {
      const { data } = await Api.getPromoDialog(userContext.getId())

      if (data && data.success) {
        const seenDialogs = localStorage.getItem('promoDialogs') ? JSON.parse(localStorage.getItem('promoDialogs')) : []
        const seeable = data.data.reduce((acc, value) => {
          if (!seenDialogs.includes(value.id)) {
            acc.push(value)
          }
          return acc
        }, [])

        setDialogs(seeable)
      }
    }
    getPromoDialog()
  }, [])

  const closeDialog = useCallback(
    dialogId => {
      const updatedDialogs = [...dialogs].filter(d => dialogId !== d.id)
      setDialogs(updatedDialogs)
      const seenDialogs = localStorage.getItem('promoDialogs') ? JSON.parse(localStorage.getItem('promoDialogs')) : []
      seenDialogs.push(dialogId)
      localStorage.setItem('promoDialogs', JSON.stringify(seenDialogs))
    },
    [dialogs]
  )

  return (
    <div>
      <SidebarLayout menu={newMenu }>
        <div className={classes.container}>
          <PreorderProvider>
            <Switch>
              {menu(userContext.user.place.value).map(item => (
                <Route
                  key={`${item.path}`}
                  exact={item.exact}
                  path={item.path}
                  render={props => {
                    const Screen = item.component
                    document.title = `box'n services - ${item.label}`
                    return <Screen {...props} />
                  }}
                />
              ))}
              <Route render={() => <NoMatch />} />
            </Switch>
          </PreorderProvider>
          {dialogs.map(dialog => (
            <PromoDialog key={dialog.id} title={dialog.title} content={dialog.body} onClose={() => closeDialog(dialog.id)} openDialog={true} />
          ))}
        </div>
      </SidebarLayout>
    </div>
  )
}

export default Client
