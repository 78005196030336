import React, { useState, useContext } from 'react'
import { Button, CircularProgress, Grid, Switch, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import { TOAST, useToasts } from '../../hooks/HookToasts'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: 52
  },
  miniLabel: {
    fontSize: 12
  },
  photoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 16
  },
  btn: {
    marginRight: 5,
    marginLeft: 'auto'
  }
}))

const NewsletterForm = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [nlMain, setNlMain] = useState(userContext.user.nl_main)
  const [nlFresh, setNlFresh] = useState(userContext.user.nl_fresh)
  const { add } = useToasts()

  async function submit(e) {
    e.preventDefault()
    setLoading(true)
    try {
      const res = await userContext.updateNewsletters(nlMain, nlFresh)
      if (res.data.data.error) {
        add('Une erreur est survenue', TOAST.ERROR)
      } else {
        add('Vos préférences ont été mises à jour', TOAST.SUCCESS)
      }
    } catch (error) {
      console.error(error)
      add('Une erreur est survenue', TOAST.ERROR)
    }
    setLoading(false)
  }

  return (
    <form autoComplete="off" onSubmit={submit}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            label="S'abonner à la lettre d'information Le Bon Plan Conciergerie (recevez un mail tous les mois sur l'actualité et les promotions de box'n services)."
            required
            classes={{ label: classes.miniLabel }}
            control={<Switch color="primary" checked={nlMain === 1} value={nlMain} aria-label="A" onChange={e => setNlMain(nlMain === 1 ? 0 : 1)} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            label="S'abonner à la newsletter Produits frais"
            required
            classes={{ label: classes.miniLabel }}
            control={<Switch color="primary" checked={nlFresh === 1} value={nlFresh} aria-label="A" onChange={e => setNlFresh(nlFresh === 1 ? 0 : 1)} />}
          />
        </Grid>

        <Grid item xs={12}>
          <Button justify="center" style={{ marginBottom: '12px' }} type="submit" size="large" variant="contained" color="primary" onClick={submit}>
            {loading ? <CircularProgress size="24px" color="primary" /> : 'Enregistrer'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewsletterForm
