import { CircularProgress, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Api from '../../services/Api'
import { withPreorder } from '../../stores/PreorderProvider'
import { withUser } from '../../stores/UserProvider'
import palette from '../../theme/palette'
import ServicesBlock from '../entities/ServicesBlock'

const styles = theme => ({
  section: { ...theme.common.section },
  placeTitle: {
    alignItems: 'center',
    fontSize: '1.1rem'
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  miniLabel: {
    fontSize: theme.typography.pxToRem(12)
  },
  iconTitle: {
    marginLeft: theme.typography.pxToRem(5),
    '& svg path': {
      fill: palette.red
    }
  },
  next: {
    margin: `${theme.typography.pxToRem(50)} auto 0`,
    textAlign: 'right'
  }
})

class FirstStep extends Component {
  state = {
    services: [],
    isLoading: true
  }

  componentDidMount = () => {
    const { userContext, orderContext } = this.props
    orderContext.resetStateOrder()
    if (userContext.user.place) {
      Api.getServicesByPlace(userContext.user.place.value).then(res => {
        if (res.data.data.error) {
          this.setState({ isLoading: false })
        }
        this.setState({ isLoading: false, services: res.data.data.service })
      })
    } else {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const { classes, userContext, orderContext } = this.props
    const { services, isLoading } = this.state

    if (isLoading) {
      return (
        <div style={{ display: 'flex', height: '70vh', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size="24px" />
        </div>
      )
    }
    return (
      <>
        {services && services.length > 0 ? (
          <>
            <div className={classes.section}>
              {userContext.user.place ? (
                <Typography variant="h1" className={classes.placeTitle}>
                  Choisissez un service à {userContext.user.place.label}
                </Typography>
              ) : (
                <Typography variant="h1">Choisissez un service</Typography>
              )}
              <ServicesBlock
                onSelectionDone={async services => {
                  await orderContext.handleService(services)
                  this.props.history.push('/client/commandes/nouveau#1')
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className={classes.section}>
              <Typography variant="span" className={classes.placeTitle}>
                Aucun service n'existe pour le moment à {userContext.user.place.label}
              </Typography>
            </div>
          </>
        )}
      </>
    )
  }
}

export default withRouter(withUser(withPreorder(withStyles(styles)(FirstStep))))
