import React from 'react'
import { ButtonBase, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import palette from '../../theme/palette'
import { Link } from 'react-router-dom'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

const useStyles = makeStyles(theme => ({
  btn: { width: '100%' },
  message: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #e7ecf2'
  },
  last: {
    borderBottom: 0
  },
  messageInfos: {
    borderRight: '1px solid #e7ecf2',
    padding: 13,
    textAlign: 'center',
    maxWidth: theme.typography.pxToRem(105),
    minWidth: theme.typography.pxToRem(105)
  },
  icon: {
    width: 40,
    display: 'block',
    margin: '0 auto',
    '& svg': {
      width: 40,
      height: 'auto'
    },
    '& path': {
      fill: palette.dark
    }
  },
  messageSubtitle: {
    fontSize: 9,
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 1
  },
  messageDate: {},
  messageContent: {
    padding: 13
  },
  messageSpeaker: {
    marginBottom: 11
  }
}))

const MessageHome = props => {
  const classes = useStyles()
  const { last, order } = props

  const messagesSummary = () => {
    let summary = order.messages.join('\n')
    if (summary.length > 100) {
      summary = summary.substring(0, 100) + '...'
    }
    return <Typography>{summary}</Typography>
  }

  return (
    <ButtonBase
      disableTouchRipple
      className={classes.btn}
      focusRipple
      focusVisibleClassName={classes.titleActive}
      component={Link}
      to={`artisan/messages/${order.id}`}
    >
      <div className={classNames(classes.message, { [classes.last]: last })}>
        <div className={classes.messageInfos}>
          <div className={classes.messageIcon}>
            <img width={40} color={palette.dark} className={classes.icon} src={order.category.img} alt={order.category.name} />
          </div>
          <Typography noWrap className={classes.messageSubtitle}>
            {order.category.name}
          </Typography>
          <Typography noWrap className={classes.messageDate}>
            {dayjs
              .unix(order.date)
              .format('DD MMMM YYYY')
              .toLowerCase()}
          </Typography>
        </div>
        <div className={classes.messageContent}>
          <Typography className={classNames(classes.messageSubtitle, classes.messageSpeaker)}>{order.client}</Typography>
          {messagesSummary()}
        </div>
      </div>
    </ButtonBase>
  )
}

export default MessageHome
