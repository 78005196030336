import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import FirstStep from '../../components/process/FirstStep'
import OrdersList from './OrdersList'
import Pagination from '../../components/layout/Pagination'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  }
}))

const Orders = props => {
  const classes = useStyles()
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { userContext } = useContext(UserContext)
  const [paginationLinks, setPaginationLinks] = useState({})
  const [paginationMeta, setPaginationMeta] = useState({})

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const page = params.get('p')

    Api.getOrders(userContext.getRole(), userContext.getId(), { pending: 0, page }).then(res => {
      if (res.data) {
        setOrdersData(res.data)
        setIsLoading(false)
      }
    })
  }, [])

  const setOrdersData = data => {
    setOrders(data.data)
    setPaginationLinks({ prev: data.links.prev, next: data.links.next })
    setPaginationMeta({ currentPage: data.meta.current_page, pageCount: data.meta.last_page })
  }

  return (
    <div className={classes.section}>
      {isLoading ? (
        <div style={{ display: 'flex', height: '70vh', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size="24px" />
        </div>
      ) : orders.length > 0 ? (
        <>
          <OrdersList orders={orders} title="Mes commandes" />
          <Pagination
            meta={paginationMeta}
            links={paginationLinks}
            onChangePage={data => {
              if (data) {
                props.history.push(`?p=${data.meta.current_page}`)
                setOrdersData(data)
              }
            }}
          />
        </>
      ) : (
        <FirstStep />
      )}
    </div>
  )
}

export default Orders
