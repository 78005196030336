import React, { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid } from '@material-ui/core'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import { priceFormat } from '../../services/helpers'
import TopPlaces from '../../components/dashboard/TopPlaces'
import PendingOrdersStatus from '../../components/dashboard/PendingOrdersStatus'
import UnreadMessages from '../../components/dashboard/UnreadMessages'
import NumberFocus from '../../components/dashboard/NumberFocus'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: 52
  },
  placeTitle: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Home = () => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [dashboardData, setDashBoardData] = useState({})

  useEffect(() => {
    Api.getDashboard(userContext.getId()).then(res => {
      if (res.data.success && res.data.data) {
        setDashBoardData(res.data.data)
      }
    })
  }, [])

  return (
    <div className={classes.section}>
      <Typography variant="h1" className={classes.placeTitle}>
        Tableau de bord
      </Typography>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} sm={4}>
              <PendingOrdersStatus listStatus={dashboardData.nb_orders_by_status} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <UnreadMessages orders={dashboardData.messages_by_orders} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} sm={4}>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12}>
                  <NumberFocus
                    title="Chiffre d'affaires"
                    number={dashboardData.monthly_report ? `${priceFormat(dashboardData.monthly_report.total)} €` : 0}
                    legend="De chiffre d'affaires ce mois-ci"
                  />
                </Grid>
                <Grid item xs={12}>
                  <NumberFocus
                    title="Nombre de commande"
                    number={dashboardData.monthly_report ? dashboardData.monthly_report.nb_orders : 0}
                    legend="Commandes ce mois-ci"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TopPlaces rawData={dashboardData.places} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
