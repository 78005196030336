import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Divider, Avatar } from '@material-ui/core'
import config from '../../config'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  blocPaper: {
    padding: 18
  },
  blocTitle: {
    ...theme.common.blocTitle
  },
  avatar: {
    width: 22,
    height: 22,
    fontSize: 12
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center'
  }
}))

const PendingOrdersStatus = ({ listStatus }) => {
  const classes = useStyles()

  const getStatusColor = code => {
    const status = config.orderStatuses.find(orderStatus => orderStatus.value === code)
    const color = status ? status.color : ''
    return color
  }

  return (
    <Paper className={classes.blocPaper}>
      <Typography className={classes.blocTitle}>Commandes en cours</Typography>
      <List>
        {listStatus &&
          listStatus.map((status, index) => {
            const statusColor = getStatusColor(status.id)
            return (
              <div key={index}>
                {index !== 0 && <Divider />}
                <ListItem button disableGutters>
                  <Link className={classes.link} to={`/artisan/commandes?s=${status.id}`}>
                    <ListItemIcon>
                      <Avatar className={classes.avatar} style={{ backgroundColor: statusColor }}>
                        {status.nb_orders}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={status.label} />
                  </Link>
                </ListItem>
              </div>
            )
          })}
      </List>
    </Paper>
  )
}

export default PendingOrdersStatus
