import React from 'react'
import PaymentFormMobile from '../../components/form/PaymentFormMobile'

const MobilePayment = props => {
  if (props.match.params.id) {
    const params = new URLSearchParams(props.location.search)
    const url = params.get('u')
    const key = params.get('k')
    return <PaymentFormMobile order={{ id: props.match.params.id }} publicKey={key} url={url} />
  }
  return null
}

export default MobilePayment
