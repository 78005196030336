import React, { useContext, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Typography, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import palette from '../../theme/palette'
import { UserContext } from '../../stores/UserProvider'
import defaultImg from '../../assets/default.png'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

const useStyles = makeStyles(theme => ({
  message: {
    borderBottom: `1px solid ${palette.grey}`,
    paddingBottom: theme.typography.pxToRem(15),
    marginBottom: theme.typography.pxToRem(15),
    display: 'flex',

    '&:last-of-type': {
      borderBottom: 0
    }
  },
  messageMine: {
    flexDirection: 'row-reverse',
    '& > div > p': {
      textAlign: 'right'
    },
    '& $avatar': {
      marginLeft: theme.typography.pxToRem(15),
      marginRight: 0
    }
  },
  messages: {
    overflow: 'auto',
    maxHeight: 500
  },
  avatar: {
    marginRight: theme.typography.pxToRem(15)
  },
  bubble: {
    padding: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(5),
    backgroundColor: palette.grey,
    color: palette.dark
  },
  bubbleMine: {
    backgroundColor: palette.red,
    '& > p': {
      color: '#fff'
    }
  }
}))

function reOrderMessages(messages) {
  let orderedMessages = []
  let ignoredRows = []
  messages.forEach((message, index) => {
    if (ignoredRows.indexOf(message.id) === 1) return

    if (
      !messages[index - 1] ||
      messages[index - 1].author.id !== message.author.id ||
      !dayjs.unix(messages[index - 1].created_at).isSame(dayjs.unix(message.created_at), 'minute')
    ) {
      let newMessage = { ...message, text: [message.text] }

      orderedMessages.push(newMessage)
      return
    }

    if (messages[index - 1] && messages[index - 1].author.id === messages[index].author.id) {
      orderedMessages[orderedMessages.length - 1].text.push(messages[index].text)
      ignoredRows.push(messages[index].id)
    }
  })

  return orderedMessages
}

const MessageList = props => {
  const classes = useStyles()
  const el = useRef()
  const { messages } = props
  const { userContext } = useContext(UserContext)

  useEffect(() => {
    el.current.scrollIntoView(false)
  })

  return (
    <div className={classes.messages}>
      {reOrderMessages(messages).map((message, index) => {
        const mine = message.author.id === userContext.getId() ? true : false
        return (
          <div
            key={message.id}
            className={classNames(classes.message, {
              [classes.messageMine]: mine
            })}
          >
            <Avatar src={message.author.img ? message.author.img : defaultImg} className={classes.avatar} alt="" />
            <Tooltip
              title={dayjs
                .unix(message.created_at)
                .format('dddd DD MMMM à HH:mm')
                .toLowerCase()}
              placement={mine ? 'left' : 'right'}
              aria-label="Add"
            >
              <div className={classNames([classes.bubble], { [classes.bubbleMine]: mine })}>
                {message.text.map((text, index) => (
                  <Typography key={`message-${message.id}-${index}`} variant="body2">
                    {text}
                  </Typography>
                ))}
              </div>
            </Tooltip>
          </div>
        )
      })}
      <span ref={el} />
    </div>
  )
}

export default MessageList
