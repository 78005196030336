import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Logo from '../../assets/Logo'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flex: '1 1 auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    marginTop: '-70px',
    paddingTop: '80px'
  },
  content: {
    maxWidth: '100%'
  }
})

const FullCenteredLayout = props => {
  const classes = useStyles()

  return (
    <main className={classes.root}>
      <Link to="/" style={{ marginBottom: '70px' }}>
        <Logo />
      </Link>
      <div className={classes.content}>
        <Typography variant="h1" align="center">
          {props.title}
        </Typography>
        {props.children}
      </div>
    </main>
  )
}

export default FullCenteredLayout
