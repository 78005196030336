import Contact from '../common/Contact'
import Account from '../common/Account'
import Faq from '../common/Faq'
import Users from './Users'
import Report from './Report'
import Invoices from './Invoices'
import Schedule from './Schedule'
import Promo from './Promo'

export const menu = [
  {
    label: 'Accueil',
    path: '/entreprise',
    icon: 'Home',
    exact: true,
    component: Report
  },
  {
    label: 'Utilisateurs',
    path: '/entreprise/utilisateurs',
    icon: 'Sponsor',
    exact: true,
    component: Users
  },
  {
    label: 'Factures',
    path: '/entreprise/factures',
    icon: 'Orders',
    exact: true,
    component: Invoices
  },
  {
    label: 'Codes promo',
    path: '/entreprise/promo',
    icon: 'Cupons',
    exact: true,
    component: Promo
  },
  {
    label: 'Jours & Créneaux',
    path: '/entreprise/horaires',
    icon: 'Calendar',
    exact: true,
    component: Schedule,
    isLast: true
  },
  {
    label: 'Foire aux questions',
    path: '/entreprise/faq',
    icon: 'Faq',
    component: Faq,
    exact: true
  },
  {
    label: 'Contact',
    path: '/entreprise/contact',
    icon: 'Contact',
    exact: true,
    component: Contact
  },
  {
    label: 'Mon Compte',
    path: '/entreprise/compte',
    icon: null,
    hideInMenu: true,
    exact: true,
    component: Account
  },

]
