import React from 'react'
import palette from '../../../theme/palette'

const Home = ({ color = palette.primary }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.575 9.60999C17.4957 9.60878 17.4169 9.62352 17.3433 9.65336C17.2698 9.68319 17.203 9.72751 17.147 9.78367C17.0909 9.83983 17.0467 9.90668 17.017 9.98026C16.9873 10.0538 16.9727 10.1327 16.974 10.212V18.432H12.71V15.075C12.709 14.9206 12.6479 14.7726 12.5396 14.6624C12.4313 14.5523 12.2844 14.4886 12.13 14.485H9.047C8.88796 14.4836 8.7347 14.5446 8.62011 14.6549C8.50552 14.7652 8.4387 14.916 8.434 15.075V18.432H4.181V10.485C4.17983 10.4073 4.16331 10.3305 4.13238 10.2592C4.10145 10.1878 4.05673 10.1233 4.00079 10.0693C3.94485 10.0154 3.87879 9.97295 3.8064 9.94457C3.73401 9.9162 3.65673 9.90241 3.579 9.904C3.42567 9.90531 3.27901 9.96687 3.17068 10.0754C3.06235 10.1839 3.00104 10.3307 3 10.484V19.034C3 19.351 3.262 19.614 3.58 19.614H9.047C9.364 19.614 9.627 19.351 9.627 19.034V15.677H11.529V19.034C11.529 19.351 11.791 19.614 12.129 19.614H17.575C17.892 19.614 18.155 19.351 18.155 19.034V10.212C18.1569 10.1343 18.1433 10.057 18.1151 9.98452C18.0869 9.91208 18.0446 9.84596 17.9906 9.78997C17.9367 9.73399 17.8722 9.68926 17.8008 9.65836C17.7295 9.62747 17.6527 9.61103 17.575 9.60999V9.60999Z"
      fill={color}
    />
    <path
      d="M20.6949 10.082L15.9499 5.31601V2.06601C15.9499 1.72801 15.6759 1.46701 15.3369 1.46701C15.2593 1.46792 15.1827 1.4842 15.1114 1.51492C15.0401 1.54564 14.9757 1.59018 14.9217 1.64598C14.8678 1.70178 14.8255 1.76773 14.7972 1.84C14.7689 1.91226 14.7552 1.98941 14.7569 2.067V4.12401L10.8429 0.187012C10.7914 0.130553 10.7291 0.0849345 10.6598 0.0528259C10.5904 0.0207174 10.5153 0.0027518 10.4389 0C10.3588 0.000765578 10.2797 0.0177293 10.2063 0.0498657C10.1329 0.0820022 10.0668 0.128659 10.0119 0.187012L0.181918 10.017C-0.0530815 10.252 -0.0680815 10.63 0.181918 10.847C0.401918 11.099 0.777919 11.082 1.01392 10.847L10.4389 1.444L19.8419 10.935C19.8991 10.9881 19.9664 11.0291 20.0397 11.0555C20.1131 11.082 20.191 11.0934 20.2689 11.089C20.4246 11.0895 20.5755 11.035 20.6949 10.935C20.8041 10.8198 20.8649 10.6672 20.8649 10.5085C20.8649 10.3498 20.8041 10.1972 20.6949 10.082V10.082Z"
      fill={color}
    />
  </svg>
)

export default Home
