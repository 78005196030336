import React, { useEffect, useContext, useState } from 'react'
import { UserContext } from '../../stores/UserProvider'
import Api from '../../services/Api'
import { makeStyles } from '@material-ui/styles'
import { Paper, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  sectionPaper: {
    ...theme.common.sectionPaper
  }
}))

const Faq = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)
  const [faqItems, setFaqItems] = useState()
  const [expandedPannel, setExpandedPannel] = useState()

  useEffect(() => {
    Api.getFaq(userContext.getRole()).then(res => {
      if (!res.data.data.error) {
        setFaqItems(res.data.data.faq)
      }
    })
  }, [])

  return (
    <>
      <Typography variant="h1">Foire aux questions</Typography>
      {faqItems && (
        <Paper className={classes.sectionPaper}>
          {faqItems.map(item => (
            <ExpansionPanel
              key={item.id}
              expanded={expandedPannel === item.id}
              onChange={() => setExpandedPannel(expandedPannel === item.id ? false : item.id)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h3" style={{ marginBottom: 0 }}>
                  {item.question}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography dangerouslySetInnerHTML={{ __html: item.answer }} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Paper>
      )}
    </>
  )
}

export default Faq
