import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ToastConsumer, ToastProvider, DefaultToastContainer } from 'react-toast-notifications'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { Login, Register, Validation, Reset, Cgv } from './pages/authentication'
import NoMatch from './pages/NoMatch'
import PrivateRoute from './components/PrivateRoute'
import Client from './pages/client/Client'
import Craftsman from './pages/craftsman/Craftsman'
import Deliverer from './pages/deliverer/Deliverer'
import Enterprise from './pages/enterprise/Enterprise'
import MobilePayment from './pages/common/MobilePayment'
import theme from './theme'
import UserProvider, { UserContext } from './stores/UserProvider'
import OrderProvider from './stores/OrderProvider'
import { CircularProgress } from '@material-ui/core'
import TagManager from 'react-gtm-module'
import history from './services/history'
import SnackbarToast from './hooks/SnackbarToast'
import { HookToastProvider } from './hooks/HookToasts'
import { useEffect } from 'react'
import config from './config'
import Api from './services/Api'
import ErrorBoundary from './components/layout/ErrorBoundary'

const tagManagerArgs = {
  gtmId: 'GTM-MGNVJBW'
}

TagManager.initialize(tagManagerArgs)

const ToastContainer = props => <DefaultToastContainer {...props} style={{ position: 'relative' }} />

const App = () => {
  useEffect(() => {
    Api.get(config.baseURL + '/sanctum/csrf-cookie')
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ToastProvider id="toastProvider" components={{ Toast: SnackbarToast, ToastContainer }}>
          <HookToastProvider>
            <Router history={history}>
              <UserProvider>
                <CssBaseline />
                <UserContext.Consumer>
                  {({ userContext }) => {
                    return userContext.loading ? (
                      <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size="24px" />
                      </div>
                    ) : (
                      <OrderProvider>
                        <Switch>
                          <Route exact path="/">
                            {userContext.loggedIn() ? <Redirect to={`/${userContext.getRole()}`} /> : <Login />}
                          </Route>

                          <Route exact path="/inscription" component={() => <Register />} />
                          <Route path="/validation/:token" component={Validation} />
                          <Route path="/paiement-mobile/:id" component={MobilePayment} />
                          <Route path="/reinitialisation-mot-de-passe/:token" component={() => <Reset />} />
                          <Route path="/cgu/:placeId" component={Cgv} />
                          <PrivateRoute restrictedTo="artisan" path="/artisan" component={Craftsman} />
                          <PrivateRoute restrictedTo="client" path="/client" component={Client} />
                          <PrivateRoute restrictedTo="entreprise" path="/entreprise" component={Enterprise} />
                          <PrivateRoute restrictedTo="livreur" path="/livreur" component={Deliverer} />
                          <Route render={() => <NoMatch />} />
                        </Switch>
                      </OrderProvider>
                    )
                  }}
                </UserContext.Consumer>
              </UserProvider>
            </Router>
          </HookToastProvider>
          <ToastConsumer>{() => {}}</ToastConsumer>
        </ToastProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
