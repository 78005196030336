import React, { useState, useContext } from 'react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import PasswordInput from '../../components/form/PasswordInput'
import { UserContext } from '../../stores/UserProvider'
import { isPasswordValid } from '../../services/formUtils'
import { TOAST, useToasts } from '../../hooks/HookToasts'

const PasswordForm = props => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const { add } = useToasts()
  const { userContext } = useContext(UserContext)

  async function submit(e) {
    e.preventDefault()
    setLoading(true)

    if (!isPasswordValid(password) || !isPasswordValid(newPassword)) {
      add('Les mots de passe sont invalides', TOAST.ERROR)
      setLoading(false)
      return
    }

    try {
      const res = await userContext.updatePassword(password, newPassword)
      if (res.data.data.error) {
        add(`Une erreur est survenue: ${res.data.data.error}`, TOAST.ERROR)
        setLoading(false)
        return
      }

      add('Votre mot de passe a bien été changé', TOAST.SUCCESS)
      setLoading(false)
      return
    } catch (error) {
      console.error(error)
      add(`Une erreur est survenue: ${error}`, TOAST.ERROR)
      setLoading(false)
    }
  }

  return (
    <form autoComplete="off" onSubmit={submit}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12} sm={6}>
          <PasswordInput
            required
            fullWidth
            margin="normal"
            value={password}
            name="password"
            label="Nouveau Mot de passe"
            placeholder="Entrez votre nouveau mot de passe"
            onChange={e => setPassword(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordInput
            required
            fullWidth
            margin="normal"
            value={newPassword}
            name="newpassword"
            label="Confirmation Nouveau Mot de passe"
            placeholder="Entrez votre nouveau mot de passe"
            onChange={e => setNewPassword(e.currentTarget.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button justify="center" style={{ marginBottom: '12px' }} type="submit" size="large" variant="contained" color="primary" onClick={submit}>
            {loading ? <CircularProgress size="24px" color="primary" /> : 'Enregistrer'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default PasswordForm
