import React, { useContext } from 'react'
import { Typography, Paper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserContext } from '../../stores/UserProvider'
import Users from './Users'

const useStyles = makeStyles(theme => ({
  section: {
    ...theme.common.section
  },
  sectionPaper: {
    ...theme.common.sectionPaper
  },
  iframe: {
    width: '100%',
    paddingTop: '100%',
    height: 0,
    overflow: 'hidden',
    position: 'relative',
    '& iframe': {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0
    }
  },
  new: {
    margin: '50px auto',
    textAlign: 'center'
  }
}))

const Report = props => {
  const classes = useStyles()
  const { userContext } = useContext(UserContext)

  if (!userContext.user.pro_company_report_url) {
    return <Users />
  }

  return (
    <>
      <div className={classes.section}>
        <Typography variant="h1">Rapport</Typography>

        <Paper className={classes.sectionPaper}>
          <div className={classes.iframe}>
            <iframe title="Rapport" width="1000" height="1000" src={userContext.user.pro_company_report_url} frameborder="0" allowfullscreen />
          </div>

          <div className={classes.new}>
            <Button variant="contained" color="primary" size="large" component={'a'} target="_blank" href={userContext.user.pro_company_report_url}>
              Voir le rapport
            </Button>
          </div>
        </Paper>
      </div>
    </>
  )
}

export default Report
