import React, { createContext, Component } from 'react'

import config from '../config'
import Api from '../services/Api'
import io from 'socket.io-client'
import history from '../services/history'
import { getRoleRoute } from '../services/helpers'

export const UserContext = createContext({})

const _isOk = res => res && res.status === 200 && res.data

class UserProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      user: null,
      socket: io(config.wsURL),
      notifs: [],
      storage: localStorage.getItem('token') ? localStorage : sessionStorage,
      register: async data => {
        try {
          const res = await Api.register(data)
          if (_isOk(res) && res.data.data.error) {
            console.warn('Register failed with error:', res.data.data.error)
            let message = res.data.data.error === 409 ? "L'email existe déjà" : 'Une erreur est survenue'
            return { status: res.data.data.error, message: message }
          } else if (_isOk(res)) {
            return { status: 200, message: res.data.data }
          }
        } catch (err) {
          return { status: 500, message: 'Une erreur est survenue' }
        }
      },
      login: async (email, password, remember) => {
        this.setState({
          loading: true,
          storage: remember ? localStorage : sessionStorage,
          user: null
        })

        try {
          const res = await Api.login(email, password)

          if (_isOk(res) && res.data.data.token) {
            const { id, role, token } = res.data.data
            this.state.storage.setItem('id', id)
            this.state.storage.setItem('role', getRoleRoute(role))
            this.state.storage.setItem('token', token)
            await this.state.getProfile()
            this.setState({ loading: false })
            return { status: 200, route: getRoleRoute(role) }
          } else {
            this.setState({ loading: false })
            return { status: res.data.data.error }
          }
        } catch (err) {
          console.error(err)
          this.setState({ loading: false })

          return 500
        }
      },
      forgotPassword: async email => {
        try {
          const res = await Api.forgotPassword(email)

          if (_isOk(res) && res.data.data.error) {
            return { status: res.data.data.error }
          } else {
            return { status: 200 }
          }
        } catch (err) {
          console.error('forgotPassword generic error', err)
          return { status: 500 }
        }
      },
      getId: () => parseInt(this.state.storage.getItem('id')),
      getToken: () => {
        return this.state.storage.getItem('token')
      },
      logout: () => {
        this.state.storage.removeItem('id')
        this.state.storage.removeItem('role')
        this.state.storage.removeItem('token')
        if (this.state.user) {
          this.state.socket.emit('roomLeave', this.state.user.id)
        }

        this.setState({ user: null, store: null })
      },
      loggedIn: () => {
        const token = this.state.storage.getItem('token')
        return !!token
      },
      isPro: () => {
        return this.state.user.role === 20
      },
      getRole: () => {
        return this.state.user && getRoleRoute(this.state.user.role)
      },
      getProfile: async force => {
        const token = this.state.getToken()
        if (this.state.user && !force) {
          return Promise.resolve({ success: true, data: this.state.user })
        } else if (token) {
          try {
            this.setState({ loading: true })
            const res = await Api.getProfile(this.state.getId())

            if (_isOk(res) && res.data.data.error) {
              this.state.logout()
              this.setState({ loading: false })
            } else if (_isOk(res)) {
              this.setState({ user: res.data.data, loading: false }, () => {
                this.state.socket.emit('room', res.data.data.id)
                this.state.getNotifications()
              })
            }
            return res
          } catch (err) {
            console.error('getProfile error', err)
            return err
          }
        } else {
          this.setState({ loading: false })
        }
      },
      update: async userInfo => {
        const res = await Api.updateProfile(this.state.user.id, userInfo)

        if (res && res.data.success && res.data.data) {
          await this.state.getProfile(true)
        }
        return res
      },
      updatePassword: (password, new_password) => {
        return Api.updatePassword({ user_id: this.state.getId(), password: password, new_password: new_password })
      },

      updateNewsletters: (nl_main, nl_fresh) => {
        return Api.updateNewsletters({ user_id: this.state.getId(), nl_main: nl_main, nl_fresh: nl_fresh })
      },
      getNotifications: async () => {
        const res = await Api.getNotifications(this.state.user.id)
        if (res && res.data.success && res.data) {
          this.setState({ notifs: res.data.data })
        }
      },
      getNotifsCount: () => {
        return this.state.notifs.length
      },
      setNotificationAsViewed: async id => {
        const res = await Api.setNotificationAsViewed(id)
        // Once we have marked a notification as read, we can retrieve the list once more
        // It will auto-update the notification area, thanks to context API
        if (res && res.data.success) {
          this.state.getNotifications()
        }
      },
      setAllNotificationsAsViewed: async () => {
        const res = await Api.setAllNotificationsAsViewed(this.state.user.id)
        // Once we have marked all the notifications as read, we can retrieve the list once more
        // It will auto-update the notification area, thanks to context API
        if (res && res.data.success) {
          this.state.getNotifications()
        }
      }
    }
  }

  componentDidMount = async () => {
    await this.state.getProfile()

    this.state.socket.on('notif', data => {
      let currentPath = history.location.pathname
      currentPath = currentPath.split('/')

      if (currentPath.length === 4 && currentPath[2] === 'messages' && currentPath[3] === data.type_id) {
        this.state.setNotificationAsViewed(data.id)
        return
      }
      this.setState(state => ({
        notifs: [data, ...state.notifs]
      }))
    })
  }

  componentWillUnmount = () => {
    this.state.socket.off('message')
    this.state.socket.off('notif')
  }

  render = () => <UserContext.Provider value={{ userContext: this.state }}>{this.props.children}</UserContext.Provider>
}

export const withUser = Component => props => <UserContext.Consumer>{store => <Component {...props} {...store} />}</UserContext.Consumer>

export default UserProvider
