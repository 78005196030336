import React from 'react'
import classNames from 'classnames'
import { AppBar, IconButton, Toolbar } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/styles'
import Notifications from './Notifications'
import Account from './Account'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #eff3f8',
    height: 75
  },
  container: {
    ...theme.common.container
  },
  toolbarBase: {
    display: 'flex',
    flex: 1,
    alignItems: 'stretch'
  },
  toolbar: {
    marginLeft: 80,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  toolbarShift: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 280
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbarTop: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'stretch'
  },
  order: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto'
    }
  },
  case: {
    borderLeft: '1px solid #eff3f8',
    padding: theme.typography.pxToRem(15),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  menu: {
    alignSelf: 'center',
    marginRight: 'auto',
    display: 'none'
  },
  visibleSm: {
    ...theme.common.visibleSm
  },
  notifs: {
    paddingRight: theme.typography.pxToRem(20),
    paddingLeft: theme.typography.pxToRem(20),
    borderRight: '1px solid #eff3f8', // petit fix
    marginRight: -1 // pour aligner le sous menu avec le border
  },
  lastCase: {
    borderRight: '1px solid #eff3f8',
    paddingRight: theme.typography.pxToRem(15),
    width: theme.typography.pxToRem(200),
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  }
}))

const BNSAppBar = props => {
  const classes = useStyles()
  const { isOpen, toggleSidebar } = props
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar
        className={classNames(classes.toolbarBase, {
          [classes.toolbarShift]: isBrowser && isOpen,
          [classes.toolbar]: isBrowser && !isOpen
        })}
      >
        <div className={classes.container}>
          <div className={classes.toolbarTop}>
            <IconButton className={classNames(classes.menu, classes.visibleSm)} onClick={toggleSidebar}>
              <Menu style={{ fontSize: 20 }} color="primary" />
            </IconButton>

            <Notifications className={classNames(classes.notifs, classes.case)} />

            <Account className={classNames(classes.case, classes.lastCase)} />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default BNSAppBar
