const palette = {
  dark: '#03073b',
  clearBlue: '#92d8ff',
  skyBlue: '#f8fbfe',
  purple: '#373d86',
  red: '#ff5d4e',
  clearRed: '#fff5f5',
  grey: '#e7ecf2',
  green: '#b3db7b',
  yellow: '#febb3f'
}

export default palette
