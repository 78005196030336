import React from 'react'
import { makeStyles } from '@material-ui/styles'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

const useStyles = makeStyles(theme => ({
  bold: {
    display: 'inline',
    fontWeight: 700
  }
}))

const Hour = ({ date, withYear, end, noBold }) => {
  const classes = useStyles()

  return (
    <>
      <span className={noBold ? null : classes.bold}>
        {' '}
        {dayjs
          .unix(date)
          .format(withYear ? 'DD MMMM YYYY' : 'DD MMMM')
          .toLowerCase()}
      </span>
      {end ? ' entre ' : ' à '}
      <span className={noBold ? null : classes.bold}>
        {dayjs
          .unix(date)
          .format('HH:mm')
          .toLowerCase()}
      </span>
      {end && (
        <>
          {' et '}
          <span className={noBold ? null : classes.bold}>
            {dayjs
              .unix(end)
              .format('HH:mm')
              .toLowerCase()}
          </span>
        </>
      )}
      {'.'}
    </>
  )
}

export default Hour
